import {Injectable} from '@angular/core';
import {FontsBase64Service} from './fonts-base64.service';
import {StudioService} from './studio.service';
import {FrameService} from './frame.service';
import {MatService} from './mat.service';
import {TitleService} from './title.service';
import {LayersService} from './layers.service';
import {saveAs} from 'file-saver/FileSaver';
import {ProjectService} from './project.service';

@Injectable()
export class ExportService {

    svgHost: any;
    svg: any;
    width: number;
    height: number;
    fullWidth: number;
    fullHeight: number;
    dpi: number;
    maxSide = 1050;
    centerX: number;
    centerY: number;

    constructor(
        private studioService: StudioService,
        private frameService: FrameService,
        private matService: MatService,
        private titleService: TitleService,
        private layersService: LayersService,
        private fontsBase64Service: FontsBase64Service,
        private projectService: ProjectService
        ) {
    }


    drawWeb(that: any) {

        if (that.gridActive) {
            that.toggleGridLines();
        }

        this.drawPaper(that);

        setTimeout(() => {
            this.defs();
            this.studioService.setChartSpace(that, this.dpi, this.centerX, this.centerY);
            that[that.type.charAt(0).toLowerCase() + that.type.slice(1) + 'Service'].draw(that, this.svg, this.dpi, true);
            this.titleService.draw(that, this.svg, this.centerX, this.centerY, true);
            this.layersService.draw(that, this.svg, this.centerX, this.centerY, true);
            this.matService.draw(that, this.svg, this.dpi, this.centerX, this.centerY, true);
            this.matService.drawDoubleMat(that, this.svg, this.dpi, this.centerX, this.centerY, true);
            this.frameService.draw(that, this.svg, this.dpi, this.centerX, this.centerY, true);
            this.drawOuterTopBorder();
            this.drawBrandTag(that);
            this.convertBase64(that);
        }, 500); // Added to let more time to load url fonts
    }


    drawPaper(that) {

        let useSide;

        this.fullWidth = (this.projectService.frameSize) ? this.projectService.paperWidth + (this.projectService.frameSize * 2) - 0.5 : this.projectService.paperWidth;
        this.fullHeight = (this.projectService.frameSize) ? this.projectService.paperHeight + (this.projectService.frameSize * 2) - 0.5 : this.projectService.paperHeight;

        if (this.fullWidth > this.fullHeight) {
            useSide = this.fullWidth;
            this.width = this.maxSide;
            this.height = this.fullHeight * (this.maxSide / this.fullWidth);
        } else {
            useSide = this.fullHeight;
            this.width = this.fullWidth * (this.maxSide / this.fullHeight);
            this.height = this.maxSide;
        }

        this.dpi = this.maxSide / useSide;
        this.centerX = this.width / 2;
        this.centerY = this.height / 2;

        this.svgHost = that.d3.select('body').append('svg')
            .attr('id', 'svg-web')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('viewBox', '0 0 ' + (this.fullWidth * this.dpi) + ' ' + (this.fullHeight * this.dpi))
            .style('position', 'absolute')
            .style('top', 0)
            .style('left', 0)
            .style('background', 'white')
            .style('z-index', -1);

        this.svg = this.svgHost.append('g')
            .attr('id', 'export-svg');

        this.svg.append('style').text(this.fontsBase64Service.fonts);
    }


    defs() {
        const defs = this.svg.append('defs');
        // Small Shadow Web Export..
        const filter = defs.append('filter')
            .attr('id', 'small-shadow-web')
            .attr('height', '130%');
        filter.append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', this.dpi / 50)
            .attr('result', 'blur');
        filter.append('feOffset')
            .attr('in', 'blur')
            .attr('dx', 0)
            .attr('dy', 0)
            .attr('result', 'offsetBlur');
        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode')
            .attr('in', 'offsetBlur');
        feMerge.append('feMergeNode')
            .attr('in', 'SourceGraphic');
    }


    drawOuterTopBorder() {
        const width = this.fullWidth * this.dpi;
        const height = this.fullHeight * this.dpi;
        this.svg.append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', width)
            .attr('y2', 0)
            .attr('stroke-width', 1)
            .attr('stroke', '#999');
        this.svg.append('line')
            .attr('x1', width)
            .attr('y1', 0)
            .attr('x2', width)
            .attr('y2', height)
            .attr('stroke-width', 1)
            .attr('stroke', '#999');
        this.svg.append('line')
            .attr('x1', width)
            .attr('y1', height)
            .attr('x2', 0)
            .attr('y2', height)
            .attr('stroke-width', 1)
            .attr('stroke', '#999');
        this.svg.append('line')
            .attr('x1', 0)
            .attr('y1', height)
            .attr('x2', 0)
            .attr('y2', 0)
            .attr('stroke-width', 1)
            .attr('stroke', '#999');
    }


    drawBrandTag(that) {

        this.svg.append('rect')
            .attr('x', this.width - 168)
            .attr('y', this.height - 25)
            .attr('width', 168)
            .attr('height', 25)
            .attr('fill', 'black');

        this.svg.append('text')
            .text('InstantLayout.com')
            .attr('text-anchor', 'left')
            .attr('x', this.width - 162)
            .attr('y', this.height - 7)
            .style('font-family', 'Open Sans')
            .style('font-size', '18px')
            .style('fill', 'white');
    }


    convertBase64(that) {

        let imagesConverted = 0;
        const
            images = that.d3.selectAll('#svg-web image')._groups[0],
            self = this;

        const yo = {};

        if (!images.length) {
            this.convertToDownload(that);
        } else {
            for (let i = 0; i < images.length; i++) {
                toDataURL(images[i].href.baseVal, dataUrl => {
                    imagesConverted++;
                    that.d3.select('#' + images[i].id).attr('href', dataUrl);
                    if (imagesConverted === images.length) {
                        setTimeout(() => {
                            self.convertToDownload(that);
                        }, 0);
                    }
                });
            }
        }

        function toDataURL(url, callback) {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                const reader = new FileReader();
                reader.onloadend = () => callback(reader.result);
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

    }


    convertToDownload(that) {
        const svgString = this.getSVGString(this.svgHost.node());
        this.svgString2Image(svgString, this.fullWidth * this.dpi, this.fullHeight * this.dpi, save);
        that.d3.select('#svg-web').remove();
        function save(dataBlob, filesize) {
            saveAs(dataBlob, that.project.id + '.png');
        }
    }


    getSVGString(svgNode) {
        svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
        const serializer = new XMLSerializer();
        let svgString = serializer.serializeToString(svgNode);
        svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=');
        svgString = svgString.replace(/NS\d+:href/g, 'href');
        return svgString;
    }


    svgString2Image(svgString, width, height, callback) {
        svgString = btoa(unescape(encodeURIComponent(svgString)));
        const imageSource = 'data:image/svg+xml;base64,' + svgString;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        const image = new Image();
        image.onload = () => {
            context.clearRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);
            canvas.toBlob((blob: any) => {
                const fileSize = Math.round(blob.length / 1024) + ' KB';
                if (callback) {
                    callback(blob, fileSize);
                }
            });
        };
        image.src = imageSource;

        // const newImageWindow = window.open('');
        // newImageWindow.document.write(image.outerHTML);
    }


}
