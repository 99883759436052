import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PasswordService} from '../../services/password.service';
import {UserService} from '../../services/user.service';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

    errorMessage: any;
    spinner: boolean;
    error: any = {};
    form = new PasswordForm('', '');

    constructor(private router: Router,
                private passwordService: PasswordService,
                private userService: UserService,
                private analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.analyticsService.record('password');
    }

    tryPasswords() {
        this.error = {};
        if (this.form.password1 !== this.form.password2) {
            this.error['not same'] = true;
        } else if (this.form.password1.match(/\W/)) {
            this.error['illegal chars'] = true;
        } else if (this.form.password1.length < 8 || this.form.password1.length > 12) {
            this.error['wrong length'] = true;
        } else {
            this.error['sending code'] = true;
            this.checkPassword();
        }
    }

    checkPassword() {
        this.spinner = true;
        this.passwordService.checkPassword(this.userService.verifiesEmail, this.userService.verifiesCode,
            this.userService.verifiesResetting, this.form.password1)
            .subscribe(
                result => this.processPasswordResult(result),
                error => this.errorMessage = <any>error
            );
    }

    processPasswordResult(result: any) {
        this.error = {};
        if (result.passed) {
            this.router.navigate(['/']);
        } else {
            this.error['save failed'] = true;
        }
        this.spinner = false;
    }

}

export class PasswordForm {
    constructor(public password1: string, public password2: string) {
    }
}
