import {Component, OnInit, HostBinding} from '@angular/core';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: []
})
export class HomeComponent implements OnInit {
    @HostBinding('class') classes = 'fade-in';

    constructor(private analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        this.analyticsService.record('home');
    }

}
