import {Component, HostBinding, OnInit} from '@angular/core';
import {AnalyticsService} from "../../analytics.service";
import { diyData } from '../diy/diy.data';

@Component({
  selector: 'app-diy',
  templateUrl: './diy.component.html',
  styleUrls: ['./diy.component.scss']
})
export class DiyComponent implements OnInit {

    public keyword = '';
    public projects: {
        backgroundTitle: string,
        description: string,
        id: string
    }[] = [];
    public skills: {
        backgroundTitle: string,
        description: string,
        id: string
    }[] = [];

    @HostBinding('class') classes = 'fade-in';

    constructor(private analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        const { projects, skills } = diyData;
        this.projects = projects;
        this.skills = skills;
        this.analyticsService.record('home');
    }

}
