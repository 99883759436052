import {Injectable} from '@angular/core';
import {SubjectsService} from './subjects.service';
import {ProjectService} from './project.service';

@Injectable()
export class BlocksService {

    private bleedShift: number;
    private svg: any;
    private dpi: number;
    private exporting: boolean;
    private anchorSpaceW: number;
    private anchorSpaceH: number;

    constructor(private subjectsService: SubjectsService,
                private projectService: ProjectService
    ) {}



    draw(that: any, svg: any, dpi: number, exporting: boolean) {
        this.svg = svg;
        this.dpi = dpi;
        this.exporting = exporting;

        if (that.anchorSize && that.anchorPosition && that.subjects[0]) {
            this.drawAnchor(that);
        }
        if (that.anchorSize < 1 && that.subjects.length > 1) {
            this.drawSubjects(that);
        }
    }



    drawAnchor(that) {

        this.bleedShift = that.dpi * 0.25;

        let w = that.chartWidth;
        let h = that.chartHeight;
        let x = that.chartCenterX;
        let y = that.chartCenterY;

        switch (that.anchorPosition) {
            case 'top':
                h *= that.anchorSize;
                x -= that.chartWidth * 0.5;
                y -= that.chartHeight * 0.5;
                break;
            case 'bottom':
                h *= that.anchorSize;
                x -= that.chartWidth * 0.5;
                y += (that.chartHeight * 0.5) - h;
                break;
            case 'left':
                w *= that.anchorSize;
                x -= that.chartWidth * 0.5;
                y -= that.chartHeight * 0.5;
                break;
            case 'right':
                w *= that.anchorSize;
                x += (that.chartWidth * 0.5) - w;
                y -= that.chartHeight * 0.5;
                break;
        }

        if (that.subjects.length === 1
            && that.anchorScale === 1
            && !that.chartMargin
            && that.titleSize < 1
            && (this.projectService.frameSize || this.projectService.matSize)
            && that.anchorShape === 'rect') {

            if (!that.titleText || !that.titleSize) {

                if (this.exporting) {

                    const totalWidth = this.projectService.paperWidth
                        + (this.projectService.frameSize * 2)
                        + (this.projectService.matSizeLeft + this.projectService.matSizeRight)
                        - 0.5;
                    const ratio = 1050 / totalWidth;

                    w += ratio * 0.5;
                    h += ratio * 0.5;
                    x -= ratio * 0.25;
                    y -= ratio * 0.25;

                } else {
                    w += this.bleedShift * 2;
                    h += this.bleedShift * 2;
                    x -= this.bleedShift;
                    y -= this.bleedShift;
                }

            } else {

                if (this.exporting) {

                    const totalWidth = this.projectService.paperWidth
                        + (this.projectService.frameSize * 2)
                        + (this.projectService.matSizeLeft + this.projectService.matSizeRight)
                        - 0.5;
                    const ratio = 1050 / totalWidth;

                    const bleed = ratio * 0.25;

                    switch (that.titleText) {
                        case 'bottom':
                            x -= bleed;
                            y -= bleed;
                            w += bleed * 2;
                            h += bleed;
                            if (!that.titleText || !that.titleSize) {
                                h += bleed;
                            }
                            break;
                        case 'top':
                            w += bleed * 2;
                            h += bleed;
                            x -= bleed;
                            if (!that.titleText || !that.titleSize) {
                                h += bleed;
                            }
                            // if (that.marginsBehavior === 'image') {
                            //     // y -= that.titleHeight;
                            // }
                            break;
                        case 'left':
                            y -= bleed;
                            h += bleed * 2;
                            w += bleed;
                            if (!that.titleText || !that.titleSize) {
                                x -= bleed;
                                w += bleed;
                            }
                            // if (that.marginsBehavior === 'image') {
                            //     // y -= that.titleHeight;
                            // }
                            break;
                        case 'right':
                            x -= bleed;
                            y -= bleed;
                            w += bleed;
                            h += bleed * 2;
                            if (!that.titleText || !that.titleSize) {
                                w += bleed;
                            }
                            break;
                    }

                } else {
                    switch (that.titleText) {
                        case 'bottom':
                            x -= this.bleedShift;
                            y -= this.bleedShift;
                            w += this.bleedShift * 2;
                            h += this.bleedShift;
                            if (!that.titleText || !that.titleSize) {
                                h += this.bleedShift;
                            }
                            break;
                        case 'top':
                            w += this.bleedShift * 2;
                            h += this.bleedShift;
                            x -= this.bleedShift;
                            if (!that.titleText || !that.titleSize) {
                                h += this.bleedShift;
                            }
                            // if (that.marginsBehavior === 'image') {
                            //     // y -= that.titleHeight;
                            // }
                            break;
                        case 'left':
                            y -= this.bleedShift;
                            h += this.bleedShift * 2;
                            w += this.bleedShift;
                            if (!that.titleText || !that.titleSize) {
                                x -= this.bleedShift;
                                w += this.bleedShift;
                            }
                            // if (that.marginsBehavior === 'image') {
                            //     // y -= that.titleHeight;
                            // }
                            break;
                        case 'right':
                            x -= this.bleedShift;
                            y -= this.bleedShift;
                            w += this.bleedShift;
                            h += this.bleedShift * 2;
                            if (!that.titleText || !that.titleSize) {
                                w += this.bleedShift;
                            }
                            break;
                    }
                }

            }
        }

        that.subjects[0].x = x;
        that.subjects[0].y = y;
        this.anchorSpaceW = w;
        this.anchorSpaceH = h;
        const origW = w;
        const origH = h;
        w *= that.anchorScale;
        h *= that.anchorScale;
        that.subjects[0].x += (origW - w) * 0.5;
        that.subjects[0].y += (origH - h) * 0.5;

        switch (that.anchorShape) {
            case 'ellipse':
                this.subjectsService.drawEllipse(that, this.svg, this.dpi, 0, w, h, this.exporting);
                break;
            default:
                this.subjectsService.drawRect(that, this.svg, this.dpi, 0, w, h, this.exporting);
        }

        this.subjectsService.setMainImageWidth(w);
        this.subjectsService.setMainImageHeight(h);
    }



    drawSubjects(that) {

        let x, y, w, h, i, wScaled, hScaled, xStart, yStart, gap, gapImageMarginRate;

        switch (that.anchorPosition) {
            case 'top':
                xStart = 0;
                yStart = this.anchorSpaceH;
                break;
            case 'bottom':
                xStart = 0;
                yStart = 0;
                break;
            case 'left':
                xStart = this.anchorSpaceW;
                yStart = 0;
                break;
            case 'right':
                xStart = 0;
                yStart = 0;
                break;
            default:
                xStart = 0;
                yStart = 0;
        }

        x = that.chartCenterX - (that.chartWidth / 2) + xStart;
        y = that.chartCenterY - (that.chartHeight / 2) + yStart;
        w = that.chartWidth / that.cols;

        if (that.anchorPosition === 'top' || that.anchorPosition === 'bottom') {
            if (that.marginsBehavior === 'image') {
                h = (that.chartHeight - this.anchorSpaceH) / that.rows;
            } else {
                h = (that.chartHeight - this.anchorSpaceH) / that.rows;
            }
        } else {
            h = that.chartHeight / that.rows;
        }

        const tileSpace = that.chartHeight - this.anchorSpaceH;

        if (that.anchorPosition === 'top' || that.anchorPosition === 'bottom') {
            if (w > h) {
                hScaled = h * that.subjectScale1;
                gap = h - hScaled;
                if (that.marginsBehavior === 'image') {
                    gapImageMarginRate = tileSpace / (tileSpace + gap);
                    gap *= gapImageMarginRate;
                    hScaled *= gapImageMarginRate;
                }
                wScaled = (that.chartWidth - (gap * (that.cols - 1))) / that.cols;
            } else {
                gap = w - (w * that.subjectScale1);
                hScaled = h - gap;
                if (that.marginsBehavior === 'image') {
                    gapImageMarginRate = tileSpace / (tileSpace + gap);
                    gap *= gapImageMarginRate;
                    hScaled *= gapImageMarginRate;
                }
                wScaled = (that.chartWidth - (gap * (that.cols - 1))) / that.cols;
            }
        } else {
            let anchorSpace = 0;
            if (that.anchorPosition === 'left' || that.anchorPosition === 'right') {
                anchorSpace = this.anchorSpaceW;
            }
            if (w > h) {
                hScaled = h * that.subjectScale1;
                gap = h - hScaled;
                if (that.anchorPosition === 'left' || that.anchorPosition === 'right') {
                    anchorSpace = this.anchorSpaceW + gap;
                }
                wScaled = (that.chartWidth - anchorSpace - (gap * (that.cols - 1))) / that.cols;
                hScaled += (gap / that.rows);
            } else {
                gap = w - (w * that.subjectScale1);
                if (that.anchorPosition === 'left' || that.anchorPosition === 'right') {
                    anchorSpace = this.anchorSpaceW + gap;
                }
                wScaled = (that.chartWidth - anchorSpace - (gap * (that.cols - 1))) / that.cols;
                hScaled = h - gap + (gap / that.rows);
            }
        }

        if (that.anchorPosition === 'top') {
            y += gap;
        }
        if (that.anchorPosition === 'left') {
            x += gap;
        }

        const oldSubjects = that.subjects.splice(0);
        that.subjects = [oldSubjects[0]];
        i = 0;

        for (let row = 0; row < that.rows; row++) {
            let rowX = x;
            for (let col = 0; col < that.cols; col++) {
                i++;
                if (oldSubjects[i]) {
                    that.subjects.push(oldSubjects[i]);
                } else {
                    that.subjects.push({ // todo - audit subjects instead (need to keep clean, e.g. can add 12x12 then 3x4)
                        id: '',
                        w: 0,
                        h: 0,
                        x: 0,
                        y: 0,
                        line1: '',
                        line1Font: '',
                        line2: '',
                        line2Font: '',
                        scale: 1,
                        skip: false
                    });
                }
                that.subjects[i].x = rowX;
                that.subjects[i].y = y;
                if (that.subjectShape === 'rect') {
                    this.subjectsService.drawRect(that, this.svg, this.dpi, i, wScaled, hScaled, this.exporting);
                } else {
                    this.subjectsService.drawEllipse(that, this.svg, this.dpi, i, wScaled, hScaled, this.exporting);
                }

                rowX += wScaled + gap;
            }
            y += hScaled + gap;
        }

        this.subjectsService.setTileImageWidth(wScaled);
        this.subjectsService.setTileImageHeight(hScaled);
    }



}
