import {Component, OnInit} from '@angular/core';
import {ProfileService} from './profile.service';
import {UserService} from "../../services/user.service";
import {AnalyticsService} from '../../analytics.service';


@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    company: string;
    memberSince: string;
    name: string;
    plan: number;
    planKeys: any[];
    plans: any[];
    size: number;
    subscriptionExpiration: string;
    user: string;
    constructor(private profileService: ProfileService,
                private userService: UserService,
                private analyticsService: AnalyticsService) {
        this.profileService.subject1.subscribe(() => this.getProfile());
    }

    ngOnInit() {
        this.userService.checkLogin();
        this.getProfile();
        this.analyticsService.record('profile');
    }


    getProfile() {
        this.userService.checkLogin();
        this.profileService
            .getProfile()
            .subscribe(
                data => {
                    this.mapProfileData(data);
                },
                err => console.error(err)
            );
    }


    mapProfileData(data) {
        this.plans = data.plans;
        this.user = data.user;
        this.memberSince = data.memberSince;
        this.plan = data.plan;
        this.planKeys = data.planKeys;
        this.name = data.name;
        this.company = data.company;
        this.size = data.size;
        this.subscriptionExpiration = data.subscriptionExpiration;
    }


}
