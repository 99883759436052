import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-differences',
  templateUrl: './home-differences.component.html',
  styleUrls: ['./home-differences.component.scss']
})
export class HomeDifferencesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
