import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'features-steps',
  templateUrl: './features-steps.component.html',
  styleUrls: ['./features-steps.component.scss']
})
export class FeaturesStepsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
