import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DowngradeModalService {
    active: boolean;
    amount: number;
    description: string;
    newExpiration: string;
    plan: number;
    constructor() { }

    public openModal (amount, description, newExpiration, plan) {
        this.active = true;
        this.amount = amount;
        this.newExpiration = newExpiration;
        this.description = description;
        this.plan = plan;
    }
}
