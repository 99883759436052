import { Component } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { StudioConstants } from '../../studio-constants';
import { SubjectsService } from '../../services/subjects.service';

@Component({
    selector: 'info-panel',
    templateUrl: './info-panel.component.html',
    styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent {

    doubleMatColor: string;
    doubleMatSize: number;
    dpi: number = StudioConstants.dpi;
    frameColor: string;
    frameShape: string;
    frameSize: number;
    id: string;
    mainImageHeight: number;
    mainImageHeightPixels: number;
    mainImageWidth: number;
    mainImageWidthPixels: number;
    matColor: string;
    matSize: number;
    matSizeTop: number;
    matSizeLeft: number;
    matSizeRight: number;
    matSizeBottom: number;
    orientation: string;
    paperHeight: number;
    paperWidth: number;
    tileImageHeight: number;
    tileImageHeightPixels: number;
    tileImageWidth: number;
    tileImageWidthPixels: number;
    type: string;

    constructor(
        public projectService: ProjectService,
        public subjectsService: SubjectsService) {

        this.id = this.projectService.id;
        this.paperWidth = this.projectService.paperWidth;
        this.paperHeight = this.projectService.paperHeight;
        this.frameColor = this.projectService.frameColor;
        this.frameShape = this.projectService.frameShape;
        this.frameSize = this.projectService.frameSize;
        this.matColor = this.projectService.matColor;
        this.matSize = this.projectService.matSize;
        this.matSizeTop = this.projectService.matSizeTop;
        this.matSizeLeft = this.projectService.matSizeLeft;
        this.matSizeRight = this.projectService.matSizeRight;
        this.matSizeBottom = this.projectService.matSizeBottom;
        this.doubleMatColor = this.projectService.doubleMatColor;
        this.doubleMatSize = this.projectService.doubleMatSize;
        this.mainImageWidthPixels = this.subjectsService.getMainImageWidth;
        this.mainImageWidth = this.mainImageWidthPixels / this.dpi;
        this.mainImageHeightPixels = this.subjectsService.getMainImageHeight;
        this.mainImageHeight = this.mainImageHeightPixels / this.dpi;
        this.tileImageWidthPixels = this.subjectsService.getTileImageWidth;
        this.tileImageWidth = this.tileImageWidthPixels / this.dpi;
        this.tileImageHeightPixels = this.subjectsService.getTileImageHeight;
        this.tileImageHeight = this.tileImageHeightPixels / this.dpi;
        this.type = this.projectService.type;

        if (this.projectService.paperWidth === this.projectService.paperHeight) {
            this.orientation = 'square';
        }
        else if (this.projectService.paperWidth > this.projectService.paperHeight) {
            this.orientation = 'landscape';
        }
        else {
            this.orientation = 'portrait';
        }

    }

}
