import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ProjectService} from './project.service';
import {ProjectsService} from './projects.service';
import {UserService} from '../../../services/user.service';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {HttpClient} from "@angular/common/http";
import {EnvService} from "../../../env.service";
import {Observable} from "rxjs/Rx";

@Injectable()
export class StudioService {

    bleedShift: number;
    elasticTemplates: any = {};
    optionsTemplates: any = {};
    subject1 = new Subject<number>();
    subject2 = new Subject<boolean>();

    constructor(
        private projectService: ProjectService,
        private projectsService: ProjectsService,
        private http: HttpClient,
        private userService: UserService,
        private envService: EnvService) {}



    getProject(that: any) {
        // this is for export only, could move to its own exportHiResService
        const exportId = Cookie.get('export');
        this.http
            .get(this.envService.url + 'project/' + exportId)
            .subscribe((res: any) => {
                const data = res;
                that.fonts = data.fonts;
                that.project = data.project;
                that.setProject();
            });
    }



    getExports(that: any) {
        this.http
            .get(this.envService.url + 'exports/' + this.userService.token)
            .subscribe(res => {
                that.exports = res;
                this.exportsTryAgain(that);
            });
    }



    exportsTryAgain(that) {
        let allGood = true;
        that.exports.forEach(item => {
            if (!item.fileSize) {
                allGood = false;
            }
        });
        if (!allGood) {
            setTimeout(() => {
                this.getExports(that);
            }, 5000);
        }
    }



    getAllData(that: any) {
        this.projectsService
            .getAllData()
            .subscribe(
                data => {
                    this.elasticTemplates = data[0]['elasticTemplates'];
                    this.optionsTemplates = data[0]['optionsTemplates'];
                    that.cardSizes = data[0]['cardSizes'];
                    that.commonCardSizes = data[0]['commonCardSizes'];
                    that.posterSizes = data[0]['posterSizes'];
                    that.commonPosterSizes = data[0]['commonPosterSizes'];
                    that.frameSizes = data[0]['frameSizes'];
                    that.frameStyles = data[0]['frameStyles'];
                    that.matSizes = data[0]['matSizes'];
                    that.doubleMatSizes = data[0]['doubleMatSizes'];
                    that.matStyles = data[0]['matStyles'];
                    that.fonts = data[0]['fonts'];
                    that.projects = data[1];
                    that.projects.forEach(project => {
                        that.newNames.push(project.id);
                        that.projectNames[project.id] = true;
                    });
                    that.exports = data[2];
                },
                err => console.error(err)
            );
    }



    getFontIndex(that: any, fontLine: string) {
        for (const i in that.fonts) {
            if (that[fontLine] === that.fonts[i].id) {
                return +i;
            }
        }
        return 0;
    }



    setChartSpace(that: any, dpi: number, centerX: number, centerY: number) {

        dpi = (dpi) ? dpi : that.dpi;
        centerX = (centerX) ? centerX : that.centerX;
        centerY = (centerY) ? centerY : that.centerY;

        if (this.projectService.matSize) {
            centerX = centerX - ((+this.projectService.matSizeRight - +this.projectService.matSizeLeft) * 0.5 * dpi);
            centerY = centerY - ((+this.projectService.matSizeBottom - +this.projectService.matSizeTop) * 0.5 * dpi);
        }

        // if there's a mat that will already offset the frame cover
        that.frameSpace = (this.projectService.frameSize && !that.projectService.matSize) ? 0.5 : 0;

        if (this.projectService.matSize) {
            that.revealWidth = ((this.projectService.paperWidth - that.frameSpace - (+this.projectService.matSizeLeft + +this.projectService.matSizeRight)) * dpi);
            that.revealHeight = ((this.projectService.paperHeight - that.frameSpace - (+this.projectService.matSizeTop + +this.projectService.matSizeBottom)) * dpi);
        } else {
            that.revealWidth = (this.projectService.paperWidth - that.frameSpace) * dpi;
            that.revealHeight = (this.projectService.paperHeight - that.frameSpace) * dpi;
        }

        if (that.revealWidth > that.revealHeight) {
            that.marginWidth = that.revealHeight * that.chartMargin;
            that.marginHeight = that.revealHeight * that.chartMargin;
        } else {
            that.marginWidth = that.revealWidth * that.chartMargin;
            that.marginHeight = that.revealWidth * that.chartMargin;
        }

        that.chartWidth = that.revealWidth - (that.marginWidth * 2);
        that.chartHeight = that.revealHeight - (that.marginHeight * 2);

        if (this.projectService.fold === 'v') {
            that.revealWidth *= 0.5;
            that.chartCenterX += that.revealWidth * 0.5;
            centerX += that.revealWidth * 0.5;
        } else if (this.projectService.fold === 'h') {
            that.revealHeight *= 0.5;
            that.chartCenterY += that.revealHeight * 0.5;
            centerY += that.revealHeight * 0.5;
        }

        this.setTitle(that, dpi, centerX, centerY);
    }



    setTitle(that, dpi, centerX, centerY) {
        that.titleWidth = 0;
        that.titleHeight = 0;
        that.titleX = 0;
        that.titleY = 0;
        that.titleRectX = 0;
        that.titleRectY = 0;
        that.titleRectW = 0;
        that.titleRectH = 0;
        this.bleedShift = (this.projectService.frameSize || that.projectService.matSize) ? 0.25 * that.dpi : 0;
        switch (that.marginsBehavior) {
            case 'edge':
                this.setEdge(that, dpi, centerX, centerY);
                break;
            case 'even-edge':
                this.setEvenEdge(that, dpi, centerX, centerY);
                break;
            case 'even-image':
                this.setEvenImage(that, dpi, centerX, centerY);
                break;
            case 'image':
                this.setImage(that, dpi, centerX, centerY);
                break;
        }
    }



    setEdge(that, dpi, centerX, centerY) {
        // Title is % of the reveal.
        // Chart space is the % of the reveal after the title.
        switch (that.titleText) {
            case 'bottom':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.titleWidth = that.revealWidth;
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5);
                that.titleY = centerY + (that.revealHeight * 0.5) - that.titleHeight;
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                    that.titleRectH += this.bleedShift;
                    if (that.titleSize === 1 && !that.marginHeight) {
                        that.titleRectY -= this.bleedShift;
                        that.titleRectH += this.bleedShift;
                    }
                }
                break;
            case 'top':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.titleWidth = that.revealWidth;
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.titleHeight + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5);
                that.titleY = centerY - (that.revealHeight * 0.5);
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift;
                    if (that.titleSize === 1 && !that.marginHeight) {
                        that.titleRectH += this.bleedShift;
                    }
                }
                break;
            case 'left':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - (that.marginWidth * 2));
                that.titleHeight = that.revealHeight;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = (that.revealWidth - (that.marginWidth * 2)) - that.titleWidth;
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.titleWidth + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5);
                that.titleX = centerX - (that.revealWidth * 0.5);
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift;
                    if (that.titleSize === 1 && !that.marginWidth) {
                        that.titleRectW += this.bleedShift;
                    }
                }
                break;
            case 'right':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - that.marginWidth - that.marginWidth);
                that.titleHeight = that.revealHeight;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = (that.revealWidth - that.marginWidth - that.marginWidth) * (1 - that.titleSize);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5);
                that.titleX = centerX + (that.revealWidth * 0.5) - that.titleWidth;
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                    that.titleRectW += this.bleedShift;
                    if (that.titleSize === 1 && !that.marginWidth) {
                        that.titleRectX -= this.bleedShift;
                        that.titleRectW += this.bleedShift;
                    }
                }
                break;
            default:
                that.titleWidth = 0;
                that.titleHeight = 0;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX;
        }
    }



    setEvenEdge(that, dpi, centerX, centerY) {
        // Title = % of the reveal - margins.
        // Chart space = reveal - margins - title %
        // Title is placed inward at half margin
        switch (that.titleText) {
            case 'bottom':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.titleWidth = that.revealWidth;
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5);
                that.titleY = centerY + (that.revealHeight * 0.5) - that.titleHeight - (that.marginHeight * 0.5);
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                }
                break;
            case 'top':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.titleWidth = that.revealWidth;
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.titleHeight + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5);
                that.titleY = centerY - (that.revealHeight * 0.5) + (that.marginHeight * 0.5);
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                }
                break;
            case 'left':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - (that.marginWidth * 2));
                that.titleHeight = that.revealHeight;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = (that.revealWidth - (that.marginWidth * 2)) - that.titleWidth;
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.titleWidth + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5);
                that.titleX = centerX - (that.revealWidth * 0.5) + (that.marginWidth * 0.5);
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                }
                break;
            case 'right':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - that.marginWidth - that.marginWidth);
                that.titleHeight = that.revealHeight;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = (that.revealWidth - that.marginWidth - that.marginWidth) * (1 - that.titleSize);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5);
                that.titleX = centerX + (that.revealWidth * 0.5) - that.titleWidth - (that.marginWidth * 0.5);
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                }
                break;
            default:
                that.titleWidth = 0;
                that.titleHeight = 0;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX;
        }
    }



    setEvenImage(that, dpi, centerX, centerY) {
        // Title = % of the reveal - margins.
        // Chart space = reveal - margins - title %
        // Title is placed inward at half margin
        switch (that.titleText) {
            case 'bottom':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.titleWidth = that.chartWidth;
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5) + that.marginWidth;
                that.titleY = centerY + (that.revealHeight * 0.5) - that.titleHeight - (that.marginHeight * 0.5);
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift && !that.marginHeight) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                    that.titleRectH += this.bleedShift;
                }
                break;
            case 'top':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.titleWidth = that.chartWidth;
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.titleHeight + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5) + that.marginWidth;
                that.titleY = centerY - (that.revealHeight * 0.5) + (that.marginHeight * 0.5);
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift && !that.marginHeight) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                    that.titleRectH += this.bleedShift;
                    that.titleRectY -= this.bleedShift;
                }
                break;
            case 'left':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - (that.marginWidth * 2));
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.titleHeight = that.chartHeight;
                that.chartWidth = (that.revealWidth - (that.marginWidth * 2)) - that.titleWidth;
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.titleWidth + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5) + that.marginWidth;
                that.titleX = centerX - (that.revealWidth * 0.5) + (that.marginWidth * 0.5);
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift && !that.marginWidth) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                    that.titleRectW += this.bleedShift;
                    that.titleRectX -= this.bleedShift;
                }
                break;
            case 'right':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - that.marginWidth - that.marginWidth);
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.titleHeight = that.chartHeight;
                that.chartWidth = (that.revealWidth - that.marginWidth - that.marginWidth) * (1 - that.titleSize);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5) + that.marginHeight;
                that.titleX = centerX + (that.revealWidth * 0.5) - that.titleWidth - (that.marginWidth * 0.5);
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift && !that.marginWidth) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                    that.titleRectW += this.bleedShift;
                }
                break;
            default:
                that.titleWidth = 0;
                that.titleHeight = 0;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX;
        }
    }



    setImage(that, dpi, centerX, centerY) { // todo..
        // Title is % of the image after reveal minus margins.
        // Chart space is the reveal - reveal * margin - TITLE.

        switch (that.titleText) {
            case 'bottom':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.titleWidth = that.chartWidth;
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5) + that.marginWidth;
                that.titleY = centerY + (that.revealHeight * 0.5) - that.titleHeight - that.marginHeight;
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift && !that.marginHeight) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                    that.titleRectH += this.bleedShift;
                }
                break;
            case 'top':
                // chart & title size
                that.titleHeight = that.titleSize * (that.revealHeight - that.marginHeight - that.marginHeight);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.titleWidth = that.chartWidth;
                that.chartHeight = (that.revealHeight - that.marginHeight - that.marginHeight) * (1 - that.titleSize);
                that.chartCenterX = centerX;
                that.chartCenterY = centerY - (that.revealHeight * 0.5) + that.titleHeight + that.marginHeight + (that.chartHeight * 0.5);
                // title base
                that.titleX = centerX - (that.revealWidth * 0.5) + that.marginWidth;
                that.titleY = centerY - (that.revealHeight * 0.5) + that.marginHeight;
                // title rect
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                // bleed
                if (this.bleedShift && !that.marginHeight) {
                    that.titleRectX -= this.bleedShift;
                    that.titleRectW += this.bleedShift * 2;
                    that.titleRectH += this.bleedShift;
                    that.titleRectY -= this.bleedShift;
                }
                break;
            case 'left':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - (that.marginWidth * 2));
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.titleHeight = that.chartHeight;
                that.chartWidth = (that.revealWidth - (that.marginWidth * 2)) - that.titleWidth;
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.titleWidth + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5) + that.marginWidth;
                that.titleX = centerX - (that.revealWidth * 0.5) + that.marginWidth;
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift && !that.marginWidth) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                    that.titleRectW += this.bleedShift;
                    that.titleRectX -= this.bleedShift;
                }
                break;
            case 'right':
                // chart & title size
                that.titleWidth = that.titleSize * (that.revealWidth - that.marginWidth - that.marginWidth);
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.titleHeight = that.chartHeight;
                that.chartWidth = (that.revealWidth - that.marginWidth - that.marginWidth) * (1 - that.titleSize);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX - (that.revealWidth * 0.5) + that.marginWidth + (that.chartWidth * 0.5);
                // title base
                that.titleY = centerY - (that.revealHeight * 0.5) + that.marginHeight;
                that.titleX = centerX + (that.revealWidth * 0.5) - that.titleWidth - that.marginWidth;
                // title rect
                that.titleRectY = that.titleY;
                that.titleRectH = that.titleHeight;
                that.titleRectX = that.titleX;
                that.titleRectW = that.titleWidth;
                // bleed
                if (this.bleedShift && !that.marginWidth) {
                    that.titleRectY -= this.bleedShift;
                    that.titleRectH += this.bleedShift * 2;
                    that.titleRectW += this.bleedShift;
                }
                break;
            default:
                that.titleWidth = 0;
                that.titleHeight = 0;
                that.chartHeight = that.revealHeight - (that.marginHeight * 2);
                that.chartWidth = that.revealWidth - (that.marginWidth * 2);
                that.chartCenterY = centerY;
                that.chartCenterX = centerX;
        }
    }



    openExport(user: string, item: any) {
        if (user && item.id && item.fileName && item.fileSize) {
            window.open('https://images.instantlayout.com/' + user + '/exports/' + item.id + '/' + item.fileName, '_system', 'location=yes');
        }
        return false;
    }



    addExportsQueue(project: string): Observable<any> {
        return this.http
            .post(
                this.envService.url + 'exports-queue/',
                {
                    project,
                    token: this.userService.token
                }
            );
    }



    deleteExport(id: string): Observable<any> {
        return this.http.delete(this.envService.url + 'exports/' + id + '/' + this.userService.token);
    }



}
