import { Component, Input } from '@angular/core';
import {PayModalService} from '../../../../services/pay-modal.service';

@Component({
    selector: 'tier-usage',
    templateUrl: './tier-usage.component.html',
    styleUrls: ['./tier-usage.component.css']
})
export class TierUsageComponent {
    @Input() currentDescription;
    @Input() nextDescription;
    @Input() nextLabel;
    @Input() nextPrice;
    @Input() nextTier;
    @Input() usagePercent;
    constructor(public payModalService: PayModalService) {}
}
