import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnvService {

    url: string;

    constructor() {
        this.url = 'https://instantlayout.com/api/';
        if (window.location.hostname === 'localhost') {
            this.url = 'http://localhost:8081/api/';
        }
    }

}
