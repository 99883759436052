import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RegisterService} from './register.service';
import {UserService} from '../../services/user.service';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    form = new RegisterForm("");
    spinner: boolean = false;
    errorMessage: any;
    error: any = {};

    constructor(private router: Router,
                private registerService: RegisterService,
                private userService: UserService,
                private analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        this.analyticsService.record('register');
    }

    tryRegister() {
        this.error = {};
        if (this.form.email.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i)) {
            this.error['sending email'] = true;
            this.spinner = true;
            this.checkEmail();
        }
        else {
            this.error['invalid email'] = true;
        }
    }

    checkEmail() {
        this.registerService.checkEmail(this.form.email, false)
            .subscribe(
                result => this.processEmailResult(result),
                error => this.errorMessage = <any>error
            );
    }

    processEmailResult(result: any) {
        this.error = {};
        if (result.user === true) {
            this.error['already user'] = true;
        }
        if (result.user === false) {
            this.userService.verifiesEmail = this.form.email.toLowerCase(); // to share with /validate
            this.router.navigate(['/validate']);
        }
        this.spinner = false;
    }

}

export class RegisterForm {
    constructor(public email: string) {
    }
}
