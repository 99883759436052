import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {UserService} from '../../services/user.service';
import {EnvService} from "../../env.service";

@Injectable()
export class OrdersService {
    constructor(private http: HttpClient,
                private userService: UserService,
                private envService: EnvService) {}


    getOrders() {
        return this.http
            .get(this.envService.url + 'orders/' + this.userService.token)
            .map((res: HttpResponse<any>) => res);
    }


}
