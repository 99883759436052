import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {



    transform(items: any[], keyword: string): any {
        keyword = keyword.toLowerCase();
        return items.filter(item => {
            const backgroundTitle = item.backgroundTitle.toLowerCase();
            const description = item.description.toLowerCase();
            const id = item.id.toLowerCase();
            return backgroundTitle.indexOf(keyword) !== -1
                || description.indexOf(keyword) !== -1
                || id.indexOf(keyword) !== -1;
        });
    }



}
