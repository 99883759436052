import {Component, EventEmitter, Output, OnInit} from '@angular/core';
import {StudioService} from "../../../services/studio.service";

@Component({
  selector: 'templates-sizes',
  templateUrl: './templates-sizes.component.html',
  styleUrls: ['./templates-sizes.component.scss']
})
export class TemplatesSizesComponent implements OnInit {

    @Output() setOptions = new EventEmitter<object>();

    optionsTemplates: any = [];

    constructor(public studioService: StudioService) {}

    ngOnInit(): void {
        this.optionsTemplates = this.studioService.optionsTemplates;
    }

    nextStep(groupIndex, optionsIndex) {
        this.setOptions.next({
            groupIndex,
            optionsIndex
        });
    }
}
