import {Injectable} from '@angular/core';
import {ProjectService} from "./project.service";

@Injectable()
export class MatService {

    coreThickness: number;

    constructor(
        private projectService: ProjectService
    ) {}



    draw(that: any, svg: any, dpi: number, centerX: number, centerY: number, exporting: boolean) {

        if (!this.projectService.matSize) {
            return;
        }

        const width = this.projectService.paperWidth * dpi;
        const height = this.projectService.paperHeight * dpi;
        const top = this.projectService.matSizeTop * dpi;
        const left = this.projectService.matSizeLeft * dpi;
        const right = this.projectService.matSizeRight * dpi;
        const bottom = this.projectService.matSizeBottom * dpi;

        this.coreThickness = dpi / 16;
        that.matActive = true;

        svg.selectAll('.core').remove();
        svg.selectAll('.mat').remove();
        svg.selectAll('.bevel').remove();

        const g = svg.append('g')
            .attr('class', 'core')
            .style('opacity', () => {
                if (that.posterActive) {
                    return 0;
                }
                return (that.gridActive) ? 0.3 : 1;
        });

        this.drawMat(g, exporting, this.projectService.matColor, width, height,
            left, top,
            width - right, top,
            width - right, height - bottom,
            left, height - bottom
        );

        g.attr('transform', 'translate(' +
            (centerX - width * 0.5) + ', ' +
            (centerY - height * 0.5) + ')');
    }



    drawDoubleMat(that: any, svg: any, dpi: number, centerX: number, centerY: number, exporting: boolean) {

        svg.selectAll('.core2').remove();
        svg.selectAll('.mat2').remove();
        svg.selectAll('.bevel2').remove();

        if (!this.projectService.doubleMatSize) {
            return;
        }

        const width = this.projectService.paperWidth * dpi;
        const height = this.projectService.paperHeight * dpi;
        const top = (this.projectService.matSizeTop - this.projectService.doubleMatSize) * dpi;
        const left = (this.projectService.matSizeLeft - this.projectService.doubleMatSize) * dpi;
        const right = (this.projectService.matSizeRight - this.projectService.doubleMatSize) * dpi;
        const bottom = (this.projectService.matSizeBottom - this.projectService.doubleMatSize) * dpi;

        const g = svg.append('g')
            .attr('class', 'core2')
            .style('opacity', () => {
                if (that.posterActive) {
                    return 0;
                }
                return (that.gridActive) ? 0.3 : 1;
            });

        this.drawMat(g, exporting, this.projectService.doubleMatColor, width, height,
            left, top,
            width - right, top,
            width - right, height - bottom,
            left, height - bottom
        );

        g.attr('transform', 'translate(' +
            (centerX - width * 0.5) + ', ' +
            (centerY - height * 0.5) + ')');

    }



    drawMat(
        g: any,
        exporting,
        color: string,
        width: number,
        height: number,
        x1: number, y1: number,
        x2: number, y2: number,
        x3: number, y3: number,
        x4: number, y4: number) {

        const d = 'M '
            + 0 + ',' + 0 + ' '
            + width + ',' + 0 + ' '
            + width + ',' + height + ' '
            + 0 + ',' + height + ' '
            + 0 + ',' + 0 + ' '
            + 'M '
            + x1 + ',' + y1 + ' '
            + x2 + ',' + y2 + ' '
            + x3 + ',' + y3 + ' '
            + x4 + ',' + y4 + ' '
            + 'z';

        g.append('path')
            .attr('d', d)
            .style('fill', color)
            .style('fill-rule', 'evenodd')
            .style('filter', () => (exporting) ? 'url(#small-shadow-web)' : 'url(#small-shadow)');

        const coreWidth = x3 - x1 + (this.coreThickness * 2);
        const coreHeight = y3 - y1 + (this.coreThickness * 2);
        const coreTop = y1 - this.coreThickness;
        const coreLeft = x1 - this.coreThickness;

        this.drawCore(
            g,
            exporting,
            coreWidth,
            coreHeight,
            coreTop,
            coreLeft
        );
    }



    drawCore(
        g: any,
        exporting: boolean,
        width: number,
        height: number,
        top: number,
        left: number) {

        const d = 'M '
            + left + ',' + top + ' '
            + (left + width) + ',' + top + ' '
            + (left + width) + ',' + (top + height) + ' '
            + left + ',' + (top + height) + ' '
            + left + ',' + top + ' '
            + 'M '
            + (left + this.coreThickness) + ',' + (top + this.coreThickness) + ' '
            + (left + width - this.coreThickness) + ',' + (top + this.coreThickness) + ' '
            + (left + width - this.coreThickness) + ',' + (top + height - this.coreThickness) + ' '
            + (left + this.coreThickness) + ',' + (top + height - this.coreThickness) + ' '
            + (left + this.coreThickness) + ',' + (top + this.coreThickness) + ' '
            + 'z';

        g.append('path')
            .attr('d', d)
            .style('fill', 'ghostwhite')
            .style('fill-rule', 'evenodd')
            .attr('stroke', 'lightgray')
            .attr('stroke-width', 3);
    }



}
