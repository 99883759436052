import {Component} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'start-button',
    templateUrl: './start-button.component.html',
    styleUrls: ['./start-button.component.css']
})
export class StartButtonComponent {
    constructor(public userService: UserService) {
    }
}
