import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import {PayModalService} from '../../services/pay-modal.service';
import {ProfileService} from '../../views/profile/profile.service';
import {StudioService} from '../../views/studio/services/studio.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'pay-modal',
    templateUrl: './pay-modal.component.html',
    styleUrls: ['./pay-modal.component.css']
})
export class PayModalComponent implements OnInit {
    active: boolean;
    elements: Elements;
    card: StripeElement;
    elementsOptions: ElementsOptions = {};
    stripeTest: FormGroup;
    successMessage: string;
    errorMessage: string;
    spinner: boolean;
    private subject1 = new Subject<number>();
    constructor(
        private fb: FormBuilder,
        private stripeService: StripeService,
        public payModalService: PayModalService,
        public profileService: ProfileService,
        public studioService: StudioService) {}



    ngOnInit () {
        this.stripeTest = this.fb.group({
            name: ['', [Validators.required]]
        });
    }



    buy() {
        this.spinner = true;
        const name = this.stripeTest.get('name').value;
        this.stripeService
            .createToken(this.payModalService.card, { name })
            .subscribe(result => {
                if (result.token) {
                    this.payModalService
                        .post(result)
                        .subscribe(
                        (data: { message: string, success: boolean }) => {
                            this.active = false;
                            if (data.success) {
                                this.successMessage = data.message;
                                this.errorMessage = '';
                                this.studioService.subject2.next(true);
                            }
                            else {
                                this.successMessage = '';
                                this.errorMessage = data.message;
                                this.studioService.subject2.next(false);
                            }
                            this.spinner = false;
                        },
                        error => {
                            this.errorMessage = 'Error: ' + error.statusText;
                            this.spinner = false;
                        });
                }
                else if (result.error) {
                    this.errorMessage = result.error.message;
                    this.spinner = false;
                }
            });
    }



    close() {
        this.payModalService.active = false;
        this.successMessage = '';
        this.errorMessage = '';
        this.profileService.subject1.next();
        this.studioService.subject1.next();
    }



}
