import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'home-glass',
    templateUrl: './home-glass.component.html',
    styleUrls: ['./home-glass.component.css']
})
export class HomeGlassComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}
