import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {D3Service} from 'd3-ng2-service';
import {ColorPickerModule} from 'ngx-color-picker';
import {UserService} from './services/user.service';
import {RegisterService} from './views/register/register.service';
import {ValidateService} from './views/validate/validate.service';
import {PasswordService} from './services/password.service';
import {LoginService} from './services/login.service';
import {ProfileService} from './views/profile/profile.service';
import {ProjectService} from './views/studio/services/project.service';
import {StudioService} from './views/studio/services/studio.service';
import {ProjectsService} from './views/studio/services/projects.service';
import {OrdersService} from './views/orders/orders.service';
import {BlocksService} from './views/studio/services/blocks.service';
import {LayersService} from './views/studio/services/layers.service';
import {StarburstService} from './views/studio/services/starburst.service';
import {TitleService} from './views/studio/services/title.service';
import {SubjectsService} from './views/studio/services/subjects.service';
import {FrameService} from './views/studio/services/frame.service';
import {MatService} from './views/studio/services/mat.service';
import {GridService} from './views/studio/services/grid.service';
import {ExportService} from './views/studio/services/export.service';
import {FontsBase64Service} from './views/studio/services/fonts-base64.service';
import './rxjs-operators';
import {NgxUploaderModule} from 'ngx-uploader';
import {NgxStripeModule} from 'ngx-stripe';

import {AppComponent} from './app.component';

import {DiyComponent} from './views/diy/diy.component';
import {HomeComponent} from './views/home/home.component';
import {EmailComponent} from './views/email/email.component';
import {ExportComponent} from './views/export/export.component';
import {FeaturesComponent} from './views/features/features.component';
import {StudioComponent} from './views/studio/studio.component';
import {OrdersComponent} from './views/orders/orders.component';
import {ContactComponent} from './views/contact/contact.component';
import {ForgotComponent} from './views/forgot/forgot.component';
import {RegisterComponent} from './views/register/register.component';
import {ProfileComponent} from './views/profile/profile.component';
import {ValidateComponent} from './views/validate/validate.component';
import {PasswordComponent} from './views/password/password.component';
import {LogoutComponent} from './views/logout/logout.component';

import {FeaturesOverviewComponent} from './views/features/features-overview/features-overview.component';
import {FeaturesStepsComponent} from './views/features/features-steps/features-steps.component';
import {FeaturesVideosComponent} from './views/features/features-videos/features-videos.component';
import {StartButtonComponent} from './common/start-button/start-button.component';
import {ProfilePlansComponent} from './views/profile/profile-plans/profile-plans.component';
import {TemplatesModalComponent} from './views/studio/panels/templates-modal/templates-modal.component';
import {ErrorMessageComponent} from './views/studio/common/error-message/error-message.component';
import {TierUsageComponent} from './views/studio/common/tier-usage/tier-usage.component';
import {PayModalComponent} from './common/pay-modal/pay-modal.component';
import {PayModalService} from './services/pay-modal.service';
import {DowngradeModalComponent} from './views/profile/downgrade-modal/downgrade-modal.component';
import {DowngradeModalService} from './views/profile/downgrade-modal.service';
import { PricesComponent } from './views/prices/prices.component';
import { BottomNavComponent } from './common/bottom-nav/bottom-nav.component';
import { HomeFeatureComponent } from './views/home/home-feature/home-feature.component';
import { HomeGlassComponent } from './views/home/home-glass/home-glass.component';
import { HomeDifferencesComponent } from './views/home/home-differences/home-differences.component';
import { SliderComponent } from './views/studio/common/slider/slider.component';
import { JoinListComponent } from './common/join-list/join-list.component';

import { FilterPipe } from './pipes/filter.pipe';
import { InfoPanelComponent } from './views/studio/panels/info-panel/info-panel.component';
import { MenuPanelComponent } from './views/studio/common/menu-panel/menu-panel.component';
import { PhotoMountComponent } from './views/diy/photo-mount/photo-mount.component';
import { TemplatesSizesComponent } from './views/studio/panels/templates-modal/templates-sizes/templates-sizes.component';
import { TemplatesElasticComponent } from './views/studio/panels/templates-modal/templates-elastic/templates-elastic.component';
import { SuppliesComponent } from './views/supplies/supplies.component';



@NgModule({
    imports: [
        BrowserModule,
        NgxUploaderModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot([
            {path: '', component: HomeComponent},
            {path: 'contact', component: ContactComponent},
            {path: 'diy', component: DiyComponent},
            {path: 'email-list', component: EmailComponent},
            {path: 'export', component: ExportComponent},
            {path: 'features', component: FeaturesComponent},
            {path: 'forgot', component: ForgotComponent},
            {path: 'logout', component: LogoutComponent},
            {path: 'orders', component: OrdersComponent},
            {path: 'password', component: PasswordComponent},
            {path: 'photo-mount', component: PhotoMountComponent},
            {path: 'prices', component: PricesComponent},
            {path: 'profile', component: ProfileComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'supplies', component: SuppliesComponent},
            {path: 'studio', component: StudioComponent},
            {path: 'validate', component: ValidateComponent},
            {path: '**', redirectTo: ''}
        ]),
        ColorPickerModule,
        NgxStripeModule.forRoot((window.location.hostname === 'localhost') ? 'pk_test_YMWoRxe4YpqeorcdmvvDurbC' : 'pk_live_qE20mBKB2ddNa31MBbAutQNi')
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        EmailComponent,
        ExportComponent,
        StudioComponent,
        OrdersComponent,
        FeaturesComponent,
        DiyComponent,
        ContactComponent,
        RegisterComponent,
        ForgotComponent,
        ProfileComponent,
        ValidateComponent,
        PasswordComponent,
        LogoutComponent,
        FeaturesOverviewComponent,
        FeaturesStepsComponent,
        FeaturesVideosComponent,
        StartButtonComponent,
        ProfilePlansComponent,
        TemplatesModalComponent,
        ErrorMessageComponent,
        TierUsageComponent,
        PayModalComponent,
        DowngradeModalComponent,
        PricesComponent,
        BottomNavComponent,
        HomeFeatureComponent,
        HomeDifferencesComponent,
        SliderComponent,
        EmailComponent,
        JoinListComponent,
        FilterPipe,
        InfoPanelComponent,
        MenuPanelComponent,
        PhotoMountComponent,
        TemplatesSizesComponent,
        TemplatesElasticComponent,
        SuppliesComponent,
        HomeGlassComponent
    ],
    exports: [AppComponent],
    providers: [
        D3Service,
        RegisterService,
        UserService,
        ValidateService,
        PasswordService,
        LoginService,
        ProfileService,
        StudioService,
        ProjectService,
        ProjectsService,
        OrdersService,
        BlocksService,
        LayersService,
        StarburstService,
        TitleService,
        SubjectsService,
        FrameService,
        MatService,
        GridService,
        ExportService,
        FontsBase64Service,
        PayModalService,
        DowngradeModalService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
