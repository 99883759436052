import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from '../interfaces/Subject';
import {ProjectService} from "./project.service";

@Injectable()
export class SubjectsService {

    private subject: Subject;
    private g: any;
    private index: number;
    private originalX: number;
    private originalY: number;
    private originalWidth: number;
    private originalHeight: number;
    private width: number;
    private height: number;
    textSpace: {
        x: number,
        centerX: number,
        centerY: number,
        width: number,
        height: number
    };
    lineRatio1: number;
    lineRatio2: number;
    line1Element: any = {};
    line2Element: any = {};
    textLabels: string;
    labelHeight: number;
    colorPresetBackground: string;
    colorPresetColor: string;
    textPosition: string;
    textAlignment: string;
    fontSizesLabel: string;
    defaultFont1Index: number;
    defaultFont2Index: number;
    labelFontScale: number;
    exporting: boolean;
    private mainImageWidth = 0;
    private mainImageHeight = 0;
    private tileImageWidth = 0;
    private tileImageHeight = 0;

    constructor(private router: Router,
                private projectService: ProjectService) {
    }


    public setMainImageWidth(width) {
        this.mainImageWidth = width;
    }


    public setMainImageHeight(height) {
        this.mainImageHeight = height;
    }


    public setTileImageWidth(width) {
        this.tileImageWidth = width;
    }


    public setTileImageHeight(height) {
        this.tileImageHeight = height;
    }


    public get getMainImageWidth() {
        return this.mainImageWidth;
    }


    public get getMainImageHeight() {
        return this.mainImageHeight;
    }


    public get getTileImageWidth() {
        return this.tileImageWidth;
    }


    public get getTileImageHeight() {
        return this.tileImageHeight;
    }


    transformBorder(that) {
        const useSize = (this.width > this.height) ? this.height : this.width;
        const thickness = (!this.index) ? that.anchorBorderThickness : that.borderThickness;
        const border = useSize * thickness;
        this.width -= (border * 2);
        this.height -= (border * 2);
        this.subject.x += border;
        this.subject.y += border;
    }



    drawRect(that: any, svg: any, dpi: number, index: number, width: number, height: number, exporting: boolean) {

        this.exporting = exporting;
        this.subject = that.subjects[index];
        this.index = index;
        this.originalX = this.subject.x;
        this.originalY = this.subject.y;
        this.originalWidth = width;
        this.originalHeight = height;
        this.width = width;
        this.height = height;
        this.transformBorder(that);

        this.g = svg.append('g')
            .attr('class', 'image')
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
            .style('opacity', () => (that.gridActive) ? '0.3' : '1');

        if (this.subject.id && !this.subject.skip) {

            const
                fileKey = this.subject.id,
                useFile = (this.router.routerState.snapshot.url === '/export') ? '_' + fileKey : fileKey,
                offsetScale = (this.subject.offsetScale) ? this.subject.offsetScale : 1,
                offsetX = (this.subject.offsetX !== undefined) ? this.subject.offsetX : 0.5,
                offsetY = (this.subject.offsetY !== undefined) ? this.subject.offsetY : 0.5,
                ratio = (this.width / this.subject.originalImageWidth > this.height / this.subject.originalImageHeight)
                    ? this.width / this.subject.originalImageWidth : this.height / this.subject.originalImageHeight;

            this.g.append('image')
                .attr('crossorigin', 'anonymous')
                .attr('id', (this.exporting) ? 'image-exporting-' + this.index : 'image-' + this.index)
                .attr('clip-path', (this.exporting) ? 'url(#clip-path-exporting-' + index + ')' : 'url(#clip-path-' + index + ')')
                .attr('href', 'https://images.instantlayout.com/' +
                    that.project.user.replace('@', '%40') + '/' +
                    'projects/' +
                    this.projectService.id + '/' + useFile)
                .attr('x', () => {
                    const
                        diff = (this.subject.originalImageWidth * ratio * offsetScale) - this.width,
                        offset = diff * offsetX;
                    return this.subject.x + offset - diff;
                })
                .attr('y', () => {
                    const
                        diff = (this.subject.originalImageHeight * ratio * offsetScale) - this.height,
                        offset = diff * offsetY;
                    return this.subject.y + offset - diff;
                })
                .attr('width', () => {
                    return this.subject.originalImageWidth * offsetScale * ratio;
                })
                .attr('height', () => {
                    return this.subject.originalImageHeight * offsetScale * ratio;
                })
                .attr('fill', (this.subject.skip) ? 'transparent' : () => {
                    if (this.subject.backgroundColor) {
                        return this.subject.backgroundColor;
                    }
                    else if (this.index) {
                        return that.tilesBackgroundColor;
                    }
                    else {
                        return that.mainBackgroundColor;
                    }
                })
                .attr('preserveAspectRatio', 'xMidYMid slice')
                .style('cursor', 'pointer')
                .attr('class', () => (!that.mouseIsDown && ((!this.index && that.anchorModal) || (this.index && that.chartModal && this.index === +that.subjectIndex) || (!that.subjectIndex && that.chartModal && this.index))) ? 'selector-fader' : '')
                .on('mousedown', () => {
                    that.imageMoved = false;
                })
                .on('mousemove', () => {
                    that.imageMoved = true;
                })
                .on('click', () => {
                    (index => {
                        if (!that.imageMoved) {
                            that.subjectTextIndex = index;
                            that.openUploadModal(index);
                        }
                        that.imageMoved = false;
                        that.d3.event.stopPropagation();
                    })(index);
                });

        }
        else {

            this.g.append('rect')
                .attr('id', (this.exporting) ? 'rect-exporting-' + this.index : 'rect-' + this.index)
                .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
                .attr('x', this.subject.x)
                .attr('y', this.subject.y)
                .attr('width', this.width)
                .attr('height', this.height)
                .attr('fill', (this.subject.skip) ? 'transparent' : () => {
                    if (this.subject.backgroundColor) {
                        return this.subject.backgroundColor;
                    }
                    else if (this.index) {
                        return that.tilesBackgroundColor;
                    }
                    else {
                        return that.mainBackgroundColor;
                    }
                })
                .style('cursor', 'pointer')
                .attr('class', () => (!that.mouseIsDown && ((!this.index && that.anchorModal) || (this.index && that.chartModal && this.index === +that.subjectIndex) || (!that.subjectIndex && that.chartModal && this.index))) ? 'selector-fader' : '')
                .on('mousedown', () => {
                    that.imageMoved = false;
                })
                .on('mousemove', () => {
                    that.imageMoved = true;
                })
                .on('click', () => {
                    ((index) => {
                        if (!that.imageMoved) {
                            that.subjectTextIndex = index;
                            that.openUploadModal(index);
                        }
                        that.imageMoved = false;
                        that.d3.event.stopPropagation();
                    })(index);
                });

        }

        this.g.append('defs')
            .append('clipPath')
            .attr('id', (this.exporting) ? 'clip-path-exporting-' + this.index : 'clip-path-' + this.index)
            .append('rect')
            .attr('x', this.subject.x)
            .attr('y', this.subject.y)
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('stroke-width', 0);

        this.drawTextLabels(that, dpi, index);
        this.drawRectOutline(that);
    }


    drawRectOutline(that) {

        if (this.subject.skip) {
            return;
        }

        const thickness = this.originalWidth - this.width;
        this.g.append('rect')
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
            .attr('x', this.originalX + (thickness / 4))
            .attr('y', this.originalY + (thickness / 4))
            .attr('width', this.originalWidth - (thickness / 2))
            .attr('height', this.originalHeight - (thickness / 2))
            .attr('stroke-width', thickness / 2)
            .attr('stroke', () => {
                if (this.subject.borderColor) {
                    return this.subject.borderColor;
                }
                else if (this.index) {
                    return that.tilesBorderColor;
                }
                else {
                    return that.mainBorderColor;
                }
            })
            .attr('fill', 'none');

    }


    drawEllipse(that: any, svg: any, dpi: number, index: number, width: number, height: number, exporting: boolean) {

        this.exporting = exporting;
        this.subject = that.subjects[index];
        this.index = index;
        this.originalX = this.subject.x;
        this.originalY = this.subject.y;
        this.originalWidth = width;
        this.originalHeight = height;
        this.width = width;
        this.height = height;
        this.transformBorder(that);

        this.g = svg.append('g')
            .attr('class', 'image')
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
            .style('opacity', () => (that.gridActive) ? '0.3' : '1');

        if (this.subject.id) {

            const fileKey = this.subject.id;
            const useFile = (this.router.routerState.snapshot.url === '/export') ? '_' + fileKey : fileKey;
            const offsetScale = (this.subject.offsetScale) ? this.subject.offsetScale : 1;
            const offsetX = (this.subject.offsetX !== undefined) ? this.subject.offsetX : 0.5;
            const offsetY = (this.subject.offsetY !== undefined) ? this.subject.offsetY : 0.5;
            const ratio = (this.width / this.subject.originalImageWidth > this.height / this.subject.originalImageHeight) ? this.width / this.subject.originalImageWidth : this.height / this.subject.originalImageHeight;

            this.g.append('image')
                .attr('crossorigin', 'anonymous')
                .attr('id', (this.exporting) ? 'image-exporting-' + this.index : 'image-' + this.index)
                .attr('href', 'https://images.instantlayout.com/' +
                    that.project.user.replace('@', '%40') + '/' +
                    'projects/' +
                    that.project.id + '/' + useFile)
                .attr('clip-path', (this.exporting) ? 'url(#clip-path-exporting-' + index + ')' : 'url(#clip-path-' + index + ')')
                .attr('x', () => {
                    const
                        diff = (this.subject.originalImageWidth * ratio * offsetScale) - this.width,
                        offset = diff * offsetX;
                    return this.subject.x + offset - diff;
                })
                .attr('y', () => {
                    const
                        diff = (this.subject.originalImageHeight * ratio * offsetScale) - this.height,
                        offset = diff * offsetY;
                    return this.subject.y + offset - diff;
                })
                .attr('width', () => {
                    return this.subject.originalImageWidth * offsetScale * ratio;
                })
                .attr('height', () => {
                    return this.subject.originalImageHeight * offsetScale * ratio;
                })
                .attr('preserveAspectRatio', 'xMidYMid slice')
                .style('cursor', 'pointer')
                .on('mousedown', () => that.imageMoved = false)
                .on('mousemove', () => that.imageMoved = true)
                .on('click', () => {
                    ((index) => {
                        if (!that.imageMoved) {
                            that.subjectTextIndex = index;
                            that.openUploadModal(index);
                        }
                        that.imageMoved = false;
                        that.d3.event.stopPropagation();
                    })(index);
                });

        }
        else {

            this.g.append('ellipse')
                .attr('id', (this.exporting) ? 'ellipse-exporting-' + this.index : 'ellipse-' + this.index)
                .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
                .attr('cx', this.subject.x + this.width / 2)
                .attr('cy', this.subject.y + this.height / 2)
                .attr('rx', this.width / 2)
                .attr('ry', this.height / 2)
                .attr('fill', (this.subject.skip) ? 'transparent' : () => {
                    if (this.subject.backgroundColor) {
                        return this.subject.backgroundColor;
                    }
                    else if (this.index) {
                        return that.tilesBackgroundColor;
                    }
                    else {
                        return that.mainBackgroundColor;
                    }
                })
                .style('cursor', 'pointer')
                .on('mousedown', () => that.imageMoved = false)
                .on('mousemove', () => that.imageMoved = true)
                .on('click', () => {
                    ((index) => {
                        if (!that.imageMoved) {
                            that.subjectTextIndex = index;
                            that.openUploadModal(index);
                        }
                        that.imageMoved = false;
                        that.d3.event.stopPropagation();
                    })(index);
                });

        }

        this.g.append('rect')
            .attr('x', this.subject.x)
            .attr('y', this.subject.y)
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('fill', 'transparent')
            .style('cursor', 'pointer')
            .attr('class', () => (!that.mouseIsDown && ((!this.index && that.anchorModal) || (this.index && that.chartModal && this.index === +that.subjectIndex) || (!that.subjectIndex && that.chartModal && this.index))) ? 'selector-fader' : '')
            .on('mousedown', () => {
                that.imageMoved = false;
            })
            .on('mousemove', () => {
                that.imageMoved = true;
            })
            .on('click', () => {
                ((index) => {
                    if (!that.imageMoved) {
                        that.subjectTextIndex = index;
                        that.openUploadModal(index);
                    }
                    that.imageMoved = false;
                    that.d3.event.stopPropagation();
                })(index);
            });

        this.g.append('defs')
            .append('clipPath')
            .attr('id', (this.exporting) ? 'clip-path-exporting-' + this.index : 'clip-path-' + this.index)
            .append('ellipse')
            .attr('cx', this.subject.x + (this.width / 2))
            .attr('cy', this.subject.y + (this.height / 2))
            .attr('rx', (this.width / 2))
            .attr('ry', (this.height / 2))
            .append('rect')
            .attr('transform', 'translate(' + (that.centerX - this.projectService.paperWidth / 2) + ', ' + (that.centerY - this.projectService.paperHeight / 2) + ')')
            .attr('width', this.projectService.paperWidth)
            .attr('height', this.projectService.paperHeight);

        this.drawTextLabels(that, dpi, index);
        this.drawEllipseOutline(that);
    }


    drawEllipseOutline(that) {

        if (this.subject.skip) {
            return;
        }

        const thickness = this.originalWidth - this.width;
        this.g.append('ellipse')
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
            .attr('cx', this.originalX + (this.originalWidth / 2))
            .attr('cy', this.originalY + (this.originalHeight / 2))
            .attr('rx', (this.originalWidth / 2) - (thickness / 4))
            .attr('ry', (this.originalHeight / 2) - (thickness / 4))
            .attr('stroke-width', thickness / 2)
            .attr('stroke', () => {
                if (this.subject.borderColor) {
                    return this.subject.borderColor;
                }
                else if (this.index) {
                    return that.tilesBorderColor;
                }
                else {
                    return that.mainBorderColor;
                }
            })
            .attr('fill', 'none');

    }


    drawTextLabels(that, dpi, index) {

        if ((!that.textLabels && !that.mainTextLabels) || this.subject.skip || this.subject.hideLabel) {
            return;
        }

        if (!index) {
            this.textLabels = that.mainTextLabels;
            this.labelHeight = that.mainLabelHeight;
            this.colorPresetBackground = that.mainColorPresetBackground;
            this.colorPresetColor = that.mainColorPresetColor;
            this.textPosition = that.mainTextPosition;
            this.textAlignment = that.mainTextAlignment;
            this.fontSizesLabel = that.mainFontSizesLabel;
            this.defaultFont1Index = that.mainFont1Index;
            this.defaultFont2Index = that.mainFont2Index;
            this.labelFontScale = that.mainLabelFontScale;
        }
        else {
            this.textLabels = that.textLabels;
            this.labelHeight = that.labelHeight;
            this.colorPresetBackground = that.colorPresetBackground;
            this.colorPresetColor = that.colorPresetColor;
            this.textPosition = that.textPosition;
            this.textAlignment = that.textAlignment;
            this.fontSizesLabel = that.fontSizesLabel;
            this.defaultFont1Index = that.defaultFont1Index;
            this.defaultFont2Index = that.defaultFont2Index;
            this.labelFontScale = that.labelFontScale;
        }

        if ((index && !that.textLabels) || (!index && !that.mainTextLabels)) {
            return;
        }
        this.drawLabelRect(that, dpi, index);
        this.drawLabelText(that, index);
        this.scaleFontSizes(that, index);
        this.stackTextLines(that, index);
        this.positionText(that, index);
        this.offsetAlignment(that, index);
    }


    drawLabelRect(that, dpi, index) {

        let
            x,
            y,
            width,
            height,
            rectHeight,
            bleedShift = (this.projectService.frameSize || this.projectService.matSize)
                && that.subjects.length === 1
                && that.anchorScale === 1
                && !that.chartMargin
                && that.titleSize < 1
                && that.anchorShape === 'rect'
                ? 0.25 * dpi
                : 0,
            // bleedShift = 0, // more accurate for label, but image still expands
            bleedShiftX = 0,
            bleedShiftY = 0,
            bleedShiftWidth = 0,
            bleedShiftHeight = 0;

        switch (this.textLabels) {
            case 'top':
                x = this.subject.x;
                y = this.subject.y;
                width = this.width;
                height = this.height * this.labelHeight;
                break;
            case 'bottom':
                x = this.subject.x;
                y = this.subject.y + this.height - (this.height * this.labelHeight);
                width = this.width;
                height = this.height * this.labelHeight;
                break;
        }


        rectHeight = height;
        if (that.type === 'Blocks'
            && that.subjects.length === 1
            && that.anchorScale === 1
            && !that.chartMargin
            && (this.projectService.frameSize || this.projectService.matSize)) {

            switch (this.textLabels) {
                case 'top':
                    if (that.titleText !== 'top' || (that.titleText === 'top' && !that.titleSize)) {
                        rectHeight += bleedShift;
                        bleedShiftY = bleedShift;
                    }
                    bleedShiftWidth = bleedShift * 2;
                    bleedShiftX = bleedShift;
                    break;
                case 'bottom':
                    if (that.titleText !== 'bottom' || (that.titleText === 'bottom' && !that.titleSize)) {
                        rectHeight += bleedShift;
                        y -= bleedShift;
                    }
                    bleedShiftWidth = bleedShift * 2;
                    bleedShiftX = bleedShift;
                    break;
            }
        }

        this.g.append('rect')
            .attr('id', (this.exporting) ? 'text-label-rect-exporting-' + this.index : 'text-label-rect-' + this.index)
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-exporting-' + this.index : 'url(#clip-path-' + this.index)
            .attr('x', x)
            .attr('y', y)
            .attr('width', width)
            .attr('height', rectHeight)
            .attr('fill', () => {
                if (this.subject.hideLabel) {
                    return 'none';
                }
                else if (this.subject.labelBackgroundColor) {
                    return this.subject.labelBackgroundColor;
                }
                return this.colorPresetBackground;
            })
            .style('cursor', 'pointer')
            .on('mousedown', () => that.imageMoved = false)
            .on('mousemove', () => that.imageMoved = true)
            .on('click', () => {
                ((index) => {
                    if (!that.imageMoved) {
                        that.subjectTextIndex = index;
                        that.openUploadModal(index);
                    }
                    that.imageMoved = false;
                    that.d3.event.stopPropagation();
                })(index);
            });

        // if ((this.colorPresetBackground === 'white') && ((!that.anchorBorderThickness && !index) || (index && !that.borderThickness))) {
        //     this.g.append('rect')
        //         .attr('id', (this.exporting) ? 'text-label-rect-border-exporting-' + this.index : 'text-label-rect-border-' + this.index)
        //         .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)')
        //         .attr('x', this.subject.x)
        //         .attr('y', y)
        //         .attr('width', width)
        //         .attr('height', height)
        //         .attr('fill', 'white')
        //         .attr('stroke', 'white')
        //         .attr('stroke-width', that.zoom);
        // }

        const verticalMargin = (width > height) ? (height - (height / 1.5)) / 2 : (width - (width / 1.5)) / 2;
        const horizontalMargin = (bleedShiftWidth) ? (width - bleedShiftWidth) * (1 / 12) : width * (1 / 12);
        const useWidth = (bleedShiftWidth) ? width - bleedShiftWidth : width;

        this.textSpace = {
            x: this.subject.x + horizontalMargin + bleedShiftX,
            centerX: this.subject.x + (useWidth * 0.5) + bleedShiftX,
            centerY: y + ((height) / 2) + bleedShiftY,
            width: useWidth - (horizontalMargin * 2),
            height: height - (verticalMargin * 2)
        };

    }


    drawLabelText(that: any, index: number) {

        let fillColor = this.subject.labelTextColor || this.colorPresetColor;
        let opacity = 1;

        if (fillColor.match(/rgba/)) {

            fillColor = fillColor.split(/\(/)[1];
            fillColor = fillColor.split(/\)/)[0];
            opacity = +fillColor.split(/,/)[3];
            const rgb = fillColor.split(/,/);
            rgb.pop();
            fillColor = 'rgb(' + rgb.join(',') + ')';
        }

        if (!index) {
            this.lineRatio1 = (1 - that.mainFontSizes) * 10;
            this.lineRatio2 = that.mainFontSizes * 10;
        }
        else {
            this.lineRatio1 = (1 - that.fontSizes) * 10;
            this.lineRatio2 = that.fontSizes * 10;
        }

        this.line1Element[index] = this.g.append('text')
            .text(that.subjects[index].line1)
            .attr('id', (this.exporting) ? 'label-text-exporting-' + index + '-' + 1 : 'label-text-' + index + '-' + 1)
            .style('font-weight', () => that.subjects[index].bold1 ? 'bold' : 'normal')
            .style('font-style', () => that.subjects[index].italic1 ? 'italic' : 'normal')
            .style('text-decoration', () => that.subjects[index].underline1 ? 'underline' : '')
            .style('font-family', '\'' + that.fonts[this.defaultFont1Index].id + '\', \'Deja Vu\'')
            // .attr('dominant-baseline', 'middle')
            .attr('text-anchor', () => {
                switch (this.textPosition) {
                    case 'left':
                        return 'start';
                    case 'right':
                        return 'end';
                    default:
                        return 'middle';
                }
            })
            .attr('x', this.textSpace.centerX)
            .attr('y', this.textSpace.centerY)
            .style('cursor', 'pointer')
            .style('font-size', this.lineRatio1 + 'px')
            .style('fill', () => this.subject.hideLabel ? 'none' : fillColor)
            .style('opacity', opacity)
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-exporting-' + this.index : 'url(#clip-path-' + this.index)
            .on('mousedown', () => {
                that.imageMoved = false;
            })
            .on('mousemove', () => {
                that.imageMoved = true;
            })
            .on('click', () => {
                ((index) => {
                    if (!that.imageMoved) {
                        that.subjectTextIndex = index;
                        that.openUploadModal(index);
                    }
                    that.imageMoved = false;
                    that.d3.event.stopPropagation();
                })(index);
            });

        this.line2Element[index] = this.g.append('text')
            .text(that.subjects[index].line2)
            .attr('id', (this.exporting) ? 'label-text-exporting-' + index + '-' + 2 : 'label-text-' + index + '-' + 2)
            .style('font-weight', () => that.subjects[index].bold2 ? 'bold' : 'normal')
            .style('font-style', () => that.subjects[index].italic2 ? 'italic' : 'normal')
            .style('text-decoration', () => that.subjects[index].underline2 ? 'underline' : '')
            .style('font-family', '\'' + that.fonts[this.defaultFont2Index].id + '\', \'Deja Vu\'')
            // .attr('dominant-baseline', 'middle')
            .attr('text-anchor', () => {
                switch (this.textPosition) {
                    case 'left':
                        return 'start';
                    case 'right':
                        return 'end';
                    default:
                        return 'middle';
                }
            })
            .attr('x', this.textSpace.centerX)
            .attr('y', this.textSpace.centerY)
            .style('cursor', 'pointer')
            .style('font-size', this.lineRatio2 + 'px')
            .style('fill', () => this.subject.hideLabel ? 'none' : fillColor)
            .style('opacity', opacity)
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-exporting-' + this.index : 'url(#clip-path-' + this.index)
            .on('mousedown', () => {
                that.imageMoved = false;
            })
            .on('mousemove', () => {
                that.imageMoved = true;
            })
            .on('click', () => {
                ((index) => {
                    if (!that.imageMoved) {
                        that.subjectTextIndex = index;
                        that.openUploadModal(index);
                    }
                    that.imageMoved = false;
                    that.d3.event.stopPropagation();
                })(index);
            });

    }


    scaleFontSizes(that, index) {

        const
            textWidth1 = this.line1Element[index].node().getComputedTextLength(),
            textWidth2 = this.line2Element[index].node().getComputedTextLength(),
            largerLineWidth = (textWidth1 > textWidth2) ? textWidth1 : textWidth2,
            widthScale = this.textSpace.width / largerLineWidth;

        this.line1Element[index].style('font-size', (this.lineRatio1 * widthScale) + 'px');
        this.line2Element[index].style('font-size', (this.lineRatio2 * widthScale) + 'px');

        const
            textHeight1 = this.line1Element[index].node().getBBox().height,
            textHeight2 = this.line2Element[index].node().getBBox().height,
            totalTextHeight = textHeight1 + textHeight2,
            heightScale = this.textSpace.height / totalTextHeight;

        if (this.textSpace.height < totalTextHeight) {
            this.line1Element[index].style('font-size', (this.lineRatio1 * widthScale * heightScale * this.labelFontScale) + 'px');
            this.line2Element[index].style('font-size', (this.lineRatio2 * widthScale * heightScale * this.labelFontScale) + 'px');
        }
        else {
            this.line1Element[index].style('font-size', (this.lineRatio1 * widthScale * this.labelFontScale) + 'px');
            this.line2Element[index].style('font-size', (this.lineRatio2 * widthScale * this.labelFontScale) + 'px');
        }

    }



    stackTextLines(that, index) {

        const
            textHeight1 = this.line1Element[index].node().getBBox().height,
            textHeight2 = this.line2Element[index].node().getBBox().height,
            offset1 = (textHeight2 / 2),
            offset2 = (textHeight1 / 2),
            middleLine1 = textHeight1 / 4,
            middleLine2 = textHeight2 / 4;

        this.line1Element[index].attr('y', +this.line1Element[index].attr('y') - offset1 + middleLine1);
        this.line2Element[index].attr('y', +this.line2Element[index].attr('y') + offset2 + middleLine2);

    }



    positionText(that, index) {

        switch (this.textPosition) {
            case 'left':
                this.line1Element[index].attr('x', this.textSpace.x);
                this.line2Element[index].attr('x', this.textSpace.x);
                break;
            case 'right':
                this.line1Element[index].attr('x', () => this.textSpace.x + this.textSpace.width);
                this.line2Element[index].attr('x', () => this.textSpace.x + this.textSpace.width);
                break;
        }

    }



    offsetAlignment(that: any, index: number) {

        if (that.subjects[index].line1 === '' || that.subjects[index].line2 === '') {
            return;
        }

        const vector1 = this.line1Element[index].node().getBBox();
        const vector2 = this.line2Element[index].node().getBBox();

        if (vector1.width >= vector2.width) {
            switch (this.textAlignment) {
                case 'left':
                    switch (this.textPosition) {
                        case 'center':
                            this.line2Element[index]
                                .attr('x', vector2.x - ((vector1.width - vector2.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'right':
                            this.line2Element[index]
                                .attr('x', vector2.x - vector1.width + vector2.width)
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'center':
                    switch (this.textPosition) {
                        case 'left':
                            this.line2Element[index]
                                .attr('x', vector2.x + ((vector1.width - vector2.width) * 0.5))
                                .attr('text-anchor', 'start');
                            break;
                        case 'right':
                            this.line2Element[index]
                                .attr('x', vector2.x - ((vector1.width - vector2.width) * 0.5))
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'right':
                    switch (this.textPosition) {
                        case 'left':
                            this.line2Element[index]
                                .attr('x', vector1.x + vector1.width)
                                .attr('text-anchor', 'end');
                            break;
                        case 'center':
                            this.line2Element[index]
                                .attr('x', vector2.x + (vector1.width / 2) + (vector2.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                    }
                    break;
            }
        }
        else if (vector2.width > vector1.width) {
            switch (this.textAlignment) {
                case 'left':
                    switch (this.textPosition) {
                        case 'center':
                            this.line1Element[index]
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'right':
                            this.line1Element[index]
                                .attr('x', vector1.x - vector2.width + vector1.width)
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'center':
                    switch (this.textPosition) {
                        case 'left':
                            this.line1Element[index]
                                .attr('x', vector1.x + ((vector2.width - vector1.width) / 2));
                            break;
                        case 'right':
                            this.line1Element[index]
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'right':
                    switch (this.textPosition) {
                        case 'left':
                            this.line1Element[index]
                                .attr('x', vector1.x + vector2.width)
                                .attr('text-anchor', 'end');
                            break;
                        case 'center':
                            this.line1Element[index]
                                .attr('x', vector1.x + (vector2.width / 2) + (vector1.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                    }
                    break;
            }
        }
    }


}
