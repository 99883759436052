import {Injectable} from '@angular/core';
import {ProjectService} from "./project.service";

@Injectable()
export class GridService {

    constructor (private projectService: ProjectService) {}


    draw (that: any) {
        this.drawGridLines(that);
        this.drawCenterLines(that);
        this.drawFoldV(that);
        this.drawFoldH(that);
        this.drawThirdsLines(that);
        this.drawPosterMarkers(that);
        this.drawRevealMarkers(that);
    }



    drawGridLines (that: any) {
        let i;
        let counter;
        const step = that.dpi;
        const useWidth = 24 * step;
        const useHeight = 24 * step;
        const g = that.svg.append('g').attr('id', 'grid-lines');
        counter = 0;
        for (i = that.width / 2 - step; i > that.centerX - useWidth; i -= step) {
            counter++;
            g.append('line')
                .attr('x1', i)
                .attr('x2', i)
                .attr('y1', that.centerY - useHeight)
                .attr('y2', that.centerY + useHeight)
                .attr('stroke-width', () => {
                    if (!(counter % 12)) {
                        return 15;
                    }
                    else if (!(counter % 3)) {
                        return 8;
                    }
                    return 5;
                })
                .attr('stroke', 'steelblue');
        }
        counter = 0;
        for (i = that.width / 2 + step; i < that.centerX + useWidth; i += step) {
            counter++;
            g.append('line')
                .attr('x1', i)
                .attr('x2', i)
                .attr('y1', that.centerY - useHeight)
                .attr('y2', that.centerY + useHeight)
                .attr('stroke-width', () => {
                    if (!(counter % 12)) {
                        return 15;
                    }
                    else if (!(counter % 3)) {
                        return 8;
                    }
                    return 5;
                })
                .attr('stroke', 'steelblue');
        }
        counter = 0;
        for (i = that.height / 2 - step; i > that.centerY - useHeight; i -= step) {
            counter++;
            g.append('line')
                .attr('x1', that.centerX - useWidth)
                .attr('x2', that.centerX + useWidth)
                .attr('y1', i)
                .attr('y2', i)
                .attr('stroke-width', () => {
                    if (!(counter % 12)) {
                        return 15;
                    }
                    else if (!(counter % 3)) {
                        return 8;
                    }
                    return 5;
                })
                .attr('stroke', 'steelblue');
        }
        counter = 0;
        for (i = that.height / 2 + step; i < that.centerY + useHeight; i += step) {
            counter++;
            g.append('line')
                .attr('x1', that.centerX - useWidth)
                .attr('x2', that.centerX + useWidth)
                .attr('y1', i)
                .attr('y2', i)
                .attr('stroke-width', () => {
                    if (!(counter % 12)) {
                        return 15;
                    }
                    else if (!(counter % 3)) {
                        return 8;
                    }
                    return 5;
                })
                .attr('stroke', 'steelblue');
        }
        g.style('opacity', () => (that.gridActive) ? 1 : 0);
    }


    drawCenterLines (that: any) {
        const useWidth = 24 * that.dpi;
        const useHeight = 24 * that.dpi;
        const g = that.svg.append('g').attr('id', 'center-lines');
        g.append('line')
            .attr('x1', that.centerX - useWidth)
            .attr('x2', that.centerX + useWidth)
            .attr('y1', that.centerY)
            .attr('y2', that.centerY)
            .attr('stroke-width', 15)
            .attr('stroke', 'red');
        g.append('line')
            .attr('x1', that.centerX)
            .attr('x2', that.centerX)
            .attr('y1', that.centerY - useHeight)
            .attr('y2', that.centerY + useHeight)
            .attr('stroke-width', 15)
            .attr('stroke', 'red');
        g.append('rect')
            .attr('x', that.centerX - useWidth)
            .attr('y', that.centerY - useHeight)
            .attr('width', useWidth * 2)
            .attr('height', useHeight * 2)
            .attr('stroke', 'gray')
            .attr('stroke-width', 50)
            .style('fill', 'none');
        g.append('text')
            .text('48"')
            .attr('text-anchor', 'middle')
            .attr('x', that.centerX)
            .attr('y', that.centerY - useHeight - 100)
            .attr('fill', 'gray')
            .style('font-size', '200px');
        g.append('text')
            .text('48"')
            .attr('text-anchor', 'left')
            .attr('x', that.centerX + useWidth + 100)
            .attr('y', that.centerY + 75)
            .attr('fill', 'gray')
            .style('font-size', '200px');
        g.style('opacity', () => (that.gridActive) ? 1 : 0);
    }


    drawFoldV (that: any) {
        if (!that.projectService.fold || that.projectService.fold === 'h') return;
        const useHeight = this.projectService.paperHeight * 0.5 * that.dpi;
        const g = that.svg.append('g').attr('id', 'v-fold');
        g.append('line')
            .attr('x1', that.centerX)
            .attr('x2', that.centerX)
            .attr('y1', that.centerY - useHeight)
            .attr('y2', that.centerY + useHeight)
            .attr('stroke-width', 5)
            .attr('stroke', 'rgba(0,0,0,0.15)');
    }


    drawFoldH (that: any) {
        if (!that.projectService.fold || that.projectService.fold === 'v') return;
        const useWidth = this.projectService.paperWidth * 0.5 * that.dpi;
        const g = that.svg.append('g').attr('id', 'h-fold');
        g.append('line')
            .attr('x1', that.centerX - useWidth)
            .attr('x2', that.centerX + useWidth)
            .attr('y1', that.centerY)
            .attr('y2', that.centerY)
            .attr('stroke-width', 5)
            .attr('stroke', 'rgba(0,0,0,0.15)');
    }


    drawThirdsLines (that: any) {
        let reveal = 0;
        if (this.projectService.matSize) {
            reveal = this.projectService.matSize * 2;
        }
        else if (this.projectService.frameSize) {
            reveal = 0.5;
        }
        const useWidth = ((this.projectService.paperWidth - reveal) * 0.5) * that.dpi;
        const useHeight = ((this.projectService.paperHeight - reveal) * 0.5) * that.dpi;
        const g = that.svg.append('g')
            .attr('id', 'thirds-lines')
            .style('opacity', 0.66);
        const thirdWidth = (((this.projectService.paperWidth - reveal) * that.dpi) * 0.5) * 0.333;
        const thirdHeight = (((this.projectService.paperHeight - reveal) * that.dpi) * 0.5) * 0.333;

        [
            {
                x1: that.centerX - useWidth,
                x2: that.centerX + useWidth,
                y1: that.centerY - thirdHeight,
                y2: that.centerY - thirdHeight,
                dash: 50,
                thickness: 10,
                color: 'black'
            },
            {
                x1: that.centerX - useWidth,
                x2: that.centerX + useWidth,
                y1: that.centerY + thirdHeight,
                y2: that.centerY + thirdHeight,
                dash: 50,
                thickness: 10,
                color: 'black'
            },
            {
                x1: that.centerX - thirdWidth,
                x2: that.centerX - thirdWidth,
                y1: that.centerY - useHeight,
                y2: that.centerY + useHeight,
                dash: 50,
                thickness: 10,
                color: 'black'
            },
            {
                x1: that.centerX + thirdWidth,
                x2: that.centerX + thirdWidth,
                y1: that.centerY - useHeight,
                y2: that.centerY + useHeight,
                dash: 50,
                thickness: 10,
                color: 'black'
            },
            {
                x1: that.centerX - useWidth + 50,
                x2: that.centerX + useWidth - 50,
                y1: that.centerY - thirdHeight,
                y2: that.centerY - thirdHeight,
                dash: 50,
                thickness: 10,
                color: 'white'
            },
            {
                x1: that.centerX - useWidth + 50,
                x2: that.centerX + useWidth - 50,
                y1: that.centerY + thirdHeight,
                y2: that.centerY + thirdHeight,
                dash: 50,
                thickness: 10,
                color: 'white'
            },
            {
                x1: that.centerX - thirdWidth,
                x2: that.centerX - thirdWidth,
                y1: that.centerY - useHeight + 50,
                y2: that.centerY + useHeight - 50,
                dash: 50,
                thickness: 10,
                color: 'white'
            },
            {
                x1: that.centerX + thirdWidth,
                x2: that.centerX + thirdWidth,
                y1: that.centerY - useHeight + 50,
                y2: that.centerY + useHeight - 50,
                dash: 50,
                thickness: 10,
                color: 'white'
            },
        ].forEach((o) => {
            this.drawLine(g, o.x1, o.x2, o.y1, o.y2, o.dash, o.thickness, o.color);
        });

        g.style('opacity', () => (that.thirdsActive) ? 0.66 : 0);
    }


    drawLine (g, x1, x2, y1, y2, dash, thickness, color) {
        g.append('line')
            .attr('x1', x1)
            .attr('x2', x2)
            .attr('y1', y1)
            .attr('y2', y2)
            .attr('stroke-dasharray', dash)
            .attr('stroke-width', thickness)
            .attr('stroke', color);
    }


    drawPosterMarkers (that: any) {
        const g = that.svg.append('g').attr('id', 'poster-markers').style('opacity', () => {
            return (that.dimensionsActive) ? 1 : 0;
        });
        const width = this.projectService.paperWidth * that.dpi;
        const height = this.projectService.paperHeight * that.dpi;
        const topSteps = [
            that.centerX - width / 2, ',', that.centerY - height / 2 - (150 + (this.projectService.frameSize * 300)), ' ', // 1
            that.centerX - width / 2, ',', that.centerY - height / 2 - (300 + (this.projectService.frameSize * 300)), ' ', // 2
            that.centerX + width / 2, ',', that.centerY - height / 2 - (300 + (this.projectService.frameSize * 300)), ' ', // 3
            that.centerX + width / 2, ',', that.centerY - height / 2 - (150 + (this.projectService.frameSize * 300)), // 4
        ];
        const sideSteps = [
            that.centerX - width / 2 - (150 + (this.projectService.frameSize * 300)), ',', that.centerY - height / 2, ' ', // 1
            that.centerX - width / 2 - (300 + (this.projectService.frameSize * 300)), ',', that.centerY - height / 2, ' ', // 2
            that.centerX - width / 2 - (300 + (this.projectService.frameSize * 300)), ',', that.centerY + height / 2, ' ', // 3
            that.centerX - width / 2 - (150 + (this.projectService.frameSize * 300)), ',', that.centerY + height / 2, // 4
        ];
        g.append('polyline')
            .attr('points', topSteps.join(''))
            .attr('stroke-width', 20)
            .attr('stroke', 'gray')
            .style('fill', 'none');
        g.append('text')
            .text(this.projectService.paperWidth + '"')
            .attr('text-anchor', 'middle')
            .attr('x', that.centerX)
            .attr('y', that.centerY - height / 2 - (450 + (this.projectService.frameSize * 300)))
            .attr('fill', 'black')
            .style('font-size', '200px');
        g.append('polyline')
            .attr('points', sideSteps.join(''))
            .attr('stroke-width', 20)
            .attr('stroke', 'gray')
            .style('fill', 'none');
        g.append('text')
            .text(this.projectService.paperHeight + '"')
            .attr('text-anchor', 'end')
            .attr('x', that.centerX - width / 2 - (450 + (this.projectService.frameSize * 300)))
            .attr('y', that.centerY + 60)
            .attr('fill', 'black')
            .style('font-size', '200px');
    }


    drawRevealMarkers (that: any) {
        if (!this.projectService.matSize && !this.projectService.frameSize) return;
        const g = that.svg.append('g').attr('id', 'reveal-markers').style('opacity', () => {
            return (that.dimensionsActive) ? 1 : 0;
        });
        const width = this.projectService.paperWidth * that.dpi;
        const height = this.projectService.paperHeight * that.dpi;
        let revealWidth = 0;
        let revealHeight = 0;
        if (this.projectService.matSize) {
            revealWidth = this.projectService.paperWidth - this.projectService.matSizeLeft - this.projectService.matSizeRight;
            revealHeight = this.projectService.paperHeight - this.projectService.matSizeTop - this.projectService.matSizeBottom;
        }
        else {
            revealWidth = this.projectService.paperWidth - 0.5;
            revealHeight = this.projectService.paperHeight - 0.5;
        }
        const revealWidthDPI = revealWidth * that.dpi;
        const revealHeightDPI = revealHeight * that.dpi;
        const halfMatHorz = 300 * 0.5 * (this.projectService.matSizeRight - this.projectService.matSizeLeft);
        const halfMatVert = 300 * 0.5 * (this.projectService.matSizeBottom - this.projectService.matSizeTop);
        const topSteps = [
            that.centerX - halfMatHorz - (revealWidthDPI * 0.5), ',', that.centerY + height / 2 + (150 + (this.projectService.frameSize * 300)), // 1
            that.centerX - halfMatHorz - (revealWidthDPI * 0.5), ',', that.centerY + height / 2 + (300 + (this.projectService.frameSize * 300)), // 2
            that.centerX - halfMatHorz + (revealWidthDPI * 0.5), ',', that.centerY + height / 2 + (300 + (this.projectService.frameSize * 300)), // 3
            that.centerX - halfMatHorz + (revealWidthDPI * 0.5), ',', that.centerY + height / 2 + (150 + (this.projectService.frameSize * 300)), // 4
        ];
        const sideSteps = [
            that.centerX + width / 2 + (150 + (this.projectService.frameSize * 300)), ',', that.centerY - halfMatVert + revealHeightDPI / 2, // 1
            that.centerX + width / 2 + (300 + (this.projectService.frameSize * 300)), ',', that.centerY - halfMatVert + revealHeightDPI / 2, // 2
            that.centerX + width / 2 + (300 + (this.projectService.frameSize * 300)), ',', that.centerY - halfMatVert - revealHeightDPI / 2, // 3
            that.centerX + width / 2 + (150 + (this.projectService.frameSize * 300)), ',', that.centerY - halfMatVert - revealHeightDPI / 2, // 4
        ];
        g.append('polyline')
            .attr('points', topSteps.join(' '))
            .attr('stroke-width', 20)
            .attr('stroke', 'gray')
            .style('fill', 'none');
        g.append('text')
            .text(revealWidth + '"')
            .attr('text-anchor', 'middle')
            .attr('x', that.centerX - halfMatHorz)
            .attr('y', that.centerY + height / 2 + (600 + (this.projectService.frameSize * 300)))
            .attr('fill', 'black')
            .style('font-size', '200px');
        g.append('text')
            .text('Reveal')
            .attr('text-anchor', 'middle')
            .attr('x', that.centerX - halfMatHorz)
            .attr('y', that.centerY + height / 2 + (800 + (this.projectService.frameSize * 300)))
            .attr('fill', 'black')
            .style('font-size', '150px');
        g.append('polyline')
            .attr('points', sideSteps.join(' '))
            .attr('stroke-width', 20)
            .attr('stroke', 'gray')
            .style('fill', 'none');
        g.append('text')
            .text(revealHeight + '"')
            .attr('text-anchor', 'start')
            .attr('x', that.centerX + width / 2 + (450 + (this.projectService.frameSize * 300)))
            .attr('y', that.centerY + 60 - halfMatVert)
            .attr('fill', 'black')
            .style('font-size', '200px');
        g.append('text')
            .text('Reveal')
            .attr('text-anchor', 'start')
            .attr('x', that.centerX + width / 2 + (450 + (this.projectService.frameSize * 300)))
            .attr('y', that.centerY + 260 - halfMatVert)
            .attr('fill', 'black')
            .style('font-size', '150px');
    }


}
