import { Component, OnInit } from '@angular/core';
import { DowngradeModalService } from '../downgrade-modal.service';

@Component({
  selector: 'downgrade-modal',
  templateUrl: './downgrade-modal.component.html',
  styleUrls: ['./downgrade-modal.component.css']
})
export class DowngradeModalComponent implements OnInit {
    active: boolean;
    successMessage: string;
    errorMessage: string;
    constructor(public downgradeModalService: DowngradeModalService) { }

    ngOnInit() {
    }

    close() {
        this.downgradeModalService.active = false;
        this.successMessage = '';
        this.errorMessage = '';
    }

}
