import {Component, HostBinding, HostListener, ElementRef, OnInit} from '@angular/core';
import {Router, Event, NavigationEnd} from '@angular/router';
import {UserService} from './services/user.service';
import {LoginService} from './services/login.service';
import {PayModalService} from './services/pay-modal.service';
import {DowngradeModalService} from './views/profile/downgrade-modal.service';
import {Cookie} from 'ng2-cookies/ng2-cookies';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    mobileMenu:  boolean;
    fullScreen: boolean;
    errorMessage: any;
    export: boolean = true;
    form = new LoginForm('', '');
    remember: any = {};
    spinner: boolean;
    error: any = {};
    paperWidth: string;
    paperHeight: string;
    printMode: boolean;
    // @HostBinding('class') classes = 'fade-in background';
    @HostBinding('class.background') get background () {
        let test = (document.location.href.match('export')) ? document.location.href.match('export')[0] : null;
        return (test !== 'export');
    }
    @HostListener('scroll', ['$event']) onScroll(event) {
        event.target.parentElement.children[0].scrollTop = event.target.scrollTop;
    }


    constructor(public userService: UserService,
                private loginService: LoginService,
                public router: Router,
                private element: ElementRef,
                public payModalService: PayModalService,
                public downgradeModalService: DowngradeModalService) {
        let test = (document.location.href.match('export')) ? document.location.href.match('export')[0] : null;
        this.export = (test !== null);
        router.events.subscribe((event: Event) => {
            if (element
                && element.nativeElement
                && element.nativeElement.children
                && element.nativeElement.children[0]
                && element.nativeElement.children[0].children[1]
                && event instanceof NavigationEnd) {
                element.nativeElement.children[0].children[1].scrollTop = 0;
            }
            if (window.location.hostname === 'instantlayout.com' && event instanceof NavigationEnd) {
                (<any>window).ga('set', 'page', event.urlAfterRedirects);
                (<any>window).ga('send', 'pageview');
            }
        });
        setTimeout(() => this.export = (this.router.url === '/export'), 0);
    }

    ngOnInit() {
        this.removePreventFirstAnimation();
        this.userService.checkLogin();
        if (Cookie.get('remember') && Cookie.get('email')) {
            this.remember.selected = true;
            this.form.email = Cookie.get('email');
        }
    }

    removePreventFirstAnimation() {
        setTimeout(() => {
            document.body.className = document.body.className.replace(/\bprevent-first-animation\b/g, '');
        }, 1000);
    }

    tryLogin() {
        if (this.remember.selected) {
            Cookie.set('remember', 'checked', 60);
            Cookie.set('email', this.form.email, 60);
        } else {
            Cookie.delete('remember');
            Cookie.delete('email');
        }
        this.error = {};
        this.error['sending code'] = true;
        this.checkLogin();
    }

    checkLogin() {
        this.form.email = this.form.email.replace(/ /g, '');
        this.spinner = true;
        this.loginService.checkLogin(this.form.email, this.form.password)
            .subscribe(
                result => this.processCodeResult(result),
                error => this.errorMessage = <any>error
            );
    }

    processCodeResult(result: any) {
        this.error = {};
        if (result && result.passed) {
            this.userService.login(result.token);
            this.userService.loggedIn = true;
            this.form.password = '';
            this.router.navigate(['/studio']);
            this.mobileMenu = false;
        }
        else {
            this.error['failed'] = true;
            setTimeout(() => {
                this.error['failed'] = false;
            }, 3000);
        }
        this.spinner = false;
    }

}

export class LoginForm {
    constructor(public email: string, public password: string) {
    }
}
