import { Injectable } from '@angular/core';
import {ProjectService} from "./project.service";

@Injectable({
  providedIn: 'root'
})
export class LayersService {

    svg: any;
    centerX: number;
    centerY: number;
    exporting: boolean;
    layers: any;

    constructor(private projectService: ProjectService) { }



    draw(that: any, svg: any, centerX: number, centerY: number, exporting: boolean) {
        if (!that.layers || (that.layers && !that.layers.length)) {
            return;
        }
        this.svg = svg;
        this.centerX = centerX;
        this.centerY = centerY;
        this.exporting = exporting;
        this.layers = this.svg
            .append('g')
            .attr('class', 'layers')
            .style('opacity', () => (that.gridActive) ? '0.3' : '1');
        let i = that.layers.length;
        that.layers.slice().reverse().forEach((layer) => {
            if (layer.layerType === 'image') {
                this.drawImage(that, layer, 'layer' + i, i);
            } else {
                this.drawText(that, layer, 'layer' + i, i);
            }
            i--;
        });
    }


r
    drawText(that: any, layer: any, layerId: string, index: number) {

        let fillColor = layer.color;
        let opacity = 1;

        if (fillColor.match(/rgba/)) {
            fillColor = fillColor.split(/\(/)[1];
            fillColor = fillColor.split(/\)/)[0];
            opacity = +fillColor.split(/,/)[3];
            const rgb = fillColor.split(/,/);
            rgb.pop();
            fillColor = 'rgb(' + rgb.join(',') + ')';
        }

        if (!layer.text) {
            return;
        }

        let tspans = layer.text;
        tspans = tspans.replace(/\r\n/, '\n');
        tspans = tspans.split('\n');

        let
            enlarge = (layer.layerEnlarge) + 1,
            size = layer.layerSize * 100 * (that.dpi / 72) * enlarge,
            x = this.centerX + (this.projectService.paperWidth * layer.layerX * that.dpi),
            height = tspans.length * size * layer.lineHeight,
            offsetOne = size - (size * layer.lineHeight),
            halfHeight = (height + offsetOne) * 0.5,
            y = this.centerY - halfHeight + (this.projectService.paperHeight * layer.layerY * that.dpi),
            rotate = layer.rotate || 0;

        if (this.exporting) {
            const paperHeightDpi = this.projectService.paperHeight * that.dpi;                     // 16 * 300 = 4800
            const frameSpace = (this.projectService.frameSize) ? (this.projectService.frameSize * 2) - 0.5 : 0;   // 4.5
            const fullFramedWidth = this.projectService.paperWidth + frameSpace;                   // 24.5
            const fullFramedHeight = this.projectService.paperHeight + frameSpace;                 // 20.5
            const fullFramedWidthQuarters = fullFramedWidth / 0.25;                 // 24.5 / 0.25 = 98
            const fullFramedHeightQuarters = fullFramedHeight / 0.25;               // 20.5 / 0.25 = 82
            const paperQuartersWidth = this.projectService.paperWidth / 0.25;                      // 20 / 0.25 = 80
            const paperQuartersHeight = this.projectService.paperHeight / 0.25;                    // 16 / 0.25 = 64
            let ratio = 0;
            if (this.projectService.paperWidth > this.projectService.paperHeight) {
                ratio = 1050 / fullFramedWidthQuarters;                             // 1050 / 98 = 10.714..
            } else {
                ratio = 1050 / fullFramedHeightQuarters;
            }
            const scaledPaperWidth = paperQuartersWidth * ratio;                    // 80 * 10.714 = 857.12
            const scaledPaperHeight = paperQuartersHeight * ratio;                  // 64 * 10.714 = 685.696
            const paperRatioHeight = scaledPaperHeight / paperHeightDpi;            // 0.142..

            x = this.centerX + (scaledPaperWidth * layer.layerX);
            size *= paperRatioHeight;
            height = tspans.length * size * layer.lineHeight;
            offsetOne = size - (size * layer.lineHeight);
            halfHeight = (height + offsetOne) * 0.5;
            y = this.centerY - halfHeight + (scaledPaperHeight * layer.layerY);
        }

        this.layers = this.layers.append('g')
            .attr('id', (this.exporting) ? 'image-exporting-' + layerId : layerId)
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)');

        const text = this.layers.append('text')
            .attr('x', x)
            .attr('y', y)
            .attr('fill', fillColor)
            .attr('opacity', opacity)
            .attr('dominant-baseline', 'hanging')
            .attr('text-anchor', layer.align)
            .style('font-size', size)
            .style('font-weight', () => layer.bold === 'true' ? 'bold' : 'normal')
            .style('text-decoration', () => layer.underline === 'true' ? 'underline' : '')
            .style('font-style', () => layer.italic === 'true' ? 'italic' : '')
            .attr('transform', 'rotate(' + (rotate * 100) + ', ' + x + ', ' + (y + halfHeight) + ')')
            .style('cursor', 'pointer')
            .on('mousedown', () => {
                that.imageMoved = false;
            })
            .on('mousemove', () => {
                that.imageMoved = true;
            })
            .on('click', () => {
                ((index) => {
                    if (!that.imageMoved) {
                        that.layerIndex = index;
                        that.openLayersModal(index);
                        that.d3.select('#layer' + index).attr(
                            'class',
                            'selector-fader'
                        );
                    }
                    that.imageMoved = false;
                    that.d3.event.stopPropagation();
                })(index);
            });

        let plusY = y;
        tspans.forEach((tspan) => {
            text.append('tspan')
                .text(tspan)
                .attr('x', x)
                .attr('y', plusY)
                .style('font-family', '\'' + layer.font + '\', \'Deja Vu\'');
            plusY += size * layer.lineHeight;
        });

    }



    drawImage(that: any, layer: any, layerId: string, index: number) {

        if (!layer.originalImageWidth || !layer.originalImageHeight) return;

        let
            width = 0,
            height = 0,
            x = 0,
            y = 0;

        if (this.exporting) {
                                                                                    // 20x16 w/ 2.5" frame
            const paperWidthDpi = this.projectService.paperWidth * that.dpi;                       // 20 * 300 = 6000
            const paperHeightDpi = this.projectService.paperHeight * that.dpi;                     // 16 * 300 = 4800
            const frameSpace = (this.projectService.frameSize) ? (this.projectService.frameSize * 2) - 0.5 : 0;   // 4.5
            const fullFramedWidth = this.projectService.paperWidth + frameSpace;                   // 24.5
            const fullFramedHeight = this.projectService.paperHeight + frameSpace;                 // 20.5
            const fullFramedWidthQuarters = fullFramedWidth / 0.25;                 // 24.5 / 0.25 = 98
            const fullFramedHeightQuarters = fullFramedHeight / 0.25;               // 20.5 / 0.25 = 82
            const paperQuartersWidth = this.projectService.paperWidth / 0.25;                      // 20 / 0.25 = 80
            const paperQuartersHeight = this.projectService.paperHeight / 0.25;                    // 16 / 0.25 = 64
            let ratio = 0;
            if (this.projectService.paperWidth > this.projectService.paperHeight) {
                ratio = 1050 / fullFramedWidthQuarters;                             // 1050 / 98 = 10.714..
            } else {
                ratio = 1050 / fullFramedHeightQuarters;
            }
            const scaledPaperWidth = paperQuartersWidth * ratio;                    // 80 * 10.714 = 857.12
            const scaledPaperHeight = paperQuartersHeight * ratio;                  // 64 * 10.714 = 685.696
            const paperRatioWidth = scaledPaperWidth / paperWidthDpi;               // 0.142..
            const paperRatioHeight = scaledPaperHeight / paperHeightDpi;            // 0.142..

            width = layer.originalImageWidth * layer.layerSize * paperRatioWidth;   // 300
            height = layer.originalImageHeight * layer.layerSize * paperRatioHeight;// 236.57..

            x = (this.centerX - (width * 0.5)) + (scaledPaperWidth * layer.layerX);
            y = (this.centerY - (height * 0.5)) + (scaledPaperHeight * layer.layerY);

            // console.log(paperWidthDpi);
            // console.log(paperHeightDpi);
            // console.log(frameSpace);
            // console.log(fullFramedWidth);
            // console.log(fullFramedHeight);
            // console.log(fullFramedWidthQuarters);
            // console.log(fullFramedHeightQuarters);
            // console.log(paperQuartersWidth);
            // console.log(paperQuartersHeight);
            // console.log(frameQuartersOffset);
            // console.log(ratio);
            // console.log(scaledPaperWidth);
            // console.log(scaledPaperHeight);
            // console.log(paperRatioWidth);
            // console.log(paperRatioHeight);
            // console.log(width);
            // console.log(height);
            // console.log(this.centerX);                                              // 525
            // console.log(this.centerY);                                              // 439.285
            // console.log(x);
            // console.log(y);

        } else {
            width = layer.originalImageWidth * layer.layerSize;
            height = layer.originalImageHeight * layer.layerSize;
            x = this.centerX;
            y = this.centerY;
            x = (x - (width * 0.5)) + (((this.projectService.paperWidth * that.dpi)) * (layer.layerX));
            y = (y - (height * 0.5)) + (((this.projectService.paperHeight * that.dpi)) * (layer.layerY));

        }

        const rotate = layer.rotate || 0;

        this.layers = this.layers.append('g')
            .attr('clip-path', (this.exporting) ? 'url(#clip-path-paper-exporting)' : 'url(#clip-path-paper)');

        this.layers.append('image')
            .attr('crossorigin', 'anonymous')
            .attr('href', 'https://images.instantlayout.com/' +
                that.project.user.replace('@', '%40') + '/' +
                'projects/' +
                that.project.id + '/' + layer.id)
            .attr('id', (this.exporting) ? 'image-exporting-' + layerId : layerId)
            .attr('x', x)
            .attr('y', y)
            .attr('width', width)
            .attr('height', height)
            .attr('transform', 'rotate(' + (rotate * 100) + ', ' + (x + (width * 0.5)) + ', ' + (y + (height * 0.5)) + ')')
            .style('cursor', 'pointer')
            .on('mousedown', () => {
                that.imageMoved = false;
            })
            .on('mousemove', () => {
                that.imageMoved = true;
            })
            .on('click', () => {
                (layerIndex => {
                    if (!that.imageMoved) {
                        that.layerIndex = layerIndex;
                        that.openLayersModal(layerIndex);
                        that.d3.select('#layer' + layerIndex).attr(
                            'class',
                            'selector-fader'
                        );
                    }
                    that.imageMoved = false;
                    that.d3.event.stopPropagation();
                })(index);
            });
    }



}
