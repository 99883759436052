import {Component, OnInit, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {D3Service} from 'd3-ng2-service';
import {ProjectsService} from '../studio/services/projects.service';
import {BlocksService} from '../studio/services/blocks.service';
import {LayersService} from '../studio/services/layers.service';
import {StarburstService} from '../studio/services/starburst.service';
import {StudioService} from '../studio/services/studio.service';
import {TitleService} from '../studio/services/title.service';
import {Subject} from '../studio/interfaces/Subject';
import {Layer} from '../studio/interfaces/Layer';
import {AnalyticsService} from '../../analytics.service';
import {ProjectService} from '../studio/services/project.service';

const SUBJECTS: Subject[] = [];
const LAYERS: Layer[] = [];



@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: [
        './export.component.css'
    ]
})
export class ExportComponent implements OnInit {
    private defaultFont1: string;
    private defaultFont2: string;
    private defaultFont1Index: number;
    private defaultFont2Index: number;
    private mainFont1: string;
    private mainFont2: string;
    private mainFont1Index: number;
    private mainFont2Index: number;
    private defaultTitleFont1: string; // todo - lose the "default" (only 1 title)
    private defaultTitleFont2: string;
    private defaultTitleFont1Index: number;
    private defaultTitleFont2Index: number;
    private textLabels: string;
    private mainTextLabels: string;
    private textPosition: string;
    private textAlignment: string;
    private mainTextPosition: string;
    private mainTextAlignment: string;
    private titleText: string;
    private titleTextLine1: string;
    private titleTextLine2: string;
    private titleTextLine1Bold: boolean;
    private titleTextLine2Bold: boolean;
    private titleTextLine1Italic: boolean;
    private titleTextLine2Italic: boolean;
    private titleTextLine1Underline: boolean;
    private titleTextLine2Underline: boolean;
    private titleTextPosition: string;
    private titleTextAlignment: string;
    private rows: number;
    private cols: number;
    private colorPresetBackground: string;
    private colorPresetColor: string;
    private mainColorPresetBackground: string;
    private mainColorPresetColor: string;
    private titleColorPresetBackground: string;
    private titleColorPresetColor: string;
    private defaultBackgroundColor: string;
    private defaultBorderColor: string;
    private defaultLineColor: string;
    private defaultLineColorI: string;
    private defaultLineColorEven: string;
    private defaultLineColorOdd: string;
    private mainBackgroundColor: string;
    private mainBorderColor: string;
    private tilesBackgroundColor: string;
    private tilesBorderColor: string;
    private tilesBackgroundColorI: string;
    private tilesBorderColorI: string;
    private labelsBackgroundColorI: string;
    private labelsTextColorI: string;
    private labelsBackgroundColorEven: string;
    private labelsTextColorEven: string;
    private labelsBackgroundColorOdd: string;
    private labelsTextColorOdd: string;
    private tilesBackgroundColorEven: string;
    private tilesBorderColorEven: string;
    private tilesBackgroundColorOdd: string;
    private tilesBorderColorOdd: string;
    private subjects = SUBJECTS;
    private layers = LAYERS;
    private tilesOptions: any[] = [
        { index: 'All Tiles (reset)' },
        { index: 'All Tiles & Main' },
        { index: 'Unchanged Tiles' },
        { index: 'Unchanged Tiles & Main' },
        { index: 'Even Tiles' },
        { index: 'Odd Tiles' }
    ];
    private dpi: number;
    private subjectIndex: any;
    private layerIndex: any;
    private subjectTextIndex: number;
    private project: any;
    private type: string;
    private host: any;
    private paperWidth: number;
    private paperHeight: number;
    private titleWidth: number;
    private titleHeight: number;
    private width: number;
    private height: number;
    private svgParent: any;
    private svg: any;
    private poster: any;
    private d3: any;
    private parentNativeElement: any;
    private centerX: number;
    private centerY: number;
    private chartCenterX: number;
    private chartCenterY: number;
    private lastX: number;
    private lastY: number;
    private rememberX = 0;
    private rememberY = 0;
    private chartMargin: number;
    private anchorPosition: string;
    private anchorSize: number;
    private anchorScale: number;
    private subjectScale1: number;
    private subjectScale2: number;
    private subjectDistribution: number;
    private frameSize: number;
    private step = 200;
    private maxSubjects = {
        Starburst: 144,
        Blocks: 144
    };
    private fonts: { id: string, size: number }[] = [];
    private columns: number;
    private anchorScaleLabel: string;
    private subjectScale1Label: string;
    private subjectScale2Label: string;
    private subjectDistributionLabel: string;
    private connectorLinesThicknessLabel: string;
    private radius1Label: string;
    private radius2Label: string;
    private anchorBorderThicknessLabel: string;
    private borderThicknessLabel: string;
    private labelHeightLabel: string;
    private mainLabelHeightLabel: string;
    private titleSizeLabel: string;
    private fontSizesLabel: string;
    private mainFontSizesLabel: string;
    private mainFontScaleLabel: string;
    private titleFontSizesLabel: string;
    private titleFontScaleLabel: string;
    private labelFontScaleLabel: string;
    private mainLabelFontScaleLabel: string;
    private chartMarginLabel: string;
    private anchorSizeLabel: string;
    private connectorLinesThickness: number;
    private chartRotation: number;
    private radius1: number;
    private radius2: number;
    private anchorBorderThickness: number;
    private borderThickness: number;
    private labelHeight: number;
    private mainLabelHeight: number;
    private titleSize: number;
    private fontSizes: number;
    private mainFontSizes: number;
    private mainFontScale: number;
    private titleFontSizes: number;
    private titleFontScale: number;
    private labelFontScale: number;
    private mainLabelFontScale: number;
    private orientation: string;
    private anchorShape: string;
    private starburstAlignment: string;
    private subjectShape: string;
    public projects: any;
    private frameSpace: number;
    private revealWidth: number;
    private revealHeight: number;
    private marginWidth: number;
    private marginHeight: number;
    private chartWidth: number;
    private chartHeight: number;
    private marginsBehavior: string;
    private titleX: number;
    private titleY: number;
    private titleRectX: number;
    private titleRectY: number;
    private titleRectW: number;
    private titleRectH: number;

    constructor(private element: ElementRef,
                private router: Router,
                d3Service: D3Service,
                private projectsService: ProjectsService,
                private blocksService: BlocksService,
                private layersService: LayersService,
                private projectService: ProjectService,
                private starburstService: StarburstService,
                private studioService: StudioService,
                private titleService: TitleService,
                private analyticsService: AnalyticsService) {
        this.d3 = d3Service.getD3();
    }



    ngOnInit() {
        this.dpi = 300;
        this.anchorScale = 0.0;
        this.subjectScale1 = 0.0;
        this.subjectScale2 = 0.0;
        this.subjectDistribution = 0.0;
        this.connectorLinesThickness = 0;
        this.chartRotation = 0;
        this.radius1 = 0.0;
        this.radius2 = 0.0;
        this.anchorBorderThickness = 0.0;
        this.borderThickness = 0.0;
        this.labelHeight = 1.5;
        this.mainLabelHeight = 1.5;
        this.titleSize = 1.5;
        this.fontSizes = 0.5;
        this.mainFontSizes = 0.5;
        this.mainFontScale = 1.0;
        this.titleFontSizes = 0.5;
        this.titleFontScale = 1.0;
        this.labelFontScale = 1.0;
        this.mainLabelFontScale = 1.0;
        this.chartMargin = 0.0;
        this.anchorSize = 0.6650;
        this.anchorPosition = 'top';
        this.anchorScaleLabel = '0%';
        this.subjectScale1Label = '0%';
        this.subjectScale2Label = '0%';
        this.subjectDistributionLabel = '0%';
        this.connectorLinesThicknessLabel = '0%';
        this.radius1Label = '100%';
        this.radius2Label = '100%';
        this.anchorBorderThicknessLabel = '0%';
        this.borderThicknessLabel = '0%';
        this.labelHeightLabel = '15%';
        this.mainLabelHeightLabel = '15%';
        this.titleSizeLabel = '15%';
        this.fontSizesLabel = '50%/50%';
        this.mainFontSizesLabel = '50%/50%';
        this.mainFontScaleLabel = '100%';
        this.titleFontSizesLabel = '50%/50%';
        this.titleFontScaleLabel = '100%';
        this.labelFontScaleLabel = '100%';
        this.mainLabelFontScaleLabel = '100%';
        this.chartMarginLabel = '0%';
        this.anchorSizeLabel = '66.5/33.5';
        this.parentNativeElement = this.element.nativeElement;
        this.type = 'Blocks';
        this.studioService.getProject(this);
        this.analyticsService.record('export');
    }



    setProject() {
        const project = this.project;
        this.type = project.type || this.type;
        this.paperWidth = project.paperWidth || 20;
        this.paperHeight = project.paperHeight || 16;
        this.frameSize = project.frameSize || 0;
        this.projectService.id = project.id;
        this.projectService.paperWidth = project.paperWidth || 20;
        this.projectService.paperHeight = project.paperHeight || 16;
        this.projectService.frameSize = project.frameSize || 0;
        this.projectService.frameShape = project.frameShape || 'full-bevel';
        this.projectService.frameColor = project.frameColor || 'rgb(10,10,10)';
        this.projectService.matSize = project.matSize || 0;
        this.projectService.matSizeTop = project.matSizeTop || project.matSize;
        this.projectService.matSizeLeft = project.matSizeLeft || project.matSize;
        this.projectService.matSizeRight = project.matSizeRight || project.matSize;
        this.projectService.matSizeBottom = project.matSizeBottom || project.matSizes;
        this.projectService.matColor = project.matColor || 'rgb(242,244,244)';
        this.projectService.doubleMatSize = project.doubleMatSize || 0;
        this.projectService.doubleMatColor = project.doubleMatColor || 'rgb(242,244,244)';
        this.projectService.type = project.type || this.type;
        this.projectService.fold = project.fold || '';
        this.orientation = project.orientation || '';
        this.columns = project.columns || null;
        if (project.anchorShape) {
            this.anchorShape = project.anchorShape;
        } else if (project.type === 'Starburst') {
            this.anchorShape = '';
        } else {
            this.anchorShape = 'rect';
        }
        this.starburstAlignment = project.starburstAlignment || 'Perpendicular';
        this.subjectShape = project.subjectShape || 'rect';
        this.chartMargin = project.chartMargin || 0;
        this.anchorSize = project.anchorSize || 0.665;
        this.anchorPosition = project.anchorPosition || '';
        this.anchorScale = (project.anchorScale !== undefined) ? project.anchorScale : 0.335;
        this.subjectScale1 = project.subjectScale1 || 0.9;
        this.subjectScale2 = project.subjectScale2 || 0.9;
        this.subjectDistribution = project.subjectDistribution || 0;
        this.connectorLinesThickness = project.connectorLinesThickness || 0;
        this.chartRotation = project.chartRotation || 0;
        this.radius1 = project.radius1 || 1;
        this.radius2 = project.radius2 || 1;
        this.anchorBorderThickness = project.anchorBorderThickness || 0;
        this.borderThickness = project.borderThickness || 0;
        this.labelHeight = project.labelHeight || 0;
        this.mainLabelHeight = project.mainLabelHeight || 0;
        this.titleSize = project.titleSize || 0;
        this.fontSizes = project.fontSizes || 0.5;
        this.mainFontSizes = project.mainFontSizes || 0.5;
        this.mainFontScale = project.mainFontScale || 1;
        this.titleFontSizes = project.titleFontSizes || 0.5;
        this.titleFontScale = project.titleFontScale || 1;
        this.labelFontScale = project.labelFontScale || 1;
        this.mainLabelFontScale = project.mainLabelFontScale || 1;
        this.textLabels = project.textLabels || '';
        this.mainTextLabels = project.mainTextLabels || '';
        this.defaultFont1 = project.defaultFont1 || 'Open Sans';
        this.defaultFont2 = project.defaultFont2 || 'Open Sans';
        this.defaultFont1Index = this.studioService.getFontIndex(this, 'defaultFont1');
        this.defaultFont2Index = this.studioService.getFontIndex(this, 'defaultFont2');
        this.mainFont1 = project.mainFont1 || 'Open Sans';
        this.mainFont2 = project.mainFont2 || 'Open Sans';
        this.mainFont1Index = this.studioService.getFontIndex(this, 'mainFont1');
        this.mainFont2Index = this.studioService.getFontIndex(this, 'mainFont2');
        this.defaultTitleFont1 = project.defaultTitleFont1 || 'Open Sans';
        this.defaultTitleFont2 = project.defaultTitleFont2 || 'Open Sans';
        this.defaultTitleFont1Index = this.studioService.getFontIndex(this, 'defaultTitleFont1');
        this.defaultTitleFont2Index = this.studioService.getFontIndex(this, 'defaultTitleFont2');
        this.textPosition = project.textPosition || 'center';
        this.textAlignment = project.textAlignment || 'center';
        this.marginsBehavior = project.marginsBehavior || 'edge';
        this.mainTextPosition = project.mainTextPosition || 'center';
        this.mainTextAlignment = project.mainTextAlignment || 'center';
        this.titleText = project.titleText || '';
        this.titleTextLine1 = project.titleTextLine1 || '';
        this.titleTextLine2 = project.titleTextLine2 || '';
        this.titleTextLine1Bold = project.titleTextLine1Bold || false;
        this.titleTextLine2Bold = project.titleTextLine2Bold || false;
        this.titleTextLine1Italic = project.titleTextLine1Italic || false;
        this.titleTextLine2Italic = project.titleTextLine2Italic || false;
        this.titleTextLine1Underline = project.titleTextLine1Underline || false;
        this.titleTextLine2Underline = project.titleTextLine2Underline || false;
        this.rows = project.rows || 0;
        this.cols = project.cols || 0;
        this.titleTextPosition = project.titleTextPosition || 'center';
        this.titleTextAlignment = project.titleTextAlignment || 'center';
        this.colorPresetBackground = project.colorPresetBackground || 'rgba(0,0,0,0.66)';
        this.colorPresetColor = project.colorPresetColor || 'white';
        this.labelsBackgroundColorEven = project.labelsBackgroundColorEven || this.colorPresetBackground;
        this.labelsBackgroundColorOdd = project.labelsBackgroundColorOdd || this.colorPresetBackground;
        this.labelsTextColorEven = project.labelsTextColorEven || this.colorPresetColor;
        this.labelsTextColorOdd = project.labelsTextColorOdd || this.colorPresetColor;
        this.mainColorPresetBackground = project.mainColorPresetBackground || 'rgba(0,0,0,0.66)';
        this.mainColorPresetColor = project.mainColorPresetColor || 'white';
        this.titleColorPresetBackground = project.titleColorPresetBackground || 'rgba(0,0,0,0.66)';
        this.titleColorPresetColor = project.titleColorPresetColor || 'white';
        this.defaultBackgroundColor = project.defaultBackgroundColor || 'lightgray';
        this.defaultBorderColor = project.defaultBorderColor || 'black';
        this.defaultLineColor = project.defaultLineColor || 'black';
        this.tilesBackgroundColorEven = project.tilesBackgroundColorEven || 'lightgray';
        this.tilesBorderColorEven = project.tilesBorderColorEven || 'black';
        this.defaultLineColorEven = project.defaultLineColorEven || 'black';
        this.tilesBackgroundColorOdd = project.tilesBackgroundColorOdd || 'lightgray';
        this.tilesBorderColorOdd = project.tilesBorderColorOdd || 'black';
        this.defaultLineColorOdd = project.defaultLineColorOdd || 'black';
        this.mainBackgroundColor = project.mainBackgroundColor || 'lightgray';
        this.mainBorderColor = project.mainBorderColor || 'black';
        this.tilesBackgroundColor = project.tilesBackgroundColor || 'lightgray';
        this.tilesBorderColor = project.tilesBorderColor || 'black';
        this.subjects = JSON.parse(JSON.stringify(project.subjects));
        this.layers = project.layers ? JSON.parse(JSON.stringify(project.layers)) : [];

        this.draw();
    }



    defs() {
        const defs = this.svg.append('defs');
        const filter = defs.append('filter')
            .attr('id', 'small-shadow')
            .attr('height', '130%');
        filter.append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', this.dpi / 50)
            .attr('result', 'blur');
        filter.append('feOffset')
            .attr('in', 'blur')
            .attr('dx', 0)
            .attr('dy', 0)
            .attr('result', 'offsetBlur');
        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode')
            .attr('in', 'offsetBlur');
        feMerge.append('feMergeNode')
            .attr('in', 'SourceGraphic');
    }



    drawPaper() {
        const g = this.svg.append('g').attr('id', 'paper');
        const width = this.paperWidth * this.dpi;
        const height = this.paperHeight * this.dpi;
        g.append('rect')
            .attr('width', width)
            .attr('height', height)
            .style('fill', 'white');
        this.svg.append('defs')
            .append('clipPath')
            .attr('id', 'clip-path-paper')
            .append('rect')
            .attr('width', width)
            .attr('height', height);
    }



    draw() {

        if (!this.project) {
            return;
        }

        setTimeout(() => {

            this.host = this.d3.select(this.parentNativeElement);
            this.host.select('#svg').remove();
            this.width = this.paperWidth * this.dpi;
            this.height = this.paperHeight * this.dpi;
            this.centerX = this.width / 2;
            this.centerY = this.height / 2;

            this.svgParent = this.host.append('svg')
                .attr('id', 'svg')
                .attr('width', this.width)
                .attr('height', this.height)
                .attr('viewBox', '0 0 ' + this.width + ' ' + this.height);

            this.svg = this.svgParent.append('g').attr('id', 'poster');
            this.poster = this.svg;

            this.defs();
            this.drawPaper();
            this.studioService.setChartSpace(this, this.dpi, this.centerX, this.centerY);
            this[this.type.charAt(0).toLowerCase() + this.type.slice(1) + 'Service'].draw(this, this.svg, this.dpi, false);
            this.titleService.draw(this, this.poster, this.centerX, this.centerY, false);
            this.layersService.draw(this, this.poster, this.centerX, this.centerY, false);

        }, 0);
    }


}
