import {Component, OnInit, HostBinding} from '@angular/core';
import {OrdersService} from './orders.service';
import {UserService} from '../../services/user.service';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
    public orders: any;
    @HostBinding('class') classes = 'fade-in';

    constructor(private ordersService: OrdersService,
                private userService: UserService,
                private analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        this.userService.checkLogin();
        this.ordersService.getOrders().subscribe(
            data => this.orders = data,
            err => console.log(err)
        );
        this.analyticsService.record('orders');
    }

    openImage(user, id, project) {
        window.open('https://images.instantlayout.com/' + user + '/exports/' + id + '/' + project + '.png', '_system', 'location=yes');
        return false;
    }

}
