import { Component, OnInit } from '@angular/core';
import {AnalyticsService} from '../../analytics.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor(private analyticsService: AnalyticsService) { }

    ngOnInit() {
        this.analyticsService.record('features');
    }

}
