import { Component, HostListener, ElementRef, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit, OnChanges {

    label: string;
    mouseIsDown: boolean;
    valueRange: number;
    sliderStart: number;
    styleLeft: number;
    xRange: number = 200;
    subjects: any;
    sliderElementLeft: number;

    @Input() id: string;
    @Input() value: number;
    @Input() title: string;
    @Input() min: number;
    @Input() max: number;
    @Input() append: string;
    @Input() prepend: string;
    @Input() decimals: number;
    @Input() split: boolean;



    @Output() sliderChange = new EventEmitter();



    @HostListener('document:mouseup', ['$event']) onMouseUp(event: any) {
        this.sliderUp();
    }
    @HostListener('touchstart', ['$event']) onTouchDown(event: any) {
        this.sliderElementLeft = this.elementRef.nativeElement.children[1].getBoundingClientRect().left;
        this.sliderElementLeft += 10;
        this.mouseIsDown = true;
    }
    @HostListener('touchmove', ['$event']) onTouchMove(event: any) {
        this.sliderMove(event.touches[0].pageX - this.sliderElementLeft);
    }
    @HostListener('document:touchend', ['$event']) onTouchEnd(event: any) {
        this.sliderUp();
    }



    constructor(private elementRef: ElementRef) {}



    ngOnChanges(changes: SimpleChanges) {
        this.ngOnInit();
    }



    ngOnInit() {
        this.min = this.min || 0;
        this.max = this.max || 100;
        this.valueRange = this.max - this.min;
        this.append = this.append || '';
        this.prepend = this.prepend || '';
        this.decimals = this.decimals || 0;
        if (this.id === 'offsetX' && this.value === undefined) {
            this.value = 0.5;
        }
        else if (this.id === 'offsetY' && this.value === undefined) {
            this.value = 0.5;
        }
        else {
            this.value = this.value || 0;
        }
        setTimeout(() => {
            this.initPercentToX(this.value);
        }, 0);
    }



    initPercentToX(percent: number) {
        // console.log('AA:' + percent);
        percent -= this.min * 0.01;
        // console.log('BB:' + percent);
        percent /= this.valueRange * 0.01;
        // console.log('CC:' + percent);
        // console.log('DD:' + (percent * this.xRange));
        this.setSlider(percent * this.xRange);
    }



    sliderDown(x: number) {
        this.mouseIsDown = true;
        this.elementRef.nativeElement.style.cursor = 'ew-resize';
        this.setSlider(x);
    }



    sliderMove(x: number) {
        if (!this.mouseIsDown) return;
        this.setSlider(x);
    }



    sliderUp() {
        this.mouseIsDown = false;
        this.elementRef.nativeElement.style.cursor = 'default';
    }



    sliderLimits(x: number) {
        if (x < 0) {
            x = 0;
        }
        else if (x > 200) {
            x = 200;
        }
        return x;
    }



    setSlider(x: number) {

        this.styleLeft = this.sliderLimits(x);
        const sliderPercent = this.styleLeft * 0.005; // ei. 100% / 200px * 0.01 * xPosition
        const formattedNumber: number = (sliderPercent * this.valueRange) + this.min;

        if (this.split) {
            const right = formattedNumber.toFixed(0);
            const left = 100 - +right;
            this.label = left + ' / ' + right;
        }
        else {
            const formattedString: string = formattedNumber.toFixed(this.decimals);
            this.label = this.prepend + formattedString + this.append;
        }

        // console.log('P:', sliderPercent); // 0
        // console.log('+ N:', (this.min * 0.01)); // 0.01
        // console.log('R:', this.valueRange); // 9
        // console.log('+ U:', this.valueRange * 0.01); // 0.09
        let value;
        value = (sliderPercent + (this.min * 0.01)) * (this.valueRange * 0.01);
        // console.log('V:', value); // 0.459
        // console.log(' ');

        this.sliderChange.emit({
            id: this.id,
            value: value
        });
    }



    stepSliderLeft() {
        this.styleLeft--; // or -= 0.2
        this.setSlider(this.styleLeft);
    }



    stepSliderRight() {
        this.styleLeft++; // or += 0.2
        this.setSlider(this.styleLeft);
    }



}
