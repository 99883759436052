import { Component, OnInit } from '@angular/core';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit {

    public pricingData: any;
    public width = 16;
    public height = 20;
    public widthFraction = 0;
    public heightFraction = 0;
    public inches = [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
        42, 43, 44, 45, 46, 47, 48
    ];
    public fractions = [
        { value: 0, label: '-' },
        { value: 0.125, label: '1/8' },
        { value: 0.25, label: '1/4' },
        { value: 0.375, label: '3/8' },
        { value: 0.5, label: '1/2' },
        { value: 0.625, label: '5/8' },
        { value: 0.75, label: '3/4' },
        { value: 0.875, label: '7/8' }
    ];

    constructor(private analyticsService: AnalyticsService) {}

    ngOnInit() {

        this.pricingData = {
            headers: ['1-24', '25-99', '100+'],
            materials: [
                {
                    material: 'Matte',
                    rates: [0.0344, 0.0258, 0.0172],
                    discounts: [0.15, 0.15, 0.15]
                },
                {
                    material: 'Gloss',
                    rates: [0.0461, 0.0346, 0.0231],
                    discounts: [0.15, 0.15, 0.15]
                },
                {
                    material: 'Luster',
                    rates: [0.0417, 0.0313, 0.0208],
                    discounts: [0.15, 0.15, 0.15]
                },
                {
                    material: 'Laminated',
                    rates: [0.0644, 0.0483, 0.0322],
                    discounts: [0.15, 0.15, 0.15]
                },
                {
                    material: 'Canvas',
                    rates: [0.0528, 0.0396, 0.0264],
                    discounts: [0.15, 0.15, 0.15]
                },
                {
                    material: 'Vinyl Banner',
                    rates: [0.0567, 0.0425, 0.0283],
                    discounts: [0.15, 0.15, 0.15]
                }
            ],
            quantities: [1, 50, 250]
        };

        this.analyticsService.record('prices');
    }

}
