import {Component, Input, OnChanges} from '@angular/core';
import {PayModalService} from '../../../services/pay-modal.service';
import {DowngradeModalService} from '../downgrade-modal.service';


@Component({
    selector: 'profile-plans',
    templateUrl: './profile-plans.component.html',
    styleUrls: ['./profile-plans.component.css']
})
export class ProfilePlansComponent implements OnChanges {
    @Input() plan;
    @Input() planKeys;
    @Input() plans;
    @Input() size;
    constructor(public payModalService: PayModalService,
                private downgradeModalService: DowngradeModalService) {
    }

    ngOnChanges () {
        // this.setPlanInfo();
    }

}
