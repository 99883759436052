import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {EnvService} from "../../../env.service";

@Injectable({
  providedIn: 'root'
})
export class ShippingService {

    constructor(private http: HttpClient,
                private envService: EnvService) { }

    getShippingOptions(zip: string): Observable<any> {
        return this.http
            .get(this.envService.url + 'shipping/' + zip)
            .map(this.extractData)
            .catch(this.handleError);
    }

    private extractData(res: HttpResponse<any>) {
        return res;
    }

    private handleError(error: any) {
        // let errMsg = (error.message) ? error.message :
        //     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        return Observable.throw(error);
    }

}
