import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {EnvService} from "../env.service";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

    constructor(private http: HttpClient,
                private envService: EnvService) {
    }

    updateMembership(email: string, membership: string): Observable<any> {
        return this.http
            .post(this.envService.url + 'email-list', { id: email, membership: membership })
            .map(this.extractData)
            .catch(this.handleError);
    }

    private extractData(res: HttpResponse<any>) {
        let body = res;
        return body || {};
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

}
