export class Project {
    aliasId: string;
    doubleMatColor: string;
    doubleMatSize: number;
    fold: string;
    frameColor: string;
    frameShape: string;
    frameSize: number;
    id: string;
    matColor: string;
    matSize: number;
    matSizeTop: number;
    matSizeLeft: number;
    matSizeRight: number;
    matSizeBottom: number;
    paperHeight: number;
    paperWidth: number;
    type: string;
}
