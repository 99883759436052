import { Component } from '@angular/core';
import {EmailService} from "../../services/email.service";

@Component({
    selector: 'join-list',
    templateUrl: './join-list.component.html',
    styleUrls: ['./join-list.component.css']
})
export class JoinListComponent {

    email: string;
    message: string;

    constructor(private emailService: EmailService) {}

    updateMembership() {
        this.emailService.updateMembership(this.email, 'subscribe')
            .subscribe(
                result => this.processMembershipResult(result),
                error => this.processMembershipResult(error)
            );
    }



    processMembershipResult(result) {
        this.message = result.message;
    }



}
