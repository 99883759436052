import {Injectable} from '@angular/core';
import {SubjectsService} from './subjects.service';

@Injectable()
export class StarburstService {

    svg: any;
    connectorLines: any;
    dpi: number;
    exporting: boolean;

    constructor(private subjectsService: SubjectsService) {}


    draw(that: any, svg: any, dpi: number, exporting: boolean) {
        // Anchor image is always the first subject regardless of it being active or not.

        this.connectorLines = svg.append('g').attr('id', 'connector-lines');
        this.svg = svg.append('g').attr('id', 'images');
        this.dpi = dpi;
        this.exporting = exporting;

        const
            allLength = that.subjects.length,
            outerSubjectsLength = that.subjects.length - 1;

        let
            imageWidth,
            imageHeight,
            x2,
            y2,
            angleOne = (that.starburstAlignment === 'Perpendicular') ? 0 : ((1 / (outerSubjectsLength / 2)) * Math.PI) / 2,
            pathRadius,
            width;

        angleOne -= Math.PI / 2;
        angleOne += (Math.PI / 2) * 4; // Was that.chartRotation, I removed the control though

        for (let i = 1; i < allLength; i++) {

            if (i % 2) {
                imageWidth = (that.chartWidth / 4) * that.subjectScale2;
                imageHeight = (that.chartHeight / 4) * that.subjectScale2;
                if (that.chartHeight < that.chartWidth) {
                    that.pathRadius = that.chartHeight / 2;
                    imageWidth = imageHeight;
                }
                else {
                    that.pathRadius = that.chartWidth / 2;
                }
                pathRadius = that.pathRadius * that.radius2;
            }
            else {
                imageWidth = (that.chartWidth / 4) * that.subjectScale1;
                imageHeight = (that.chartHeight / 4) * that.subjectScale1;
                if (that.chartHeight < that.chartWidth) {
                    that.pathRadius = that.chartHeight / 2;
                    imageWidth = imageHeight;
                }
                else {
                    that.pathRadius = that.chartWidth / 2;
                }
                pathRadius = that.pathRadius * that.radius1;
            }

            width = (pathRadius * 2);

            this.subjectsService.setTileImageWidth(imageWidth);
            this.subjectsService.setTileImageHeight(imageWidth);

            const angle = (i / (outerSubjectsLength / 2)) * Math.PI;
            const addAngle = angle + angleOne;
            const x = (pathRadius * Math.cos(addAngle)) + pathRadius;
            const y = (pathRadius * Math.sin(addAngle)) + pathRadius;
            x2 = that.chartCenterX + x - pathRadius;
            y2 = that.chartCenterY + y - pathRadius;
            that.subjects[i].scale = (that.subjects[i].scale) ? that.subjects[i].scale : 3;
            this.connectorLine(that, that.chartCenterX, that.chartCenterY, x2, y2, i);

            that.subjects[i].x = x2 - imageWidth / 2;
            that.subjects[i].y = y2 - imageWidth / 2;

            if (that.subjectShape === 'rect') {
                this.subjectsService.drawRect(that, this.svg, dpi, i, imageWidth, imageWidth, this.exporting);
            }
            else if (that.subjectShape === 'ellipse') {
                this.subjectsService.drawEllipse(that, this.svg, dpi, i, imageWidth, imageWidth, this.exporting);
            }
            else {
                this.subjectsService.drawRect(that, this.svg, dpi, i, imageWidth, imageWidth, this.exporting);
            }

        }
        this.drawAnchor(that);
    }


    drawAnchor(that) {
        if (!that.anchorShape) {
            return;
        }
        let useSize = (that.chartWidth > that.chartHeight) ? that.chartHeight : that.chartWidth;
        useSize *= that.anchorScale;
        useSize -= (useSize * that.anchorBorderThickness);
        that.subjects[0].x = that.chartCenterX - useSize / 2;
        that.subjects[0].y = that.chartCenterY - useSize / 2;

        if (that.anchorShape === 'rect') {
            this.subjectsService.drawRect(that, this.svg, this.dpi, 0, useSize, useSize, this.exporting);
        }
        else if (that.anchorShape === 'ellipse') {
            this.subjectsService.drawEllipse(that, this.svg, this.dpi, 0, useSize, useSize, this.exporting);
        }
        else {
            this.subjectsService.drawRect(that, this.svg, this.dpi, 0, useSize, useSize, this.exporting);
        }

        this.subjectsService.setMainImageWidth(useSize);
        this.subjectsService.setMainImageHeight(useSize);
    }


    connectorLine(that: any, x1: number, y1: number, x2: number, y2: number, i: number) {
        this.connectorLines.append('line')
            .attr('clip-path', (!this.exporting) ? 'url(#clip-path-paper)' : '')
            .attr('class', 'line')
            .attr('x1', x1)
            .attr('y1', y1)
            .attr('x2', x2)
            .attr('y2', y2)
            .attr('stroke-width', that.connectorLinesThickness * (this.dpi / 3))
            .attr('stroke', () => (that.subjects[i] && that.subjects[i].lineColor) ? that.subjects[i].lineColor : that.defaultLineColor);
    }


}
