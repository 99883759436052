import {Component} from '@angular/core';
import {StudioComponent} from '../../studio.component';
import {StudioService} from "../../services/studio.service";

@Component({
    selector: 'templates-modal',
    templateUrl: './templates-modal.component.html',
    styleUrls: ['./templates-modal.component.scss']
})
export class TemplatesModalComponent {

    layoutId: number;
    groupIndex: number;
    optionsIndex: number;
    step: number = 1;

    constructor(private studioComponent: StudioComponent,
                private studioService: StudioService) {}

    openSizes(layoutId: number) {
        this.layoutId = layoutId;
        this.step = 2;
    }

    setOptions(indexes: { groupIndex: number, optionsIndex: number }) {
        this.groupIndex = indexes.groupIndex;
        this.optionsIndex = indexes.optionsIndex;
        this.newProject();
    }

    newProject() {
        // console.log('layoutId: ' + this.layoutId);
        // console.log('groupIndex: ' + this.groupIndex);
        // console.log('optionsIndex: ' + this.optionsIndex);

        let elasticTemplate = {};
        this.studioService.elasticTemplates.forEach((item) => {
            if (item.id === this.layoutId) {
                elasticTemplate = item;
            }
        });

        const optionsTemplate = this.studioService.optionsTemplates[this.groupIndex].options[this.optionsIndex];

        const newProject = Object.assign({}, elasticTemplate, optionsTemplate);

        this.studioComponent.newProject(newProject);
        // close modals somewhere too
    }

}
