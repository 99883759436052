import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {UserService} from '../../services/user.service';
import {Subject} from "rxjs";
import {EnvService} from "../../env.service";

@Injectable()
export class ProfileService {
    subject1 = new Subject<number>();
    constructor(private http: HttpClient,
                private userService: UserService,
                private envService: EnvService) {}

    getProfile () {
        return this.http
            .get(this.envService.url + 'profile/' + this.userService.token)
            .map(this.extractData)
            .catch(this.handleError);
    }

    private extractData (res: HttpResponse<any>) {
        const body = res;
        return body || {};
    }

    private handleError (error: any) {
        console.error(error);
        return Observable.throw(error);
    }

}
