import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'features-overview',
  templateUrl: './features-overview.component.html',
  styleUrls: ['./features-overview.component.scss']
})
export class FeaturesOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
