import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss']
})
export class SuppliesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
