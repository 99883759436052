import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'templates-elastic',
  templateUrl: './templates-elastic.component.html',
  styleUrls: ['./templates-elastic.component.scss']
})
export class TemplatesElasticComponent {

    @Output() openSizes = new EventEmitter<string>();

    nextStep(layoutId) {
        this.openSizes.next(layoutId);
    }
}
