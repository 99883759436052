import {Component, OnInit, HostListener, HostBinding, ElementRef, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {D3Service} from 'd3-ng2-service';
import {UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions} from 'ngx-uploader';
import {ProjectsService} from './services/projects.service';
import {UserService} from '../../services/user.service';
import {BlocksService} from './services/blocks.service';
import {LayersService} from './services/layers.service';
import {ProjectService} from './services/project.service';
import {ShippingService} from './services/shipping.service';
import {StarburstService} from './services/starburst.service';
import {StudioService} from './services/studio.service';
import {SubjectsService} from './services/subjects.service';
import {TitleService} from './services/title.service';
import {FrameService} from './services/frame.service';
import {MatService} from './services/mat.service';
import {GridService} from './services/grid.service';
import {ExportService} from './services/export.service';
import {PayModalService} from '../../services/pay-modal.service';
import {Subject} from './interfaces/Subject';
import {Layer} from './interfaces/Layer';
import {EnvService} from '../../env.service';
import {AnalyticsService} from '../../analytics.service';



export interface UploaderOptions {
    concurrency: number; // number of files uploaded at the same time
    allowedContentTypes?: string[]; // content types allowed (default *)
    maxUploads?: number; // max number of files the user can upload
}

export interface UploadProgress {
    // status: UploadStatus; // current status of upload for specific file (Queue | Uploading | Done | Canceled)
    data?: {
        percentage: number; // percentage of upload already completed
        speed: number; // current upload speed per second in bytes
        speedHuman: string; // current upload speed per second in human readable form,
        eta: number; // estimated time remaining in seconds
        etaHuman: string; // estimated time remaining in human readable format
    };
}

export interface UploadFile {
    id: string; // unique id of uploaded file instance
    fileIndex: number; // fileIndex in internal ngx-uploader array of files
    lastModifiedDate: string; // last modify date of the file (Date object)
    name: string; // original name of the file
    size: number; // size of the file in bytes
    type: string; // mime type of the file
    form: FormData; // FormData object (you can append extra data per file, to this object)
    progress: UploadProgress;
    response?: any; // response when upload is done (parsed JSON or string)
    responseStatus?: number; // response status code when upload is done
}

export interface UploadOutput {
    type: 'addedToQueue' | 'allAddedToQueue' | 'uploading' | 'done' | 'removed' | 'start' | 'cancelled' | 'dragOver' | 'dragOut' | 'drop';
    file?: UploadFile;
    nativeFile?: File; // native javascript File object, can be used to process uploaded files in other libraries
}

export interface UploadInput {
    type: 'uploadAll' | 'uploadFile' | 'cancel' | 'cancelAll' | 'remove' | 'removeAll';
    url?: string; // URL to upload file to
    method?: string; // method (POST | PUT)
    id?: string; // unique id of uploaded file
    fieldName?: string; // field name (default 'file')
    fileIndex?: number; // fileIndex in internal ngx-uploader array of files
    file?: UploadFile; // uploading file
    data?: { [key: string]: string | Blob }; // custom data sent with the file
    headers?: { [key: string]: string }; // custom headers
    concurrency?: number; // concurrency of how many files can be uploaded in parallel (default is 0 which means unlimited)
    withCredentials?: boolean; // apply withCredentials option
}

const SUBJECTS: Subject[] = [];
const LAYERS: Layer[] = [];

@Component({
    selector: 'app-studio',
    templateUrl: './studio.component.html',
    styleUrls: [
        './menus/options-menu.css',
        './menus/open-menu.css',
        './menus/view-menu.css',
        './panels/anchor-modal.css',
        './panels/chart-modal.css',
        './panels/download-modal.css',
        './panels/floating-modal.scss',
        './panels/framing-modal.css',
        './panels/matting-modal.css',
        './panels/print-modal.css',
        './menus/projects-menu.css',
        './panels/review-modal.css',
        './panels/shipping-modal.css',
        './panels/sizing-modal.css',
        './panels/title-modal.css',
        './panels/transport-modal.css',
        './studio.component.css'
    ]
})
export class StudioComponent implements OnInit {
    hiResSpinner: boolean;
    public disabled: boolean;
    public copyName: string;
    public paperSizeInches: {
        value: number,
        label: string
    }[] = [];
    public paperSizeFractions: {
        value: number,
        label: string
    }[] = [];
    public cardSizeInches: {
        value: number,
        label: string
    }[] = [];
    public cardSizeFractions: {
        value: number,
        label: string
    }[] = [];
    public paperWidthInches: number;
    public paperWidthFraction: number;
    public paperHeightInches: number;
    public paperHeightFraction: number;
    public cardWidthInches: number;
    public cardWidthFraction: number;
    public cardHeightInches: number;
    public cardHeightFraction: number;
    public titleX: number;
    public titleY: number;
    public titleRectX: number;
    public titleRectY: number;
    public titleRectW: number;
    public titleRectH: number;
    zooms = {
        1: 0.25,
        2: 0.5,
        3: 0.75,
        4: 1,
        5: 2,
        6: 4,
        7: 8
    };
    useRatio = 1;

    shipToName: string;
    shipToAddress1: string;
    shipToAddress2: string;
    shipToCity: string;
    shipToState: string;
    shipToZip: string;
    shipToPhone: string;

    shippingOptions: [];
    shippingOption: {
        key: string,
        rate: number,
        desc: string
    };

    public mediumsRows = [
        ['Matte', 'Gloss', 'Luster'],
        ['Laminated', 'Canvas', 'Vinyl Banner']
    ];
    public qty = 1;
    public discountBase = 0.85;
    public basePrices = {
        Matte: [0.0344, 0.0258, 0.0172],
        Gloss: [0.0461, 0.0346, 0.0231],
        Luster: [0.0417, 0.0313, 0.0208],
        Laminated: [0.0644, 0.0483, 0.0322],
        Canvas: [0.0528, 0.0396, 0.0264],
        'Vinyl Banner': [0.0567, 0.0425, 0.0283]
    };
    subtotal: 0;
    shippingSelectedRate: 0;
    paperType = {
        Matte: true,
        Gloss: false,
        Luster: false,
        Laminated: false,
        Canvas: false,
        'Vinyl Banner': false
    };
    selectedPaperType = 'Matte';
    printCart = [];

    // ngx-uploader...
    options: {
        concurrency: 1,
        maxUploads: 1,
        allowedContentTypes: ['jpg', 'jpeg', 'png']
    };
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: any;
    // ...ngx-uploader

    tabs = {
        main: {
            layout: false,
            image: true,
            config: false,
            label: false,
            text: false
        },
        tiles: {
            layout: true,
            image: false,
            config: false,
            labels: false,
            text: false
        }
    };
    public exports: [{
        id: string,
        date: string,
        dimensions: string,
        fileName: string,
        fileSize: string,
        user: string
    }];
    public message: string;
    public currentDescription: string;
    public currentTier: number;
    public nextDescription: string;
    public nextLabel: string;
    public nextPrice: number;
    public nextTier: number;
    public usagePercent: number;
    public applyToAll: boolean;
    public applyToAllLabels: boolean;
    public exportWidth: number;
    public exportHeight: number;
    public defaultFont1: string;
    public defaultFont2: string;
    public defaultFont1Index: number;
    public defaultFont2Index: number;
    public mainFont1: string;
    public mainFont2: string;
    public mainFont1Index: number;
    public mainFont2Index: number;
    public defaultTitleFont1: string; // todo - lose the "default" (only 1 title)
    public defaultTitleFont2: string;
    public defaultTitleFont1Index: number;
    public defaultTitleFont2Index: number;
    public textLabels: string;
    public mainTextLabels: string;
    public textPosition: string;
    public textAlignment: string;
    public marginsBehavior: string;
    public mainTextPosition: string;
    public mainTextAlignment: string;
    public titleText: string;
    public titleTextLine1: string;
    public titleTextLine2: string;
    public titleTextLine1Bold: boolean;
    public titleTextLine2Bold: boolean;
    public titleTextLine1Italic: boolean;
    public titleTextLine2Italic: boolean;
    public titleTextLine1Underline: boolean;
    public titleTextLine2Underline: boolean;
    public titleTextPosition: string;
    public titleTextAlignment: string;
    public rows: number;
    public cols: number;
    public colorPresetBackground: string;
    public colorPresetColor: string;
    public mainColorPresetBackground: string;
    public mainColorPresetColor: string;
    public titleColorPresetBackground: string;
    public titleColorPresetColor: string;
    public defaultBackgroundColor: string;
    public defaultBorderColor: string;
    public defaultLineColor: string;
    public defaultLineColorI: string;
    public defaultLineColorEven: string;
    public defaultLineColorOdd: string;
    public mainBackgroundColor: string;
    public mainBorderColor: string;
    public tilesBackgroundColor: string;
    public tilesBorderColor: string;
    public tilesBackgroundColorI: string;
    public tilesBorderColorI: string;
    public textLayerColor: string;
    public labelsBackgroundColorI: string;
    public labelsTextColorI: string;
    public labelsBackgroundColorEven: string;
    public labelsTextColorEven: string;
    public labelsBackgroundColorOdd: string;
    public labelsTextColorOdd: string;
    public tilesBackgroundColorEven: string;
    public tilesBorderColorEven: string;
    public tilesBackgroundColorOdd: string;
    public tilesBorderColorOdd: string;
    public framingModal: boolean;
    public mattingModal: boolean;
    public floatingModal: boolean;
    public chartModal: boolean;
    public sizingModal: boolean;
    public infoPanel: boolean;
    public labelsModal: boolean;
    public newModal: boolean = false;
    public titleModal: boolean;
    public anchorModal: boolean;
    public shippingModal: boolean = false;
    public transportModal: boolean = false;
    public reviewModal: boolean = false;
    public subjects = SUBJECTS;
    public layers = LAYERS;
    public tilesOptions: any[] = [
        {index: 'All Tiles (reset)'},
        {index: 'All Tiles & Main'},
        {index: 'Unchanged Tiles'},
        {index: 'Unchanged Tiles & Main'},
        {index: 'Even Tiles'},
        {index: 'Odd Tiles'}
    ];
    public dpi: number;
    public subjectIndex: any;
    public layerIndex?: number;
    public subjectTextIndex: number;
    public textModal: boolean;
    public currentSaved: boolean;
    public paperOrientation: string;
    public project: any;
    public locked: string;
    public type: string;
    public host: any;
    public zoom: number;
    public lastZoom: number;
    public paperWidth: number;
    public paperHeight: number;
    public titleWidth: number;
    public titleHeight: number;
    public width: number;
    public height: number;
    public svgParent: any;
    public svg: any;
    public poster: any;
    public d3: any;
    public parentNativeElement: any;
    public centerX: number;
    public centerY: number;
    public chartCenterX: number;
    public chartCenterY: number;
    public rememberX = 0;
    public rememberY = 0;
    public lastX = 0;
    public lastY = 0;
    public posterActive: boolean;
    public dimensionsActive: boolean;
    public gridActive: boolean;
    public thirdsActive: boolean;
    public matColor: string;
    public doubleMatColor: string;
    public frameColor: string;
    public posterSizes: any = [];
    public commonPosterSizes: any = [];
    public cardSizes: any = [];
    public commonCardSizes: any = [];
    public chartMargin: number;
    public anchorPosition: string;
    public anchorSize: number;
    public anchorScale: number;
    public offsetScale: number;
    public offsetX: number;
    public offsetY: number;
    public subjectScale1: number;
    public subjectScale2: number;
    public subjectDistribution: number;
    public frameSize: number;
    public frameShape: string;
    public maxSubjects = {
        Starburst: 144,
        Blocks: 144
    };
    public frameSizes: string[] = [];
    public frameStyles: string[] = [];
    public matSizes: string[] = [];
    public doubleMatSizes: string[] = [];
    public matStyles: string[] = [];
    public fonts: { id: string, size: number }[] = [];
    public uploadingActive: boolean;
    public columns: number;
    public labelHeightLabel: string;
    public mainLabelHeightLabel: string;
    public titleSizeLabel: string;
    public connectorLinesThickness: number;
    public chartRotation: number;
    public radius1: number;
    public radius2: number;
    public anchorBorderThickness: number;
    public borderThickness: number;
    public labelHeight: number;
    public mainLabelHeight: number;
    public titleSize: number;
    public fontSizes: number;
    public mainFontSizes: number;
    public mainFontScale: number;
    public titleFontSizes: number;
    public titleFontScale: number;
    public labelFontScale: number;
    public mainLabelFontScale: number;
    public mouseIsDown: boolean;
    public orientation: string;
    public anchorShape: string;
    public starburstAlignment: string;
    public subjectShape: string;
    public openModal = false;
    public downloadModal = false;
    public printModal = false;
    public projects: any;
    public deleteModal: {};
    public renameModal: {};
    public newNames: string[];
    public projectNames: {};
    public frameSpace: number;
    public revealWidth: number;
    public revealHeight: number;
    public marginWidth: number;
    public marginHeight: number;
    public chartWidth: number;
    public chartHeight: number;
    public receipt: boolean;
    public promoTry: string;
    public promoError: string;

    @HostBinding('class') classes = 'fade-in';

    @HostListener('window:resize') onResize() {
        this.setUseRatio();
        this.zoomClear();
        this.draw();
    }

    constructor(public element: ElementRef,
                public router: Router,
                d3Service: D3Service,
                public shippingService: ShippingService,
                public projectService: ProjectService,
                public projectsService: ProjectsService,
                public userService: UserService,
                public blocksService: BlocksService,
                public layersService: LayersService,
                public starburstService: StarburstService,
                public titleService: TitleService,
                public frameService: FrameService,
                public matService: MatService,
                public gridService: GridService,
                public exportService: ExportService,
                public payModalService: PayModalService,
                public studioService: StudioService,
                public subjectsService: SubjectsService,
                public envService: EnvService,
                private analyticsService: AnalyticsService) {

        this.studioService.subject1.subscribe(() => {
            this.message = '';
        });

        this.d3 = d3Service.getD3();

        // ngx-uploader...
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
        // ...ngx-uploader
    }


    ngOnInit() {
        this.generatePaperSizeOptions();
        this.userService.checkLogin();
        this.deleteModal = {};
        this.renameModal = {};
        this.dpi = 300;
        this.zoom = 3;
        this.anchorScale = 0.0;
        this.offsetScale = 0;
        this.offsetX = 0.5;
        this.offsetY = 0.5;
        this.paperOrientation = 'p';
        this.subjectScale1 = 0.0;
        this.subjectScale2 = 0.0;
        this.subjectDistribution = 0.0;
        this.connectorLinesThickness = 0;
        this.chartRotation = 0;
        this.radius1 = 0.0;
        this.radius2 = 0.0;
        this.anchorBorderThickness = 0.0;
        this.borderThickness = 0.0;
        this.labelHeight = 1.5;
        this.mainLabelHeight = 1.5;
        this.titleSize = 1.5;
        this.fontSizes = 0.5;
        this.mainFontSizes = 0.5;
        this.mainFontScale = 1.0;
        this.titleFontSizes = 0.5;
        this.titleFontScale = 1.0;
        this.labelFontScale = 1.0;
        this.mainLabelFontScale = 1.0;
        this.chartMargin = 0.0;
        this.anchorSize = 0.6650;
        this.anchorPosition = 'top';
        this.parentNativeElement = this.element.nativeElement;
        this.type = 'Blocks';
        this.newNames = [];
        this.projectNames = {};
        this.studioService.getAllData(this);
        this.analyticsService.record('studio');
    }


    generatePaperSizeOptions() {

        const MIN_PAPER_SIZE = 2;
        const MAX_PAPER_SIZE = 48;
        const MAX_CARD_SIZE = 11;

        for (let i = MIN_PAPER_SIZE; i <= MAX_PAPER_SIZE; i++) {
            this.paperSizeInches.push({value: i, label: i.toString()});
            if (i <= MAX_CARD_SIZE) {
                this.cardSizeInches.push({value: i, label: i.toString()});
            }
        }

        this.paperSizeFractions.push({value: 0, label: '- "'});
        this.paperSizeFractions.push({value: 0.125, label: '1/8"'});
        this.paperSizeFractions.push({value: 0.25, label: '1/4"'});
        this.paperSizeFractions.push({value: 0.375, label: '3/8"'});
        this.paperSizeFractions.push({value: 0.5, label: '1/2"'});
        this.paperSizeFractions.push({value: 0.625, label: '5/8"'});
        this.paperSizeFractions.push({value: 0.75, label: '3/4"'});
        this.paperSizeFractions.push({value: 0.875, label: '7/8"'});
    }


    drawInView() {
        this.zoomClear();
        this.setUseRatio();
        this.draw();
    }


    handleOrientationClick(orientation: string) {
        this.currentSaved = false;
        const beforeWidth = this.projectService.paperWidth;
        const beforeHeight = this.projectService.paperHeight;
        if ((orientation === 'p' && beforeHeight < beforeWidth)
            || (orientation === 'l' && beforeHeight > beforeWidth)) {
            this.projectService.paperWidth = beforeHeight;
            this.projectService.paperHeight = beforeWidth;
            this.paperOrientation = orientation;
        } else if (!orientation) {
            this.projectService.paperHeight = beforeWidth;
            this.paperOrientation = '';
        }
        this.setPaperSizeSelectsFromProject();
        this.drawInView();
    }
    handlePaperSizeSelect() {
        this.currentSaved = false;
        this.projectService.paperWidth = +this.paperWidthInches + +this.paperWidthFraction;
        this.projectService.paperHeight = +this.paperHeightInches + +this.paperHeightFraction;
        if (this.projectService.fold === 'v') {
            this.setFold('v');
        } else if (this.projectService.fold === 'h') {
            this.setFold('h');
        }
        this.setOrientation('');
        this.drawInView();
    }
    handleFoldClick(fold: string) {
        this.currentSaved = false;
        this.setFold(fold);
        this.setOrientation('');
        this.drawInView();
    }
    handleFoldSizeSelect() {
        this.currentSaved = false;
        if (this.projectService.fold === 'v') {
            this.projectService.paperWidth = (+this.cardWidthInches + +this.cardWidthFraction) * 2;
            this.projectService.paperHeight = +this.cardHeightInches + +this.cardHeightFraction;
        } else if (this.projectService.fold === 'h') {
            this.projectService.paperWidth = +this.cardWidthInches + +this.cardWidthFraction;
            this.projectService.paperHeight = (+this.cardHeightInches + +this.cardHeightFraction) * 2;
        }
        this.setPaperSizeSelectsFromProject();
        this.setOrientation('');
        this.drawInView();
    }
    handleCommonPrintSizeClick(size: { id: number, w: number, h: number }) {
        this.currentSaved = false;
        this.projectService.paperWidth = size.w;
        this.projectService.paperHeight = size.h;
        if (this.paperOrientation === 'l' && size.w < size.h) {
            this.projectService.paperWidth = size.h;
            this.projectService.paperHeight = size.w;
        } else if (this.paperOrientation === 'p' && size.w > size.h) {
            this.paperOrientation = 'l';
            this.projectService.paperWidth = size.h;
            this.projectService.paperHeight = size.w;
        } else if (size.w === size.h) {
            this.paperOrientation = '';
        }
        const { paperWidth, paperHeight } = this.projectService;
        this.paperOrientation = '';
        if (paperWidth < paperHeight) {
            this.paperOrientation = 'p';
        } else if (paperWidth > paperHeight) {
            this.paperOrientation = 'l';
        }
        this.setPaperSizeSelectsFromProject();
        this.setOrientation('');
        this.drawInView();
    }



    handleCommonCardSizeClick({ w, h }) {

        this.currentSaved = false;
        this.projectService.paperWidth = w;
        this.projectService.paperHeight = h;

        if (this.projectService.fold === 'v') {
            this.projectService.paperWidth = w * 2;
        } else if (this.projectService.fold === 'h') {
            this.projectService.paperHeight = h * 2;
        }

        this.paperWidthInches = Math.floor(this.projectService.paperWidth);
        this.paperWidthFraction = this.projectService.paperWidth % 1 ? this.projectService.paperWidth % 1 : 0;
        this.paperHeightInches = Math.floor(this.projectService.paperHeight);
        this.paperHeightFraction = this.projectService.paperHeight % 1 ? this.projectService.paperHeight % 1 : 0;

        if (this.projectService.fold === 'v') {
            this.setFold('v');
        } else if (this.projectService.fold === 'h') {
            this.setFold('h');
        }

        this.setOrientation('');
        this.drawInView();
    }



    setOrientation(orientation: string) {
        this.currentSaved = false;
        if (orientation) {
            this.paperOrientation = orientation;
        } else {
            this.paperOrientation = '';
            if (this.projectService.paperHeight > this.projectService.paperWidth) {
                this.paperOrientation = 'p';
            } else if (this.projectService.paperHeight < this.projectService.paperWidth) {
                this.paperOrientation = 'l';
            }
        }
        switch (this.paperOrientation) {
            case 'h':
            case 'l':
                if (this.projectService.paperHeight > this.projectService.paperWidth) {
                    const tempHeight = this.projectService.paperHeight;
                    this.projectService.paperHeight = this.projectService.paperWidth;
                    this.projectService.paperWidth = tempHeight;
                }
                break;
            default:
                if (this.projectService.paperHeight <= this.projectService.paperWidth) {
                    const tempHeight = this.projectService.paperHeight;
                    this.projectService.paperHeight = this.projectService.paperWidth;
                    this.projectService.paperWidth = tempHeight;
                }
        }
        this.setPaperSizeSelectsFromProject();
    }



    setPaperSizeSelectsFromProject() {
        this.paperWidthInches = Math.floor(this.projectService.paperWidth);
        this.paperWidthFraction = this.projectService.paperWidth - this.paperWidthInches;
        this.paperHeightInches = Math.floor(this.projectService.paperHeight);
        this.paperHeightFraction = this.projectService.paperHeight - this.paperHeightInches;
    }



    setFold(fold: string) {

        this.projectService.fold = fold;
        this.setFoldWithinLimits();

        let width = +this.paperWidthInches + +this.paperWidthFraction;
        let height = +this.paperHeightInches + +this.paperHeightFraction;

        if (fold === 'v') {
            width *= 0.5;
            this.projectService.frameSize = 0;
            this.projectService.matSize = 0;
            this.projectService.doubleMatSize = 0;
        }
        else if (fold === 'h') {
            height *= 0.5;
            this.projectService.frameSize = 0;
            this.projectService.matSize = 0;
            this.projectService.doubleMatSize = 0;
        }

        this.cardWidthInches = Math.floor(width);
        this.cardWidthFraction = width % 1 ? width % 1 : 0;
        this.cardHeightInches = Math.floor(height);
        this.cardHeightFraction = height % 1 ? height % 1 : 0;
    }



    setFoldWithinLimits() {
        if (this.projectService.fold === 'v' && (this.projectService.paperWidth > 22 || this.projectService.paperHeight > 17)) {
            this.projectService.paperWidth = 10;
            this.projectService.paperHeight = 7;
            this.setPaperSizeSelectsFromProject();
        }
        else if (this.projectService.fold === 'v' && (this.projectService.paperHeight > 17 || this.projectService.paperWidth > 22)) {
            this.projectService.paperWidth = 7;
            this.projectService.paperHeight = 10;
            this.setPaperSizeSelectsFromProject();
        }
        else if (this.projectService.fold === 'h' && (this.projectService.paperHeight > 22 || this.projectService.paperWidth > 17)) {
            this.projectService.paperWidth = 7;
            this.projectService.paperHeight = 10;
            this.setPaperSizeSelectsFromProject();
        }
        else if (this.projectService.fold === 'h' && (this.projectService.paperHeight > 17 || this.projectService.paperWidth > 22)) {
            this.projectService.paperWidth = 7;
            this.projectService.paperHeight = 10;
            this.setPaperSizeSelectsFromProject();
        }
    }



    onUploadOutput(output: UploadOutput): void {

        if (output.type === 'addedToQueue') {
            this.uploadingActive = true;
            this.message = '';
        } else if (output.type === 'allAddedToQueue' && this.uploadingActive) {
            if (this.subjectIndex !== undefined) {
                const event: UploadInput = {
                    type: 'uploadAll',
                    url: this.envService.url + 'upload',
                    method: 'POST',
                    data: {
                        token: this.userService.token,
                        project: this.project.id.toString(),
                        subjectIndex: this.subjectIndex.toString()
                    }
                };
                this.uploadInput.emit(event);
            } else if (this.layerIndex !== undefined) {
                const event: UploadInput = {
                    type: 'uploadAll',
                    url: this.envService.url + 'upload',
                    method: 'POST',
                    data: {
                        token: this.userService.token,
                        project: this.project.id.toString(),
                        layerIndex: this.layerIndex.toString()
                    }
                };
                this.uploadInput.emit(event);
            }
            this.uploadingActive = true;
        } else if (output.type === 'done') {
            if (output.file.response.error === 1001) {
                this.userService.clearLogin();
            } else if (output.file.response.error) {
                this.uploadingActive = false;
                this.message = output.file.response.message;
                this.usagePercent = output.file.response.usagePercent;
                this.currentDescription = output.file.response.currentDescription;
                this.currentTier = output.file.response.currentTier;
                this.nextDescription = output.file.response.nextDescription;
                this.nextLabel = output.file.response.nextLabel;
                this.nextPrice = output.file.response.nextPrice;
                this.nextTier = output.file.response.nextTier;
            } else {
                this.usagePercent = 0;
                if (this.subjectIndex !== undefined) {
                    this.subjects[this.subjectIndex].id = output.file.response.id;
                    this.subjects[this.subjectIndex].originalImageWidth = output.file.response.originalImageWidth;
                    this.subjects[this.subjectIndex].originalImageHeight = output.file.response.originalImageHeight;
                } else if (this.layerIndex !== undefined) {
                    this.layers[this.layerIndex].id = output.file.response.id;
                    this.layers[this.layerIndex].originalImageWidth = output.file.response.originalImageWidth;
                    this.layers[this.layerIndex].originalImageHeight = output.file.response.originalImageHeight;
                }
                this.currentSaved = false;
                this.uploadingActive = false;
                this.draw();
            }
        }
    }


    cancelUpload(id: string): void {
        this.uploadInput.emit({type: 'cancel', id: id});
    }


    removeFile(id: string): void {
        this.uploadInput.emit({type: 'remove', id: id});
    }


    removeAllFiles(): void {
        this.uploadInput.emit({type: 'removeAll'});
    }


    openUploadModal(index: number) {
        this.closeModals();
        this.subjectIndex = index;
        if (index) {
            this.chartModal = true;
            this.draw();
        } else {
            this.anchorModal = true;
            this.draw();
        }
    }


    openLayersModal(index: number) {
        this.closeModals();
        this.floatingModal = true;
        this.layerIndex = index - 1;
    }


    openTextModal(index: number) {
        this.subjectTextIndex = index;
        this.textModal = true;
    }


    closeTextModal() {
        this.subjectTextIndex = undefined;
        this.textModal = false;
        this.draw();
    }


    openTitleText() {
        this.closeModals();
        this.titleModal = true;
        this.draw();
    }


    closeModals() {
        this.subjectIndex = undefined;
        this.layerIndex = undefined;
        this.newModal = false;
        // this.openModal = false;
        this.downloadModal = false;
        this.printModal = false;
        this.sizingModal = false;
        this.infoPanel = false;
        this.titleModal = false;
        this.anchorModal = false;
        this.labelsModal = false;
        this.framingModal = false;
        this.mattingModal = false;
        this.chartModal = false;
        this.floatingModal = false;
        this.shippingModal = false;
        this.transportModal = false;
        this.reviewModal = false;
        this.draw();
    }


    newProject(newProject: any) {
        this.closeModals();
        const nameKeys = {};
        for (let i = 0; i < this.projects.length; i++) {
            nameKeys[this.projects[i].id] = true;
        }
        let newName: string;
        for (let i = 1; !newName; i++) {
            if (!nameKeys[i]) {
                newName = i.toString();
            }
        }

        newProject.id = newName;

        this.resetSubjectsIndexes();
        this.projectsService.saveNewProject(newProject)
            .subscribe(
                data => {
                    this.projects = data;
                    this.newNames = [];
                    this.projectNames = {};
                    this.projects.forEach(project => {
                        this.newNames.push(project.id);
                        this.projectNames[project.id] = true;
                    });
                    let index = 0;
                    for (let i = 0; i < this.projects.length; i++) {
                        if (this.projects[i].id === newName) {
                            index = i;
                        }
                    }
                    this.setProject(index);
                    this.openModal = true;
                    this.renameModal[index] = true;
                });
    }


    saveProject() {
        // TODO - MOVE TO PROJECTS SERVICE .. THEN HAVE RENAME USE IT BEFORE RENAMING
        const projectData = {
            aliasId: this.project.aliasId,
            id: this.project.id,
            user: this.project.user,
            type: this.type,
            columns: this.columns,
            orientation: this.orientation, // TODO - THIS IS THE TILES ORIENTATION (NEEDS A BETTER NAME)
            paperOrientation: this.paperOrientation,
            fold: this.projectService.fold,
            paperWidth: this.projectService.paperWidth,
            paperHeight: this.projectService.paperHeight,
            frameSize: this.projectService.frameSize,
            frameShape: this.projectService.frameShape,
            frameColor: this.projectService.frameColor,
            matSize: this.projectService.matSize,
            matSizeTop: this.projectService.matSizeTop,
            matSizeLeft: this.projectService.matSizeLeft,
            matSizeRight: this.projectService.matSizeRight,
            matSizeBottom: this.projectService.matSizeBottom,
            matColor: this.projectService.matColor,
            doubleMatSize: this.projectService.doubleMatSize,
            doubleMatColor: this.projectService.doubleMatColor,
            chartMargin: this.chartMargin,
            anchorSize: this.anchorSize,
            anchorPosition: this.anchorPosition,
            anchorShape: this.anchorShape,
            starburstAlignment: this.starburstAlignment,
            subjectShape: this.subjectShape,
            anchorScale: this.anchorScale,
            subjectScale1: this.subjectScale1,
            subjectScale2: this.subjectScale2,
            subjectDistribution: this.subjectDistribution,
            connectorLinesThickness: this.connectorLinesThickness,
            chartRotation: this.chartRotation,
            radius1: this.radius1,
            radius2: this.radius2,
            anchorBorderThickness: this.anchorBorderThickness,
            borderThickness: this.borderThickness,
            labelHeight: this.labelHeight,
            mainLabelHeight: this.mainLabelHeight,
            titleSize: this.titleSize,
            fontSizes: this.fontSizes,
            mainFontSizes: this.mainFontSizes,
            mainFontScale: this.mainFontScale,
            titleFontSizes: this.titleFontSizes.toString(),
            titleFontScale: this.titleFontScale,
            labelFontScale: this.labelFontScale,
            mainLabelFontScale: this.mainLabelFontScale,
            textLabels: this.textLabels,
            mainTextLabels: this.mainTextLabels,
            defaultFont1: this.defaultFont1,
            defaultFont2: this.defaultFont2,
            mainFont1: this.mainFont1,
            mainFont2: this.mainFont2,
            defaultTitleFont1: this.defaultTitleFont1,
            defaultTitleFont2: this.defaultTitleFont2,
            textPosition: this.textPosition,
            textAlignment: this.textAlignment,
            marginsBehavior: this.marginsBehavior,
            mainTextPosition: this.mainTextPosition,
            mainTextAlignment: this.mainTextAlignment,
            titleText: this.titleText,
            titleTextLine1: this.titleTextLine1,
            titleTextLine2: this.titleTextLine2,
            titleTextLine1Bold: this.titleTextLine1Bold,
            titleTextLine2Bold: this.titleTextLine2Bold,
            titleTextLine1Italic: this.titleTextLine1Italic,
            titleTextLine2Italic: this.titleTextLine2Italic,
            titleTextLine1Underline: this.titleTextLine1Underline,
            titleTextLine2Underline: this.titleTextLine2Underline,
            rows: this.rows,
            cols: this.cols,
            titleTextPosition: this.titleTextPosition,
            titleTextAlignment: this.titleTextAlignment,
            applyToAll: this.applyToAll,
            applyToAllLabels: this.applyToAllLabels,
            mainBackgroundColor: this.mainBackgroundColor,
            mainBorderColor: this.mainBorderColor,
            colorPresetBackground: this.colorPresetBackground,
            colorPresetColor: this.colorPresetColor,
            mainColorPresetBackground: this.mainColorPresetBackground,
            mainColorPresetColor: this.mainColorPresetColor,
            tilesBackgroundColor: this.tilesBackgroundColor,
            tilesBorderColor: this.tilesBorderColor,
            titleColorPresetBackground: this.titleColorPresetBackground,
            titleColorPresetColor: this.titleColorPresetColor,
            defaultBackgroundColor: this.defaultBackgroundColor,
            defaultBorderColor: this.defaultBorderColor,
            defaultLineColor: this.defaultLineColor,
            tilesBackgroundColorEven: this.tilesBackgroundColorEven,
            tilesBorderColorEven: this.tilesBorderColorEven,
            defaultLineColorEven: this.defaultLineColorEven,
            tilesBackgroundColorOdd: this.tilesBackgroundColorOdd,
            tilesBorderColorOdd: this.tilesBorderColorOdd,
            defaultLineColorOdd: this.defaultLineColorOdd,
            labelsBackgroundColorEven: this.labelsBackgroundColorEven,
            labelsTextColorEven: this.labelsTextColorEven,
            labelsBackgroundColorOdd: this.labelsBackgroundColorOdd,
            labelsTextColorOdd: this.labelsTextColorOdd,
            subjects: this.subjects,
            layers: this.layers
        };
        this.project = projectData;
        this.projectsService.saveExistingProject(projectData)
            .subscribe(
                data => {
                    this.currentSaved = true;
                    this.projects.forEach((project, index) => {
                        if (this.project.id === project.id) {
                            this.projects[index] = this.project;
                        }
                    });
                });
    }


    setUseRatio() {
        setTimeout(() => {

            const svg = document.getElementById('svg');
            if (!svg) {
                return;
            }
            const vector = svg.getBoundingClientRect();
            const viewportWidth = vector.width;
            const viewportHeight = vector.height;

            const fullWidth = this.projectService.paperWidth * this.dpi;
            const fullHeight = this.projectService.paperHeight * this.dpi;

            const widthRatio = viewportWidth / fullWidth;
            const heightRatio = viewportHeight / fullHeight;
            this.useRatio = widthRatio < heightRatio ? widthRatio : heightRatio;

        }, 0);
    }


    zoomClear() {
        this.lastX = 0;
        this.lastY = 0;
        this.rememberX = 0;
        this.rememberY = 0;
        this.zoom = 3;
    }


    zoomIn() {
        if (this.zoom < 7) {
            this.rememberX = 0;
            this.rememberY = 0;
            this.lastX = 0;
            this.lastY = 0;
            this.zoom++;
            this.draw();
        }
    }


    zoomOut(skipDrawing) {
        if (this.zoom > 1) {
            this.rememberX = 0;
            this.rememberY = 0;
            this.lastX = 0;
            this.lastY = 0;
            this.zoom--;
            if (!skipDrawing) {
                this.draw();
            }
        }
    }


    togglePosterOnly() {
        this.posterActive = !this.posterActive;
        const opacity = (this.posterActive) ? 0 : (this.gridActive) ? 0.3 : 1;
        this.d3.select('#window').style('opacity', opacity);
        this.d3.select('#frame').style('opacity', opacity);
        this.d3.select('#frame-shadow').style('opacity', opacity);
        this.d3.select('.mat').style('opacity', opacity);
        this.d3.select('.mat2').style('opacity', opacity);
        this.d3.select('.core').style('opacity', opacity);
        this.d3.select('.core2').style('opacity', opacity);
    }


    toggleDimensions() {
        if (!this.dimensionsActive) {
            this.zoomClear();
            this.zoomOut(true);
            this.draw();
        }
        this.dimensionsActive = !this.dimensionsActive;
        const opacity = (this.dimensionsActive) ? 1 : 0;
        this.d3.select('#poster-markers').style('opacity', opacity);
        if (this.projectService.frameSize || this.projectService.matSize) {
            this.d3.select('#reveal-markers').style('opacity', opacity);
        } else {
            this.d3.select('#reveal-markers').style('opacity', 0);
        }
    }


    toggleGridLines() {
        this.gridActive = !this.gridActive;
        if (this.gridActive) {
            this.d3.select('#grid-lines').style('opacity', 1);
            this.d3.select('#center-lines').style('opacity', 1);
            this.d3.select('#paper').style('opacity', 0.5);
            this.d3.selectAll('.image').style('opacity', 0.3);
            this.d3.selectAll('.line').style('opacity', 0.3);
            this.d3.selectAll('.layers').style('opacity', 0.3);
            this.d3.select('#title-rect').style('opacity', 0.3);
            const opacity = (this.posterActive) ? 0 : 0.3;
            this.d3.selectAll('.core').style('opacity', opacity);
            this.d3.selectAll('.mat').style('opacity', opacity);
            this.d3.selectAll('.bevel').style('opacity', opacity);
            this.d3.selectAll('.core2').style('opacity', opacity);
            this.d3.selectAll('.mat2').style('opacity', opacity);
            this.d3.selectAll('.bevel2').style('opacity', opacity);
            this.d3.select('#frame').style('opacity', opacity);
            this.d3.select('#frame-shadow').style('opacity', opacity);
        } else {
            this.d3.select('#grid-lines').style('opacity', 0);
            this.d3.select('#center-lines').style('opacity', 0);
            this.d3.select('#paper').style('opacity', 1);
            this.d3.selectAll('.image').style('opacity', 1);
            this.d3.selectAll('.line').style('opacity', 1);
            this.d3.selectAll('.layers').style('opacity', 1);
            this.d3.select('#title-rect').style('opacity', 1);
            const opacity = (this.posterActive) ? 0 : 1;
            this.d3.selectAll('.core').style('opacity', opacity);
            this.d3.selectAll('.mat').style('opacity', opacity);
            this.d3.selectAll('.bevel').style('opacity', opacity);
            this.d3.selectAll('.core2').style('opacity', opacity);
            this.d3.selectAll('.mat2').style('opacity', opacity);
            this.d3.selectAll('.bevel2').style('opacity', opacity);
            this.d3.select('#frame').style('opacity', opacity);
            this.d3.select('#frame-shadow').style('opacity', opacity);
        }
    }


    toggleThirdsLines() {
        this.thirdsActive = !this.thirdsActive;
        if (this.thirdsActive) {
            this.d3.select('#thirds-lines').style('opacity', 1);
        } else {
            this.d3.select('#thirds-lines').style('opacity', 0);
        }
    }


    defs() {

        const defs = this.svg.append('defs');

        // Large Shadow - Outside of Frame (studio only)
        let filter = defs.append('filter')
            .attr('id', 'large-shadow')
            .attr('height', '120%');
        filter.append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', 60)
            .attr('result', 'blur');
        filter.append('feOffset')
            .attr('in', 'blur')
            .attr('dx', 30)
            .attr('dy', 30)
            .attr('result', 'offsetBlur');
        let feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode')
            .attr('in', 'offsetBlur');
        feMerge.append('feMergeNode')
            .attr('in', 'SourceGraphic');

        // Medium Shadow - Paper (studio only)
        filter = defs.append('filter')
            .attr('id', 'medium-shadow')
            .attr('height', '130%');
        filter.append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', 20)
            .attr('result', 'blur');
        filter.append('feOffset')
            .attr('in', 'blur')
            .attr('dx', 5)
            .attr('dy', 5)
            .attr('result', 'offsetBlur');
        feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode')
            .attr('in', 'offsetBlur');
        feMerge.append('feMergeNode')
            .attr('in', 'SourceGraphic');

        // Small Shadow
        filter = defs.append('filter')
            .attr('id', 'small-shadow')
            .attr('height', '130%');
        filter.append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', this.dpi / 50)
            .attr('result', 'blur');
        filter.append('feOffset')
            .attr('in', 'blur')
            .attr('dx', 0)
            .attr('dy', 0)
            .attr('result', 'offsetBlur');
        feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode')
            .attr('in', 'offsetBlur');
        feMerge.append('feMergeNode')
            .attr('in', 'SourceGraphic');
    }


    setProject(index: number) {

        this.copyName = '';

        if (this.dimensionsActive) this.toggleDimensions();
        if (this.gridActive) this.toggleGridLines();
        if (this.posterActive) this.togglePosterOnly();
        this.currentSaved = true;

        this.project = this.projects[index];
        const project = this.project;

        this.locked = project.locked || 'false';
        this.orientation = project.orientation || '';
        this.columns = project.columns || 0;

        this.projectService.aliasId = project.aliasId;
        this.projectService.id = project.id;
        this.projectService.type = project.type || this.type;
        this.type = project.type || this.type;
        this.projectService.fold = project.fold || '';
        this.projectService.paperWidth = project.paperWidth || 20;
        this.projectService.paperHeight = project.paperHeight || 16;
        this.projectService.frameSize = project.frameSize || 0;
        this.projectService.frameShape = project.frameShape || 'full-bevel';
        this.projectService.frameColor = project.frameColor || 'rgb(10,10,10)';
        this.projectService.matSize = project.matSize || 0;
        this.projectService.matSizeTop = project.matSizeTop || project.matSize;
        this.projectService.matSizeLeft = project.matSizeLeft || project.matSize;
        this.projectService.matSizeRight = project.matSizeRight || project.matSize;
        this.projectService.matSizeBottom = project.matSizeBottom || project.matSize;
        if (project.matColor) {
            this.projectService.matColor = project.matColor;
        } else if (project.doubleMatSize) {
            this.projectService.matColor = 'rgb(40,35,31)';
        } else {
            this.projectService.matColor = 'rgb(242,244,244)';
        }
        this.projectService.doubleMatSize = project.doubleMatSize || 0;
        this.projectService.doubleMatColor = project.doubleMatColor || 'rgb(242,244,244)';

        if (project.anchorShape) {
            this.anchorShape = project.anchorShape;
        } else if (project.type === 'Starburst') {
            this.anchorShape = '';
        } else {
            this.anchorShape = 'rect';
        }
        this.starburstAlignment = project.starburstAlignment || 'Perpendicular';
        this.subjectShape = project.subjectShape || 'rect';
        this.chartMargin = project.chartMargin || 0;
        this.anchorSize = project.anchorSize || 0.665;
        this.anchorPosition = project.anchorPosition || '';
        this.anchorScale = (project.anchorScale !== undefined) ? project.anchorScale : 0.335;
        this.subjectScale1 = project.subjectScale1 || 0.9;
        this.subjectScale2 = project.subjectScale2 || 0.9;
        this.subjectDistribution = project.subjectDistribution || 0;
        this.connectorLinesThickness = project.connectorLinesThickness || 0;
        this.chartRotation = project.chartRotation || 0;
        this.radius1 = project.radius1 || 1;
        this.radius2 = project.radius2 || 1;
        this.anchorBorderThickness = project.anchorBorderThickness || 0;
        this.borderThickness = project.borderThickness || 0;
        this.labelHeight = project.labelHeight || 0;
        this.mainLabelHeight = project.mainLabelHeight || 0;
        this.titleSize = project.titleSize || 0;
        this.fontSizes = project.fontSizes || 0.5;
        this.mainFontSizes = project.mainFontSizes || 0.5;
        this.mainFontScale = project.mainFontScale || 1;
        this.titleFontSizes = project.titleFontSizes || 0.5;
        this.titleFontScale = project.titleFontScale || 1;
        this.labelFontScale = project.labelFontScale || 1;
        this.mainLabelFontScale = project.mainLabelFontScale || 1;
        this.textLabels = project.textLabels || '';
        this.mainTextLabels = project.mainTextLabels || '';
        this.defaultFont1 = project.defaultFont1 || 'Open Sans';
        this.defaultFont2 = project.defaultFont2 || 'Open Sans';
        this.defaultFont1Index = this.studioService.getFontIndex(this, 'defaultFont1');
        this.defaultFont2Index = this.studioService.getFontIndex(this, 'defaultFont2');
        this.mainFont1 = project.mainFont1 || 'Open Sans';
        this.mainFont2 = project.mainFont2 || 'Open Sans';
        this.mainFont1Index = this.studioService.getFontIndex(this, 'mainFont1');
        this.mainFont2Index = this.studioService.getFontIndex(this, 'mainFont2');
        this.defaultTitleFont1 = project.defaultTitleFont1 || 'Open Sans';
        this.defaultTitleFont2 = project.defaultTitleFont2 || 'Open Sans';
        this.defaultTitleFont1Index = this.studioService.getFontIndex(this, 'defaultTitleFont1');
        this.defaultTitleFont2Index = this.studioService.getFontIndex(this, 'defaultTitleFont2');
        this.textPosition = project.textPosition || 'center';
        this.textAlignment = project.textAlignment || 'center';
        this.marginsBehavior = project.marginsBehavior || 'image';
        this.mainTextPosition = project.mainTextPosition || 'center';
        this.mainTextAlignment = project.mainTextAlignment || 'center';
        this.titleText = project.titleText || '';
        this.titleTextLine1 = project.titleTextLine1 || '';
        this.titleTextLine2 = project.titleTextLine2 || '';
        this.titleTextLine1Bold = project.titleTextLine1Bold || false;
        this.titleTextLine2Bold = project.titleTextLine2Bold || false;
        this.titleTextLine1Italic = project.titleTextLine1Italic || false;
        this.titleTextLine2Italic = project.titleTextLine2Italic || false;
        this.titleTextLine1Underline = project.titleTextLine1Underline || false;
        this.titleTextLine2Underline = project.titleTextLine2Underline || false;
        this.rows = project.rows || 0;
        this.cols = project.cols || 0;
        this.titleTextPosition = project.titleTextPosition || 'center';
        this.titleTextAlignment = project.titleTextAlignment || 'center';
        this.colorPresetBackground = project.colorPresetBackground || 'rgba(0,0,0,0.66)';
        this.colorPresetColor = project.colorPresetColor || 'white';
        this.labelsBackgroundColorEven = project.labelsBackgroundColorEven || this.colorPresetBackground;
        this.labelsBackgroundColorOdd = project.labelsBackgroundColorOdd || this.colorPresetBackground;
        this.labelsTextColorEven = project.labelsTextColorEven || this.colorPresetColor;
        this.labelsTextColorOdd = project.labelsTextColorOdd || this.colorPresetColor;
        this.mainColorPresetBackground = project.mainColorPresetBackground || 'rgba(0,0,0,0.66)';
        this.mainColorPresetColor = project.mainColorPresetColor || 'white';
        this.titleColorPresetBackground = project.titleColorPresetBackground || 'rgba(0,0,0,0.66)';
        this.titleColorPresetColor = project.titleColorPresetColor || 'white';
        this.defaultBackgroundColor = project.defaultBackgroundColor || 'lightgray';
        this.defaultBorderColor = project.defaultBorderColor || 'black';
        this.defaultLineColor = project.defaultLineColor || 'black';
        this.tilesBackgroundColorEven = project.tilesBackgroundColorEven || 'lightgray';
        this.tilesBorderColorEven = project.tilesBorderColorEven || 'black';
        this.defaultLineColorEven = project.defaultLineColorEven || 'black';
        this.tilesBackgroundColorOdd = project.tilesBackgroundColorOdd || 'lightgray';
        this.tilesBorderColorOdd = project.tilesBorderColorOdd || 'black';
        this.defaultLineColorOdd = project.defaultLineColorOdd || 'black';
        this.applyToAll = project.applyToAll || false;
        this.applyToAllLabels = project.applyToAllLabels || false;
        this.mainBackgroundColor = project.mainBackgroundColor || 'lightgray';
        this.mainBorderColor = project.mainBorderColor || 'black';
        this.tilesBackgroundColor = project.tilesBackgroundColor || 'lightgray';
        this.tilesBorderColor = project.tilesBorderColor || 'black';
        this.paperOrientation = '';
        if (this.projectService.paperHeight > this.projectService.paperWidth) {
            this.paperOrientation = 'p';
        } else if (this.projectService.paperHeight < this.projectService.paperWidth) {
            this.paperOrientation = 'l';
        }
        this.subjects = JSON.parse(JSON.stringify(project.subjects));
        this.layers = project.layers ? JSON.parse(JSON.stringify(project.layers)) : [];
        // temp type schema fix..
        this.layers.forEach(layer => {
            if (layer.bold !== undefined) {
                layer.bold = layer.bold.toString();
            }
            if (layer.italic !== undefined) {
                layer.italic = layer.italic.toString();
            }
            if (layer.underline !== undefined) {
                layer.underline = layer.underline.toString();
            }
        });


        this.resetSubjectsIndexes();
        this.setPaperSizeSelectsFromProject();
        this.setFold(this.projectService.fold);
        this.setUseRatio();
        this.zoomClear();
        this.closeModals();
    }


    deleteProject(index: number) {
        this.projectsService
            .deleteProject(this.projects[index].id)
            .subscribe(
                data => {
                    const deletingId = this.projects[index].id;
                    this.projects.splice(index, 1);
                    this.newNames = [];
                    this.projectNames = {};
                    this.projects.forEach((project) => {
                        this.newNames.push(project.id);
                        this.projectNames[project.id] = true;
                    });
                    if (this.project && this.project.id === deletingId) {
                        this.currentSaved = true;
                        this.project = null;
                        this.anchorModal = false;
                        this.floatingModal = false;
                        this.chartModal = false;
                        this.host = this.d3.select(this.parentNativeElement);
                        this.host.select('#svg').remove();
                    }
                });
    }


    renameProject(index: number) {
        // 1. Test name availability.
        const oldName = this.projects[index].id;
        const newName = this.newNames[index];
        let nameGood = true;
        this.projects.forEach((project) => {
            if (newName === project.id) {
                nameGood = false;
            }
        });
        if (nameGood && newName.length) { // todo - enhance name check
            let loadedProjectId = '';
            if (this.project) {
                this.project.id = this.newNames[index];
                loadedProjectId = this.project.id;
            }
            this.projects[index].id = newName;
            // 2. Save project using new name (copy code from new).
            this.projectsService
                .renameProject(oldName, this.projects[index])
                .subscribe(
                    data => {
                        this.projects = data;
                        this.newNames = [];
                        this.projectNames = {};
                        const self = this;
                        this.projects.forEach((project) => {
                            this.newNames.push(project.id);
                            this.projectNames[project.id] = true;
                            if (project.id === loadedProjectId) {
                                self.project = project;
                            }
                        });
                        this.projectService.id = newName;
                    });
            // 3. If the project is loaded, apply the change too (if anything is affected).
        } else {
            // rename failed, cancelling
            // todo - send an error message
            this.newNames[index] = oldName;
        }
    }


    copyProject() {
        const oldName = this.project.id;
        this.project.id = this.copyName;
        this.projectsService
            .copyProject(oldName, this.project)
            .subscribe(
                data => {
                    this.projects = data;
                    this.newNames = [];
                    this.projectNames = {};
                    const self = this;
                    let copiedIndex = 0;
                    this.projects.forEach((project, index) => {
                        this.newNames.push(project.id);
                        this.projectNames[project.id] = true;
                        self.project = project;
                        if (project.id === this.copyName) {
                            copiedIndex = index;
                        }
                    });
                    this.setProject(copiedIndex);
                });
    }


    setPrintPrices() {
        this.subtotal = 0;
        this.printCart.forEach((item, index) => {

            const tierIndex = (item.qty >= 100) ? 2 : (item.qty >= 25) ? 1 : 0;

            switch (item.paperType) {
                case 'Matte':
                    item.price = this.basePrices['Matte'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Matte Adhesive':
                    item.price = this.basePrices['Matte Adhesive'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Matte Laminated':
                    item.price = this.basePrices['Matte Laminated'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Gloss':
                    item.price = this.basePrices['Gloss'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Gloss Adhesive':
                    item.price = this.basePrices['Gloss Adhesive'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Gloss Laminated':
                    item.price = this.basePrices['Gloss Laminated'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Luster':
                    item.price = this.basePrices['Luster'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Canvas':
                    item.price = this.basePrices['Canvas'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
                case 'Vinyl Banner':
                    item.price = this.basePrices['Vinyl Banner'][tierIndex] * item.paperHeight * item.paperWidth * this.discountBase;
                    item.subtotal = item.qty * item.price;
                    break;
            }
            this.subtotal += item.subtotal;
        });
    }


    setFrameSize(selectedSize: number) {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.frameSize = selectedSize;
        if (selectedSize) {
            this.projectService.fold = '';
        }
        this.draw();
    }


    setFrameShape(selectedShape: string) {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.frameShape = selectedShape;
        this.draw();
    }


    setFrameColor(selectedColor: string) {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.frameColor = selectedColor;
        this.matService.draw(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.matService.drawDoubleMat(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.frameService.draw(this, this.svg, this.dpi, this.centerX, this.centerY,false);
    }


    setAnchorShape(shape: string) {
        this.currentSaved = false;
        this.mainTextPosition = 'center';
        this.mainTextAlignment = 'center';
        this.anchorShape = shape;
        this.draw();
    }


    setScope(option: string) {
        // TODO - Update text properties for all or items that are only unchanged
        // this.draw();
    }


    setShape(shape: string) {
        this.currentSaved = false;
        this.textPosition = 'center';
        this.textAlignment = 'center';
        this.subjectShape = shape;
        this.draw();
    }


    setTextLabels(type: string) {
        this.currentSaved = false;
        this.textLabels = type;
        if (this.textLabels && !this.labelHeight) {
            this.labelHeight = 0.15;
            this.labelHeightLabel = '15.0%';
        }
        this.draw();
    }


    setTextPosition(type: string) {
        this.currentSaved = false;
        this.textPosition = type;
        this.textAlignment = type;
        this.draw();
    }


    setTextAlignment(type: string) {
        this.currentSaved = false;
        this.textAlignment = type;
        this.draw();
    }


    setMarginsBehavior(type: string) {
        this.currentSaved = false;
        this.marginsBehavior = type;
        this.draw();
    }


    setMainTextLabels(type: string) {
        this.currentSaved = false;
        this.mainTextLabels = type;
        if (this.mainTextLabels && !this.mainLabelHeight) {
            this.mainLabelHeight = 0.15;
            this.mainLabelHeightLabel = '15.0%';
        }
        this.draw();
    }


    setMainTextPosition(type: string) {
        this.currentSaved = false;
        this.mainTextPosition = type;
        this.mainTextAlignment = type;
        this.draw();
    }


    setMainTextAlignment(type: string) {
        this.currentSaved = false;
        this.mainTextAlignment = type;
        this.draw();
    }


    setLayoutType(type: string) {
        this.currentSaved = false;
        this.type = type;
        this.draw();
    }


    setAnchorPosition(position: string) {
        this.currentSaved = false;
        this.anchorPosition = position;
        this.draw();
    }


    setTitleText(type: string) {
        this.currentSaved = false;
        this.titleText = type;
        this.titleTextAlignment = 'center';
        if (type === 'left' || type === 'right') {
            this.titleTextPosition = 'middle';
        } else {
            this.titleTextPosition = 'center';
        }
        if (this.titleText && !this.titleSize) {
            this.titleSize = 0.15;
            this.titleSizeLabel = '15.0%';
        }
        this.draw();
    }


    setTitleTextPosition(type: string) {
        this.currentSaved = false;
        this.titleTextPosition = type;
        this.draw();
    }


    setTitleTextAlignment(type: string) {
        this.currentSaved = false;
        this.titleTextAlignment = type;
        this.draw();
    }


    setColorPreset(background: string, color: string) {
        this.currentSaved = false;
        this.colorPresetBackground = background;
        this.colorPresetColor = color;
        if (this.tabs.tiles.labels
            && ((this.subjectIndex === 'All Tiles & Main' || this.subjectIndex === 'Unchanged Tiles & Main'))) {
            this.mainColorPresetBackground = this.colorPresetBackground;
            this.mainColorPresetColor = this.colorPresetColor;
        } else {
            this.setApplyToAllLabels(this.applyToAllLabels);
        }
        this.draw();
    }


    setMainColorPreset(background: string, color: string) {
        this.currentSaved = false;
        this.mainColorPresetBackground = background;
        this.mainColorPresetColor = color;
        this.setApplyToAllLabels(this.applyToAllLabels);
    }


    setTitleColorPreset(background: string, color: string) {
        this.currentSaved = false;
        this.titleColorPresetBackground = background;
        this.titleColorPresetColor = color;
        this.draw();
    }


    setDefaultBackgroundColor(color: string) {
        this.currentSaved = false;
        this.defaultBackgroundColor = color;
        this.draw();
    }


    setDefaultBorderColor(color: string) {
        this.currentSaved = false;
        this.defaultBorderColor = color;
        this.draw();
    }


    setDefaultLineColor(color: string) {
        this.currentSaved = false;
        this.defaultLineColor = color;
        this.draw();
    }


    setAllBackgroundColor(color: string) {
        this.currentSaved = false;
        this.mainBackgroundColor = color;
        this.tilesBackgroundColor = color;
        this.draw();
    }


    setAllBorderColor(color: string) {
        this.currentSaved = false;
        this.mainBorderColor = color;
        this.tilesBorderColor = color;
        this.draw();
    }


    setAllLineColor(color: string) {
        this.currentSaved = false;
        this.defaultLineColor = color;
        this.draw();
    }


    setApplyToAll(field: string, state: boolean) {
        this.currentSaved = false;
        this.applyToAll = state;
        if (this.applyToAll) {
            this.defaultBackgroundColor = this[field + 'BackgroundColor'];
            this.defaultBorderColor = this[field + 'BorderColor'];
            this.tilesBackgroundColor = this[field + 'BackgroundColor'];
            this.tilesBorderColor = this[field + 'BorderColor'];
            this.tilesBackgroundColorEven = this[field + 'BackgroundColor'];
            this.tilesBorderColorOdd = this[field + 'BorderColor'];
            this.subjects.forEach((subject) => {
                delete subject.backgroundColor;
                delete subject.borderColor;
                delete subject.lineColor;
            });
        }
        this.draw();
    }


    setApplyToAllLabels(state: boolean) {
        this.currentSaved = false;
        this.applyToAllLabels = state;
        if (this.applyToAllLabels) {
            this.colorPresetBackground = this.mainColorPresetBackground;
            this.colorPresetColor = this.mainColorPresetColor;

            // this.defaultBackgroundColor = this[field + 'BackgroundColor'];
            // this.defaultBorderColor = this[field + 'BorderColor'];
            // this.tilesBackgroundColor = this[field + 'BackgroundColor'];
            // this.tilesBorderColor = this[field + 'BorderColor'];
            // this.tilesBackgroundColorEven = this[field + 'BackgroundColor'];
            // this.tilesBorderColorOdd = this[field + 'BorderColor'];
            // this.subjects.forEach((subject) => {
            //     delete subject.backgroundColor;
            //     delete subject.borderColor;
            //     delete subject.lineColor;
            // });
        }
        this.draw();
    }


    selectedTileOption() {
        if (this.subjectIndex > 0) {
            this.tilesBackgroundColorI = this.tilesBackgroundColor;
            this.tilesBorderColorI = this.tilesBorderColor;
            this.defaultLineColorI = this.defaultLineColor;
            if (this.subjects[this.subjectIndex] && this.subjects[this.subjectIndex].backgroundColor) {
                this.tilesBackgroundColorI = this.subjects[this.subjectIndex].backgroundColor;
            }
            if (this.subjects[this.subjectIndex] && this.subjects[this.subjectIndex].borderColor) {
                this.tilesBorderColorI = this.subjects[this.subjectIndex].borderColor;
            }
            if (this.subjects[this.subjectIndex] && this.subjects[this.subjectIndex].lineColor) {
                this.defaultLineColorI = this.subjects[this.subjectIndex].lineColor;
            }
        } else {
            switch (this.subjectIndex) {
                case 'All Tiles & Main':
                    this.subjects.forEach((subject) => {
                        delete subject.backgroundColor;
                        delete subject.borderColor;
                        delete subject.lineColor;
                    });
                    this.mainBackgroundColor = this.tilesBackgroundColor;
                    this.mainBorderColor = this.tilesBorderColor;
                    break;
                case 'All Tiles (reset)':
                    this.tilesBackgroundColorEven = this.tilesBackgroundColor;
                    this.tilesBackgroundColorOdd = this.tilesBackgroundColor;
                    this.tilesBorderColorEven = this.tilesBorderColor;
                    this.tilesBorderColorOdd = this.tilesBorderColor;
                    this.subjects.forEach((subject, index) => {
                        if (index) {
                            delete subject.backgroundColor;
                            delete subject.borderColor;
                            delete subject.lineColor;
                        }
                    });
                    break;
                case 'Unchanged Tiles':
                    // already works as is for unchanged tiles
                    break;
                case 'Unchanged Tiles & Main':
                    // already works as is for unchanged tiles
                    this.mainBackgroundColor = this.tilesBackgroundColor;
                    this.mainBorderColor = this.tilesBorderColor;
                    this.tilesBackgroundColorEven = this.tilesBackgroundColor;
                    this.tilesBackgroundColorOdd = this.tilesBackgroundColor;
                    this.tilesBorderColorEven = this.tilesBorderColor;
                    this.tilesBorderColorOdd = this.tilesBorderColor;
                    break;
                case 'Even Tiles':
                    this.tilesBackgroundColorEven = (this.tilesBackgroundColorEven) ? this.tilesBackgroundColorEven : this.tilesBackgroundColor;
                    this.tilesBorderColorEven = (this.tilesBorderColorEven) ? this.tilesBorderColorEven : this.tilesBorderColor;
                    this.defaultLineColorEven = (this.defaultLineColorEven) ? this.defaultLineColorEven : this.defaultLineColor;
                    this.subjects.forEach((subject, index) => {
                        if (index && !(index % 2)) {
                            subject.backgroundColor = this.tilesBackgroundColorEven;
                            subject.borderColor = this.tilesBorderColorEven;
                            subject.lineColor = this.defaultLineColorEven;
                        }
                    });
                    break;
                case 'Odd Tiles':
                    this.tilesBackgroundColorOdd = (this.tilesBackgroundColorOdd) ? this.tilesBackgroundColorOdd : this.tilesBackgroundColor;
                    this.tilesBorderColorOdd = (this.tilesBorderColorOdd) ? this.tilesBorderColorOdd : this.tilesBorderColor;
                    this.defaultLineColorOdd = (this.defaultLineColorOdd) ? this.defaultLineColorOdd : this.defaultLineColor;
                    this.subjects.forEach((subject, index) => {
                        if (!index && index % 2) {
                            subject.backgroundColor = this.tilesBackgroundColorOdd;
                            subject.borderColor = this.tilesBorderColorOdd;
                            subject.lineColor = this.defaultLineColorOdd;
                        }
                    });
                    break;
            }
        }
        this.draw();
    }


    changedTileColor() {
        this.currentSaved = false;
        if (this.subjectIndex > 0) {
            this.subjects[this.subjectIndex].backgroundColor = this.tilesBackgroundColorI;
            this.subjects[this.subjectIndex].borderColor = this.tilesBorderColorI;
            this.subjects[this.subjectIndex].lineColor = this.defaultLineColorI;
        } else {
            switch (this.subjectIndex) {
                case 'All Tiles & Main':
                    this.subjects.forEach((subject) => {
                        delete subject.backgroundColor;
                        delete subject.borderColor;
                        delete subject.lineColor;
                    });
                    this.mainBackgroundColor = this.tilesBackgroundColor;
                    this.mainBorderColor = this.tilesBorderColor;
                    break;
                case 'All Tiles (reset)':
                    this.subjects.forEach((subject, index) => {
                        if (index) {
                            delete subject.backgroundColor;
                            delete subject.borderColor;
                            delete subject.lineColor;
                        }
                    });
                    break;
                case 'Unchanged Tiles':
                    // already works as is for unchanged tiles
                    break;
                case 'Unchanged Tiles & Main':
                    // already works as is for unchanged tiles
                    this.mainBackgroundColor = this.tilesBackgroundColor;
                    this.mainBorderColor = this.tilesBorderColor;
                    break;
                case 'Even Tiles':
                    this.subjects.forEach((subject, index) => {
                        if (index && !(index % 2)) {
                            subject.backgroundColor = this.tilesBackgroundColorEven;
                            subject.borderColor = this.tilesBorderColorEven;
                            subject.lineColor = this.defaultLineColorEven;
                        }
                    });
                    break;
                case 'Odd Tiles':
                    this.subjects.forEach((subject, index) => {
                        if (index && index % 2) {
                            subject.backgroundColor = this.tilesBackgroundColorOdd;
                            subject.borderColor = this.tilesBorderColorOdd;
                            subject.lineColor = this.defaultLineColorOdd;
                        }
                    });
                    break;
            }
        }
        this.draw();
    }


    changedTextLayerColor() {
        this.currentSaved = false;
        this.layers[this.layerIndex].color = this.textLayerColor;
        this.draw();
    }


    selectedLabelOption() {
        if (this.subjectIndex > 0) {
            this.labelsBackgroundColorI = this.colorPresetBackground;
            this.labelsTextColorI = this.colorPresetColor;
            if (this.subjects[this.subjectIndex] && this.subjects[this.subjectIndex].labelBackgroundColor) {
                this.labelsBackgroundColorI = this.subjects[this.subjectIndex].labelBackgroundColor;
            }
            if (this.subjects[this.subjectIndex] && this.subjects[this.subjectIndex].labelTextColor) {
                this.labelsTextColorI = this.subjects[this.subjectIndex].labelTextColor;
            }
        } else {
            switch (this.subjectIndex) {
                case 'All Tiles & Main':
                    this.labelsBackgroundColorEven = this.colorPresetBackground;
                    this.labelsBackgroundColorOdd = this.colorPresetBackground;
                    this.labelsTextColorEven = this.colorPresetColor;
                    this.labelsTextColorOdd = this.colorPresetColor;
                    this.subjects.forEach((subject) => {
                        delete subject.labelBackgroundColor;
                        delete subject.labelTextColor;
                    });
                    this.mainColorPresetBackground = this.colorPresetBackground;
                    this.mainColorPresetColor = this.colorPresetColor;
                    break;
                case 'All Tiles (reset)':
                    this.labelsBackgroundColorEven = this.colorPresetBackground;
                    this.labelsBackgroundColorOdd = this.colorPresetBackground;
                    this.labelsTextColorEven = this.colorPresetColor;
                    this.labelsTextColorOdd = this.colorPresetColor;
                    this.subjects.forEach((subject, index) => {
                        if (index) {
                            delete subject.labelBackgroundColor;
                            delete subject.labelTextColor;
                        }
                    });
                    break;
                case 'Unchanged Tiles':
                    // already works as is for unchanged tiles
                    break;
                case 'Unchanged Tiles & Main':
                    // already works as is for unchanged tiles
                    this.mainColorPresetBackground = this.colorPresetBackground;
                    this.mainColorPresetColor = this.colorPresetColor;
                    break;
                case 'Even Tiles':
                    this.subjects.forEach((subject, index) => {
                        if (index && !(index % 2)) {
                            subject.labelBackgroundColor = this.labelsBackgroundColorEven;
                            subject.labelTextColor = this.labelsTextColorEven;
                        }
                    });
                    break;
                case 'Odd Tiles':
                    this.subjects.forEach((subject, index) => {
                        if (index && index % 2) {
                            subject.labelBackgroundColor = this.labelsBackgroundColorOdd;
                            subject.labelTextColor = this.labelsTextColorOdd;
                        }
                    });
                    break;
            }
        }
        this.draw();
    }


    changedLabelColor() {
        this.currentSaved = false;
        if (this.subjectIndex > 0) {
            this.subjects[this.subjectIndex].labelBackgroundColor = this.labelsBackgroundColorI;
            this.subjects[this.subjectIndex].labelTextColor = this.labelsTextColorI;
        } else {
            switch (this.subjectIndex) {
                case 'All Tiles & Main':
                    this.subjects.forEach((subject) => {
                        delete subject.labelBackgroundColor;
                        delete subject.labelTextColor;
                    });
                    this.mainColorPresetBackground = this.colorPresetBackground;
                    this.mainColorPresetColor = this.colorPresetColor;
                    break;
                case 'All Tiles (reset)':
                    this.subjects.forEach((subject, index) => {
                        if (index) {
                            delete subject.labelBackgroundColor;
                            delete subject.labelTextColor;
                        }
                    });
                    break;
                case 'Unchanged Tiles':
                    // already works as is for unchanged tiles
                    break;
                case 'Unchanged Tiles & Main':
                    // already works as is for unchanged tiles
                    this.mainColorPresetBackground = this.colorPresetBackground;
                    this.mainColorPresetColor = this.colorPresetColor;
                    break;
                case 'Even Tiles':
                    this.subjects.forEach((subject, index) => {
                        if (index && !(index % 2)) {
                            subject.labelBackgroundColor = this.labelsBackgroundColorEven;
                            subject.labelTextColor = this.labelsTextColorEven;
                        }
                    });
                    break;
                case 'Odd Tiles':
                    this.subjects.forEach((subject, index) => {
                        if (index && index % 2) {
                            subject.labelBackgroundColor = this.labelsBackgroundColorOdd;
                            subject.labelTextColor = this.labelsTextColorOdd;
                        }
                    });
                    break;
            }
        }
        this.draw();
    }


    setStarburstAlignment(align: string) {
        this.currentSaved = false;
        this.starburstAlignment = align;
        this.draw();
    }


    setMatSize(selectedSize: number) {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.matSize = selectedSize;
        this.projectService.matSizeTop = selectedSize;
        this.projectService.matSizeLeft = selectedSize;
        this.projectService.matSizeRight = selectedSize;
        this.projectService.matSizeBottom = selectedSize;
        if (selectedSize) {
            this.projectService.fold = '';
        } else {
            this.projectService.doubleMatSize = 0;
        }
        this.draw();
    }



    setMatSide() {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.matSizeTop = this.projectService.matSizeTop || this.projectService.matSize;
        this.projectService.matSizeLeft = this.projectService.matSizeLeft || this.projectService.matSize;
        this.projectService.matSizeRight = this.projectService.matSizeRight || this.projectService.matSize;
        this.projectService.matSizeBottom = this.projectService.matSizeBottom || this.projectService.matSize;
        this.projectService.matSize = -1;
        this.draw();
    }



    setDoubleMatSize(selectedSize: number) {
        this.currentSaved = false;
        this.projectService.doubleMatSize = selectedSize;
        this.matService.drawDoubleMat(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.frameService.draw(this, this.svg, this.dpi, this.centerX, this.centerY,false);
    }



    setMatColor(selectedColor: string) {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.matColor = selectedColor;
        this.matService.draw(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.matService.drawDoubleMat(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.frameService.draw(this, this.svg, this.dpi, this.centerX, this.centerY,false);
    }



    setDoubleMatColor(selectedColor: string) {
        this.currentSaved = false;
        this.posterActive = false;
        this.projectService.doubleMatColor = selectedColor;
        this.matService.draw(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.matService.drawDoubleMat(this, this.svg, this.dpi, this.centerX, this.centerY, false);
        this.frameService.draw(this, this.svg, this.dpi, this.centerX, this.centerY,false);
    }



    drawPaper() {

        const g = this.svg.append('g').attr('id', 'paper');
        let width = this.projectService.paperWidth * this.dpi;
        let height = this.projectService.paperHeight * this.dpi;

        g.append('rect')
            .attr('width', width)
            .attr('height', height)
            .attr('stroke-width', 5)
            .attr('stroke', 'rgba(0,0,0,0.5)')
            .style('fill', 'white')
            .style('filter', 'url(#medium-shadow)');

        g.attr('transform', 'translate(' + (this.centerX - width / 2) + ', ' + (this.centerY - height / 2) + ')');
        g.style('opacity', () => (this.gridActive) ? 0.5 : 1);

        this.svg.append('defs')
            .append('clipPath')
            .attr('id', 'clip-path-paper')
            .attr('transform', 'translate(' + (this.centerX - width / 2) + ', ' + (this.centerY - height / 2) + ')')
            .append('rect')
            .attr('width', width)
            .attr('height', height);

    }


    draw() {

        if (!this.project) {
            return;
        }

        setTimeout(() => {

            const zoomRatio = this.zooms[this.zoom] * this.useRatio;

            this.host = this.d3.select(this.parentNativeElement);
            this.host.select('#svg').remove();
            this.width = this.parentNativeElement.offsetWidth / zoomRatio;
            this.height = this.parentNativeElement.offsetHeight / zoomRatio;
            this.centerX = this.width * 0.5;
            this.centerY = this.height * 0.5;

            let startX = 0;
            let startY = 0;

            this.svgParent = this.host.append('svg')
                .attr('id', 'svg')
                .attr('width', '100%')
                .attr('height', '100%')
                .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
                .call(this.d3.drag()
                    .on('start', () => {
                        startX = this.d3.event.x + this.rememberX;
                        startY = this.d3.event.y + this.rememberY;
                    })
                    .on('drag', () => {
                        if (this.poster) {
                            this.lastX = ((this.d3.event.x - startX) / zoomRatio);
                            this.lastY = ((this.d3.event.y - startY) / zoomRatio);
                            this.rememberX = startX - this.d3.event.x;
                            this.rememberY = startY - this.d3.event.y;
                            this.poster.attr('transform', 'translate(' + this.lastX + ',' + this.lastY + ')');
                        }
                    })
                    .on('end', () => {}));

            this.svg = this.svgParent.append('g').attr('id', 'poster');
            this.poster = this.svg;

            this.defs();
            this.drawPaper();

            this.studioService.setChartSpace(this, this.dpi, this.centerX, this.centerY);
            this[this.type.charAt(0).toLowerCase() + this.type.slice(1) + 'Service'].draw(this, this.svg, this.dpi, false);
            this.titleService.draw(this, this.poster, this.centerX, this.centerY, false);
            this.layersService.draw(this, this.poster, this.centerX, this.centerY, false);
            this.matService.draw(this, this.svg, this.dpi, this.centerX, this.centerY, false);
            this.matService.drawDoubleMat(this, this.svg, this.dpi, this.centerX, this.centerY, false);
            this.frameService.draw(this, this.svg, this.dpi, this.centerX, this.centerY, false);
            this.gridService.draw(this);
            if (this.lastX && this.lastY) {
                this.poster.attr('transform', 'translate(' + this.lastX + ',' + this.lastY + ')');
            }
            this.setExportSizes();
            this.setPrintPrices();
        }, 0);
    }


    setExportSizes() {
        const fullWidth = (this.projectService.frameSize) ? this.projectService.paperWidth + (this.projectService.frameSize * 2) - 0.5 : this.projectService.paperWidth;
        const fullHeight = (this.projectService.frameSize) ? this.projectService.paperHeight + (this.projectService.frameSize * 2) - 0.5 : this.projectService.paperHeight;
        if (fullWidth > fullHeight) {
            this.exportWidth = this.exportService.maxSide;
            this.exportHeight = fullHeight * (this.exportService.maxSide / fullWidth);
        }
        else {
            this.exportWidth = fullWidth * (this.exportService.maxSide / fullHeight);
            this.exportHeight = this.exportService.maxSide;
        }
        this.exportWidth = Math.round(this.exportWidth);
        this.exportHeight = Math.round(this.exportHeight);
    }


    drawPrint() {

        this.d3.select('#svg-print').remove();

        // Clear out old, just so we're not double loading everything for no reason.
        this.host = this.d3.select(this.parentNativeElement);
        this.host.select('#svg').remove();

        this.width = this.projectService.paperWidth;
        this.height = this.projectService.paperHeight;
        this.centerX = 0;
        this.centerY = 0;

        this.d3.select('body')
            .style('width', (10 + (this.width * 96)) + 'px')
            .style('height', (10 + (this.height * 96)) + 'px');

        this.lastZoom = this.zoom;
        this.zoom = 1;
        this.svg = this.d3.select('body').append('svg')
            .attr('id', 'svg-print')
            .attr('width', (this.width * 96) + 'px')
            .attr('height', (this.height * 96) + 'px')
            .attr('viewBox', -(this.width * this.dpi / 2) + ' ' +
                -(this.height * this.dpi / 2) + ' ' + (this.width * this.dpi) + ' ' + (this.height * this.dpi))
            .style('outline', '1px dashed lightgray');

        this.svg = this.svg.append('g').attr('id', 'poster');
        this.poster = this.svg;
        this.drawPaper();
        this.d3.select('#paper > rect').style('filter', '').style('stroke-width', '0');
        this.studioService.setChartSpace(this, this.dpi, this.centerX, this.centerY);
        this[this.type.charAt(0).toLowerCase() + this.type.slice(1) + 'Service'].draw(this);
        this.titleService.draw(this, this.poster, this.centerX, this.centerY, false);
    }



    sliderChange(event) {
        this.currentSaved = false;
        if (event.id === 'offsetScale'
            || event.id === 'offsetX'
            || event.id === 'offsetY') {
            this.subjects[this.subjectIndex][event.id] = event.value;
        } else if (event.id === 'layerSize'
            || event.id === 'layerEnlarge'
            || event.id === 'rotate'
            || event.id === 'layerX'
            || event.id === 'layerY'
            || event.id === 'lineHeight') {
            this.layers[this.layerIndex][event.id] = event.value;
        } else {
            this[event.id] = event.value;
        }
        this.draw();
    }



    addSubject() {
        if (this.subjects.length <= this.maxSubjects[this.type]) {
            this.currentSaved = false;
            this.subjects.push({
                id: undefined,
                bold1: false,
                bold2: false,
                italic1: false,
                italic2: false,
                underline1: false,
                underline2: false,
                originalImageWidth: 0,
                originalImageHeight: 0,
                offsetScale: 1,
                offsetX: 0,
                offsetY: 0,
                index: this.subjects.length,
                x: 0,
                y: 0,
                backgroundColor: '',
                borderColor: '',
                labelBackgroundColor: '',
                labelTextColor: '',
                lineColor: '',
                line1: '',
                line1Font: '',
                line2: '',
                line2Font: '',
                scale: 1,
                skip: false,
                hideLabel: false,
                background: this.defaultBackgroundColor,
                border: this.defaultBorderColor,
                connector: this.defaultLineColor
            });
        }
        if (this.type === 'Starburst') {
            this.draw();
        } else {
            this.resetSubjectsIndexes();
        }
    }


    resetSubjectsIndexes() {
        if (this.type !== 'Starburst') {
            while (this.rows * this.cols < this.subjects.length - 1) {
                this.subjects.pop();
            }
            while (this.rows * this.cols > this.subjects.length - 1) {
                this.addSubject();
            }
        }
        this.subjects.map((subject, index) => {
            subject.index = index;
        });
        this.subjectIndex = undefined;
        this.draw();
    }


    removeSubject() {
        this.currentSaved = false;
        this.subjects.pop();
        this.resetSubjectsIndexes();
        this.draw();
    }


    minusRows() {
        if (this.rows) {
            this.rows--;
            this.currentSaved = false;
            this.resetSubjectsIndexes();
            if (!this.rows) {
                this.anchorSize = 1;
            }
            this.draw();
        }
    }

    plusRows() {
        if (this.rows < 12) {
            if (this.cols && !this.rows && (!this.anchorSize || this.anchorSize === 1)) {
                this.anchorSize = 0.5;
            }
            this.rows++;
            this.currentSaved = false;
            this.resetSubjectsIndexes();
            this.draw();
        }
    }

    minusCols() {
        if (this.cols) {
            this.cols--;
            this.currentSaved = false;
            this.resetSubjectsIndexes();
            if (!this.cols) {
                this.anchorSize = 1;
            }
            this.draw();
        }
    }

    plusCols() {
        if (this.cols < 12) {
            if (!this.cols && this.rows && (!this.anchorSize || this.anchorSize === 1)) {
                this.anchorSize = 0.5;
            }
            this.cols++;
            this.currentSaved = false;
            this.resetSubjectsIndexes();
            this.draw();
        }
    }


    cycleSubjects() {
        this.currentSaved = false;
        // if (this.type === 'Starburst') {
        this.subjects.splice(1, 0, this.subjects.pop());
        // } else {
        //   this.subjects.unshift(this.subjects.pop());
        // }
        this.resetSubjectsIndexes();
        this.draw();
    }


    reverseSubjects() {
        this.currentSaved = false;
        // if (this.type === 'Starburst') {
        this.subjects.reverse();
        this.subjects.unshift(this.subjects.pop());
        // } else {
        //   this.subjects.reverse();
        // }
        this.resetSubjectsIndexes();
        this.draw();
    }


    randomizeSubjects() {
        this.currentSaved = false;
        // if (this.type === 'Starburst') {
        const first = this.subjects.shift();
        for (let i = this.subjects.length - 1; i > 1; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.subjects[i], this.subjects[j]] = [this.subjects[j], this.subjects[i]];
        }
        this.subjects.unshift(first);
        this.resetSubjectsIndexes();
        this.draw();
    }


    rotateAlignment() {
        this.currentSaved = false;
        if (this.type === 'Starburst') {
            this.chartRotation++;
            if (this.chartRotation >= 4) {
                this.chartRotation = 0;
            }
        } else {
        }
        this.draw();
    }


    removeImage() {
        this.currentSaved = false;
        this.subjects[this.subjectIndex].id = undefined;
        this.draw();
        this.d3.select('#image-' + this.subjectIndex).style('outline', '25px dashed magenta');
    }


    setFont(fontLine: string, goLeft: boolean) {
        this.currentSaved = false;
        if (goLeft) {
            this[fontLine]--;
            if (this[fontLine] < 0) {
                this[fontLine] = this.fonts.length - 1;
            }
        }
        else {
            this[fontLine]++;
            if (this[fontLine] >= this.fonts.length) {
                this[fontLine] = 0;
            }
        }
        this[fontLine.replace('Index', '')] = this.fonts[this[fontLine]].id;
        this.draw();
    }


    setLayerFont(layerIndex: number, goLeft: boolean) {
        this.currentSaved = false;
        if (goLeft) {
            this.layers[layerIndex].fontIndex--;
            if (this.layers[layerIndex].fontIndex < 0) {
                this.layers[layerIndex].fontIndex = this.fonts.length - 1;
            }
        }
        else {
            this.layers[layerIndex].fontIndex++;
            if (this.layers[layerIndex].fontIndex >= this.fonts.length) {
                this.layers[layerIndex].fontIndex = 0;
            }
        }
        this.layers[layerIndex].font = this.fonts[this.layers[layerIndex].fontIndex].id;
        this.draw();
    }


    // print() {
    //     this.drawPrint();
    //     window.print();
    //     this.zoom = this.lastZoom;
    //     this.draw();
    // }



    webDownload() {
        this.exportService.drawWeb(this);
    }



    hiResDownload(paperWidth, paperHeight) {
        if (this.hiResSpinner) {
            return;
        }
        this.hiResSpinner = true;
        this.studioService
            .addExportsQueue(this.project.id)
            .subscribe(res => {
                this.studioService.getExports(this);
                this.hiResSpinner = false;
            });
    }



    changePaperType(type: string) {

        if (this.projectService.fold && type !== 'Matte') {
            return;
        }

        this.paperType[this.selectedPaperType] = false;
        this.paperType[type] = true;
        this.selectedPaperType = type;
    }



    addToCart() {

        let updatedQty = false;
        let changedIndex;

        this.printCart.forEach((item, index) => {
            if (item.projectId === this.project.id
                && item.paperType === this.selectedPaperType) {
                item.qty += +this.qty;
                updatedQty = true;
                changedIndex = index;
            }
        });

        if (updatedQty) {
            const item = this.printCart[changedIndex];
            this.printCart.splice(changedIndex, 1);
            this.printCart.unshift(item);
        } else {
            this.printCart.unshift({
                paperType: this.selectedPaperType,
                qty: +this.qty,
                paperWidth: this.projectService.paperWidth,
                paperHeight: this.projectService.paperHeight,
                projectId: this.project.id
            });
        }

        this.setPrintPrices();
    }



    removeFromCart (index: number) {
        this.printCart.splice(index, 1);
        this.setPrintPrices();

    }



    isShipToValid () {
        let disabled = true;
        if (this.shipToName && this.shipToName.length >= 2
            && this.shipToAddress1 && this.shipToAddress1.length >= 2
            && this.shipToCity && this.shipToCity.length >= 2
            && this.shipToState && this.shipToState.length >= 2
            && this.shipToZip && this.shipToZip.length >= 5) {
            disabled = false;
        }
        return disabled;
    }



    getShippingOptions() {
        this.shippingService
            .getShippingOptions(this.shipToZip)
            .subscribe(
                result => this.shippingOptions = result,
                error => this.shippingOptions = error
            );
    }



    payPrintOrder() {
        this.payModalService.openPrintOrderModal(
            'Print Order',
            this.printCart,
            this.subtotal,
            {
                name: this.shipToName,
                address1: this.shipToAddress1,
                address2: this.shipToAddress2,
                city: this.shipToCity,
                state: this.shipToState,
                zip: this.shipToZip,
                phone: this.shipToPhone
            },
            this.shippingOption,
            (this.subtotal + this.shippingSelectedRate).toFixed(2)
        );
        this.studioService.subject2.subscribe((isSuccess: boolean) => {
            this.receipt = isSuccess;
            this.studioService.getExports(this);
        });
    }



    toggleProjectsModal(modal) {
        if (modal === '') {
            this.newModal = false;
            this.openModal = false;
            this.downloadModal = false;
            this.printModal = false;
            this.shippingModal = false;
            this.transportModal = false;
            this.reviewModal = false;
        } else {
            this[modal] = !this[modal];
            if (modal !== 'newModal') {
                this.newModal = false;
            }
            if (modal !== 'openModal') {
                this.openModal = false;
            }
            if (modal !== 'downloadModal') {
                this.downloadModal = false;
            }
            if (modal !== 'printModal') {
                this.printModal = false;
            } else if (this.printModal) {
                this.setUseRatio();
                this.zoomClear();
                this.zoomOut(true);
                this.zoomOut(true);
            }
            this.shippingModal = false;
            this.transportModal = false;
            this.reviewModal = false;
        }

        if (this.receipt) {
            this.printCart = [];
        }
        this.receipt = false;
        this.resetRenames();
    }



    toggleOptionsMenu(modal) {
        if (modal === '') {
            this.sizingModal = false;
            this.infoPanel = false;
            this.framingModal = false;
            this.mattingModal = false;
            this.titleModal = false;
            this.anchorModal = false;
            this.floatingModal = false;
            this.chartModal = false;
        }
        else {
            this[modal] = !this[modal];
            if (modal !== 'sizingModal') {
                this.sizingModal = false;
            }
            if (modal !== 'infoPanel') {
                this.infoPanel = false;
            }
            if (modal !== 'framingModal') {
                this.framingModal = false;
            }
            if (modal !== 'mattingModal') {
                this.mattingModal = false;
            }
            if (modal !== 'titleModal') {
                this.titleModal = false;
            }
            if (modal !== 'anchorModal') {
                this.anchorModal = false;
            }
            if (modal !== 'floatingModal') {
                this.floatingModal = false;
            }
            if (modal !== 'chartModal') {
                this.chartModal = false;
            }
        }
        if (this.floatingModal) {
            this.subjectIndex = undefined;
        }
    }



    resetRenames() {
        let i = 0;
        this.projects.forEach(() => {
            i++;
            this.renameModal[i] = false;
        });
    }



    selectShipping(shippingOption: any) {
        if (this.subtotal > 40 && shippingOption.key === '1') {
            this.shippingSelectedRate = 0;
        } else {
            this.shippingSelectedRate = shippingOption.rate;
        }
    }



    applyPromo() {
        if (this.promoTry) {
            this.promoError = 'Sorry, that code is not available.';
        }
    }



    clearPromoError() {
        this.promoError = '';
    }



    setTab(modal, tab) {
        this.tabs[modal] = {
            layout: false,
            config: false,
            image: false,
            label: false,
            text: false
        };
        this.tabs[modal][tab] = true;
    }



    newImageLayer() {
        const newLayer = {
            id: '',
            originalImageWidth: 0,
            originalImageHeight: 0,
            name: 'Image Layer',
            layerEnlarge: 0,
            rotate: 0,
            layerType: 'image',
            layerSize: 1,
            layerX: 0,
            layerY: 0,
            border: 0,
            borderColor: 'rgba(0,0,0,1)',
            shape: 'rect'
        };
        this.layers.unshift(newLayer);
        this.layerIndex = 0;
    }



    newTextLayer() {
        const newLayer = {
            id: '',
            name: 'Text Layer',
            layerEnlarge: 0,
            rotate: 0,
            layerType: 'text',
            layerSize: 1,
            layerX: 0,
            layerY: 0,
            text: 'Enter\ntext\nhere.',
            align: 'center',
            lineHeight: 1,
            bold: 'false',
            italic: 'false',
            underline: 'false',
            font: 'Open Sans',
            fontIndex: 13,
            color: 'rgb(0,0,0)',
            ranges: []
        };
        this.textLayerColor = 'rgb(0,0,0)';
        this.layers.unshift(newLayer);
        this.layerIndex = 0;
    }



    removeLayer(layerIndex: number) {
        this.layers.splice(layerIndex, 1);
        if (layerIndex === this.layerIndex) {
            this.layerIndex = undefined;
        }
        else if (layerIndex < this.layerIndex) {
            this.layerIndex--;
        }
        this.currentSaved = false;
        this.draw();
    }



    moveLayerUp(layerIndex: number) {
        this.layers[layerIndex - 1] = this.layers.splice(layerIndex, 1, this.layers[layerIndex - 1])[0];
        if (layerIndex === this.layerIndex) {
            this.layerIndex--;
        } else if (layerIndex - 1 === this.layerIndex) {
            this.layerIndex++;
        }
        this.currentSaved = false;
        this.draw();
    }



    moveLayerDown(layerIndex: number) {
        this.layers[layerIndex + 1] = this.layers.splice(layerIndex, 1, this.layers[layerIndex + 1])[0];
        if (layerIndex === this.layerIndex) {
            this.layerIndex++;
        } else if (layerIndex + 1 === this.layerIndex) {
            this.layerIndex--;
        }
        this.currentSaved = false;
        this.draw();
    }


    addFaderClassToLayer(index) {
        this.svg.select('#layer' + (index + 1))
            .attr('class', 'selector-fader');
    }


    isQtyNotAnInteger(qty) {
        return !qty || +qty < 1 || +qty % 1 || qty.toString().match(/\D/) || qty.toString().match(/^0/);
    }



    deleteExport(id: string): void {
        this.studioService
            .deleteExport(id)
            .subscribe(res => {
                this.studioService.getExports(this); //todo: is fetching, but is it updating UI?
            });
    }



}
