import {Injectable} from '@angular/core';
import {ProjectService} from "./project.service";

@Injectable()
export class FrameService {

    constructor(private projectService: ProjectService) {
    }


    draw(that: any, svg: any, dpi: number, centerX: number, centerY: number, exporting: boolean) {

        const width = (this.projectService.paperWidth - 0.5) * dpi; // Half of this is the offset for what the frame will cover
        const height = (this.projectService.paperHeight - 0.5) * dpi;
        const thickness = this.projectService.frameSize * dpi;

        // svg.select('#glass-gradient').remove();
        svg.select('#frame-shadow').remove();
        svg.select('#frame').remove();
        svg.select('#outer').remove();
        svg.select('#window').remove();
        let g;

        if (this.projectService.frameSize) {

            // this.drawGlass(that);

            g = svg.append('g').attr('id', 'frame-shadow').style('opacity', () => {
                if (that.posterActive) {
                    return 0;
                }
                return (that.gridActive) ? 0.3 : 1;
            });

            if (!exporting) {
                g.append('rect')
                    .attr('x', 14)
                    .attr('y', 14)
                    .attr('width', width + thickness * 2 - 28)
                    .attr('height', height + thickness * 2 - 28)
                    .attr('stroke-width', 30)
                    .attr('stroke', '#333')
                    .style('fill', 'none')
                    .style('filter', 'url(#large-shadow)');
                g.attr('transform', 'translate(' +
                    (centerX - width / 2 - thickness) + ', ' +
                    (centerY - height / 2 - thickness) + ')');
            }

            g = svg.append('g').attr('id', 'window').style('opacity', () => {
                if (that.posterActive) {
                    return 0;
                }
                return (that.gridActive) ? 0.3 : 1;
            });
            g.append('rect')
                .attr('width', width)
                .attr('height', height)
                .attr('stroke-width', 0.02 * dpi)
                .attr('stroke', '#666')
                .style('fill', 'none')
                .style('filter', () => (exporting) ? 'url(#small-shadow-web)' : 'url(#small-shadow)');
            g.attr('transform', 'translate(' +
                (centerX - width / 2) + ', ' +
                (centerY - height / 2) + ')');

            g = svg.append('g').attr('id', 'frame').style('opacity', () => {
                if (that.posterActive) {
                    return 0;
                }
                return (that.gridActive) ? 0.3 : 1;
            });

            g.append('rect')
                .attr('width', width + thickness)
                .attr('height', height + thickness)
                .attr('stroke-width', thickness)
                .attr('stroke', this.projectService.frameColor)
                .style('fill', 'none');



            let div = 2;
            switch (this.projectService.frameShape) {
                case 'full-bevel':
                    div = 2.2;
                    break;
                case 'inner-bevel':
                    div = 3;
                    break;
                case 'rounded':
                    div = 2;
                    break;
                case 'round':
                    div = 2;
                    break;
            }
            const points = {
                top: [],
                left: [],
                right: [],
                bottom: []
            };
            let offset = thickness / div;

            points.top.push(-offset + ',' + -offset);
            points.top.push(width + thickness + offset + ',' + -offset);
            points.top.push(width + thickness - offset + ',' + offset);
            points.top.push(offset + ',' + offset);

            points.bottom.push(-offset + ',' + (height + thickness + offset));
            points.bottom.push(width + thickness + offset + ',' + (height + thickness + offset));
            points.bottom.push(width + thickness - offset + ',' + (height + thickness - offset));
            points.bottom.push(offset + ',' + (height + thickness - offset));

            points.left.push(-offset + ',' + -offset);
            points.left.push(offset + ',' + offset);
            points.left.push(offset + ',' + (height + thickness - offset));
            points.left.push(-offset + ',' + (height + thickness + offset));

            points.right.push(width + thickness + offset + ',' + -offset);
            points.right.push(width + thickness + offset + ',' + (height + thickness + offset));
            points.right.push(width + thickness - offset + ',' + (height + thickness - offset));
            points.right.push(width + thickness - offset + ',' + offset);

            let orientation;
            ['top', 'left', 'bottom', 'right'].forEach((key, index) => {
                switch (this.projectService.frameShape) {
                    case 'full-bevel':
                        orientation = (index % 2) ? 'vertical' : 'horizontal';
                        break;
                    case 'inner-bevel':
                        orientation = (index % 2) ? 'vertical' : 'horizontal';
                        break;
                    case 'rounded':
                        orientation = (index % 2) ? 'horizontal' : 'vertical';
                        break;
                    case 'round':
                        orientation = (index % 2) ? 'horizontal' : 'vertical';
                        break;
                }
                g.append('polygon')
                    .attr('points', () => {
                        return points[key].join(' ');
                    })
                    .style('fill', 'url(#' + orientation + '-frame-gradient)');
            });

            let defs = svg.append('defs');
            defs = defs.append('linearGradient')
                .attr('id', 'horizontal-frame-gradient')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%');
            defs.append('stop')
                .attr('offset', '0%')
                .style('stop-color', 'rgba(0, 0, 0, 0.12)');
            if (this.projectService.frameShape === 'rounded') {
                defs.append('stop')
                    .attr('offset', '100%')
                    .style('stop-color', 'rgba(255, 255, 255, 0.12)');
            }
            else {
                defs.append('stop')
                    .attr('offset', '50%')
                    .style('stop-color', 'rgba(255, 255, 255, 0.22)');
                defs.append('stop')
                    .attr('offset', '100%')
                    .style('stop-color', 'rgba(0, 0, 0, 0.12)');
            }
            defs = defs.append('linearGradient')
                .attr('id', 'vertical-frame-gradient')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '0%')
                .attr('y2', '100%');
            defs.append('stop')
                .attr('offset', '0%')
                .style('stop-color', 'rgba(255, 255, 255, 0.22)');
            if (this.projectService.frameShape === 'rounded') {
                defs.append('stop')
                    .attr('offset', '100%')
                    .style('stop-color', 'rgba(0, 0, 0, 0.22)');
            }
            else {
                defs.append('stop')
                    .attr('offset', '50%')
                    .style('stop-color', 'rgba(0, 0, 0, 0.12)');
                defs.append('stop')
                    .attr('offset', '100%')
                    .style('stop-color', 'rgba(255, 255, 255, 0.22)');
            }








            g.attr('transform', 'translate(' +
                (centerX - width / 2 - thickness / 2) + ', ' +
                (centerY - height / 2 - thickness / 2) + ')');


// TODO - FINISH OFF NEW FRAME IMAGES...
//       g.append('defs').append('svg:pattern')
//         .attr('id', 'frame-1-top-image')
//         .attr('width', width)
//         .attr('height', thickness)
//         .attr('patternUnits', 'userSpaceOnUse')
//         .append('svg:image')
//         .attr('href', 'assets/frame-materials/frame-1-top.png')
//         .attr('width', width)
//         .attr('height', thickness)
//         .attr('x', -10)
//         .attr('y', -10);
//
//       g = this.svg.append('g').attr('class', 'frame-material').style('opacity', () => {
//         return (this.gridActive) ? '0.3' : '1';
//       });
//       const topPoints = {
//         topLeft: '0,0',
//         topRight: (width + thickness * 2) + ',0',
//         bottomLeft: thickness + ',' + thickness,
//         bottomRight: (width + thickness) + ',' + thickness
//       };
//       g.append('polygon')
//         .attr('points', topPoints.topLeft + ' ' + topPoints.topRight + ' ' + topPoints.bottomRight + ' ' + topPoints.bottomLeft)
//         .style('fill', 'url(#frame-1-top-image)');
//       g.attr('transform', 'translate(' +
//         (this.centerX - width / 2 - thickness) + ', ' +
//         (this.centerY - height / 2 - thickness) + ')');


        }

    }


    // drawGlass(that) {
    //   const g = svg.append('g');
    //   const width = (this.projectService.paperWidth - 0.5) * that.dpi;
    //   const height = (this.projectService.paperHeight - 0.5) * that.dpi;
    //   g.append('rect').attr('id', 'glass')
    //     .attr('width', width)
    //     .attr('height', height)
    //     .style('fill', 'url(#glass-gradient)');
    //   g.attr('transform', 'translate(' + (that.centerX - width / 2) + ', ' + (that.centerY - height / 2) + ')');
    // }


    // drawFrame() {
    //   const width = (this.projectService.paperWidth - 0.5) * this.dpi; // Half of this is the offset for what the frame will cover
    //   const height = (this.projectService.paperHeight - 0.5) * this.dpi;
    //   const thickness = this.frameSize * this.dpi;
    //   this.svg.select('#glass-gradient').remove();
    //   this.svg.select('#frame-shadow').remove();
    //   this.svg.select('#frame').remove();
    //   this.svg.select('#outer').remove();
    //   this.svg.select('#window').remove();
    //   let g;
    //   if (this.frameSize) {
    //
    //     this.drawGlass();
    //
    //     g = this.svg.append('g').attr('id', 'frame-shadow').style('opacity', () => {
    //       return (this.gridActive) ? '0.3' : '1';
    //     });
    //
    //     g.append('rect')
    //       .attr('x', 14)
    //       .attr('y', 14)
    //       .attr('width', width + thickness * 2 - 28)
    //       .attr('height', height + thickness * 2 - 28)
    //       .attr('stroke-width', 30)
    //       .attr('stroke', '#333')
    //       .style('fill', 'none')
    //       .style('filter', 'url(#large-shadow)');
    //     g.attr('transform', 'translate(' + (this.centerX - width / 2 - thickness) + ', ' + (this.centerY - height / 2 - thickness) + ')');
    //
    //     g = this.svg.append('g').attr('id', 'window');
    //     g.append('rect')
    //       .attr('width', width)
    //       .attr('height', height)
    //       .attr('stroke-width', 5)
    //       .attr('stroke', '#666')
    //       .style('fill', 'none')
    //       .style('filter', 'url(#small-shadow)');
    //     g.attr('transform', 'translate(' + (this.centerX - width / 2) + ', ' + (this.centerY - height / 2) + ')');
    //
    //     g = this.svg.append('g').attr('id', 'frame').style('opacity', () => {
    //       return (this.gridActive) ? '0.3' : '1';
    //     });
    //     g.append('rect')
    //       .attr('width', width + thickness)
    //       .attr('height', height + thickness)
    //       .attr('stroke-width', thickness)
    //       .attr('stroke', this.frameColor)
    //       .style('fill', 'none');
    //     g.attr('transform', 'translate(' +
    //       (this.centerX - width / 2 - thickness / 2) + ', ' +
    //       (this.centerY - height / 2 - thickness / 2) + ')');
    //
    //   }
    // }
}
