import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {Subject} from 'rxjs';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import {EnvService} from "../env.service";

@Injectable({
  providedIn: 'root'
})
export class PayModalService {

    elements: Elements;
    card: StripeElement;
    elementsOptions: ElementsOptions = {};
    subject1 = new Subject<number>();
    active: boolean;
    amount: string;
    title: string;
    plan: string;
    discount: string;
    duration: string;
    project: string;
    dimensions: string;
    printCart: any[];
    subtotal: number;
    shipTo: {
        name: string,
        address1: string,
        address2: string,
        city: string,
        state: string,
        zip: string,
        phone: string
    };
    shippingOption: {
        key: string,
        rate: number,
        desc: string
    };

    constructor (private http: HttpClient,
                 private stripeService: StripeService,
                 private envService: EnvService) {}



    resetProperties() {
        this.title = null;
        this.printCart = null;
        this.subtotal = null;
        this.shipTo = null;
        this.shippingOption = null;
        this.amount = null;
        this.plan = null;
        this.duration = null;
        this.discount = null;
        this.project = null;
        this.dimensions = null;
    };



    public openPrintOrderModal (title, printCart, subtotal, shipTo, shippingOption, total) {
        this.resetProperties();
        this.title = title;
        this.printCart = printCart;
        this.subtotal = subtotal;
        this.shipTo = shipTo;
        this.shippingOption = shippingOption;
        this.amount = total;
        this.openModal();
    }



    public openSubscriptionModal(amount, plan, title, duration, project, dimensions) {
        this.resetProperties();
        this.amount = amount;
        this.plan = plan;
        this.title = title;
        this.duration = duration;
        this.project = project;
        this.dimensions = dimensions;
        this.active = true;
        this.openModal();
    }



    openModal() {
        this.active = true;
        this.stripeService
            .elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                this.card = this.elements.create('card', {
                    style: {
                        base: {
                            iconColor: 'dimgray',
                            color: 'dimgray',
                            lineHeight: '40px',
                            fontWeight: 'normal',
                            fontFamily: 'sans-serif',
                            fontSize: '16px',
                            '::placeholder': {
                                color: 'lightgray'
                            }
                        }
                    }
                });
                this.card.mount('#card-element');
            });
    }



    post(body) {
        const token = Cookie.get('token');
        if (token) {
            body.metadata = {
                token: token,
                amount: this.amount,
                plan: this.plan,
                project: this.project,
                dimensions: this.dimensions,
                title: this.title,
                printCart: this.printCart,
                subtotal: this.subtotal,
                shippingOption: this.shippingOption,
                shipTo: this.shipTo,
                discount: 'winter30'
            };
            const ifCart = (this.printCart) ? '-prints' : '';
            const ifExport = (this.plan === '200') ? '-export' : '';
            return this.http.post(this.envService.url + 'charge' + ifCart + ifExport, body);
        }
    }



}
