import {Component, OnInit, HostBinding} from '@angular/core';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
    @HostBinding('class') classes = 'fade-in';

    constructor(private analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        this.analyticsService.record('contact');
    }

}
