export const diyData = {
    skills: [
        {
            backgroundTitle: 'Mount',
            description: 'How to mount your pictures.',
            id: 'photo-mount',
            linkName: 'Photo Mount'
        },
        {
            backgroundTitle: 'Pre-cut Mat',
            description: 'Create a photographic product using pre-cut matting.',
            id: 'pre-cut-matting',
            linkName: 'Pre-cut Matting'
        },
        {
            backgroundTitle: 'Matting',
            description: 'How to cut your own matting.',
            id: 'matting',
            linkName: 'Cut Matboard'
        },
        {
            backgroundTitle: 'Frame',
            description: 'Design a layout for an existing frame.',
            id: 'frame',
            linkName: 'Use an Existing Frame'
        }
    ],
    projects: [
        {
            backgroundTitle: 'Ads',
            id: 'ads',
            description: 'Create advertisements.'
        },
        {
            backgroundTitle: 'Architecture',
            id: 'architecture',
            description: 'Create a print using architecture.'
        },
        {
            backgroundTitle: 'Collections',
            id: 'samples',
            description: 'Organize a collection of something.'
        },
        {
            backgroundTitle: 'Door',
            id: 'door',
            description: 'Create an office door sign.'
        },
        {
            backgroundTitle: 'Employee',
            id: 'employee',
            description: 'Employee of the month!'
        },
        {
            backgroundTitle: 'Family',
            id: 'family',
            description: 'Add a title to a family picture.'
        },
        {
            backgroundTitle: 'Fine Art',
            id: 'art',
            description: 'Create a fine art print.'
        },
        {
            backgroundTitle: 'Food',
            id: 'recipe',
            description: 'Decorate your kitchen with your favorite recipes!'
        },
        {
            backgroundTitle: 'Glass',
            id: 'glass',
            description: 'Floating glass.'
        },
        {
            backgroundTitle: 'Greeting Cards',
            id: 'cards',
            description: 'Design your own Greeting Cards.'
        },
        {
            backgroundTitle: 'Landscape',
            id: 'landscape',
            description: 'Add a title to your landscape photography.'
        },
        {
            backgroundTitle: 'Large',
            id: 'large',
            description: 'Go big!'
            // description: 'How to prepare really large files for upload.'
        },
        {
            backgroundTitle: 'Missing',
            id: 'missing',
            description: 'Create a missing person or pet sign.'
        },
        {
            backgroundTitle: 'Opacity',
            id: 'opacity',
            description: 'Use semi-transparent labels.'
        },
        {
            backgroundTitle: 'Places',
            id: 'place',
            description: 'Capture and title a favorite place.'
        },
        {
            backgroundTitle: 'Real Estate',
            id: 'house',
            description: 'Create a real estate brochure or sign.'
        },
        {
            backgroundTitle: 'Sell',
            id: 'sell',
            description: 'Sell something.'
        },
        {
            backgroundTitle: 'Sidewalk',
            id: 'sidewalk',
            description: 'Promotional sidewalk signs.'
        },
        {
            backgroundTitle: 'Starburst',
            id: 'starburst',
            description: 'Create a unique starburst design.'
        },
        {
            backgroundTitle: 'Wake',
            id: 'wake',
            description: 'Remember a loved one.'
        },
        {
            backgroundTitle: 'Wedding',
            id: 'wedding',
            description: 'Use a starburst for wedding or engagement pictures.'
        },
        {
            backgroundTitle: 'Zooms',
            id: 'zooms',
            description: 'Show something at different zoom levels.'
        },
        // {
        //     backgroundTitle: 'Anniversary',
        //     id: 'anniversary',
        //     description: '..'
        // },
        // {
        //     backgroundTitle: 'Baby',
        //     id: 'door',
        //     description: 'Capture your baby\'s development.'
        // },
        // {
        //     backgroundTitle: 'Before & After',
        //     id: 'door',
        //     description: 'results'
        // },
        // {
        //     backgroundTitle: 'Birthday',
        //     id: 'birthday',
        //     description: '..'
        // },
        // {
        //     backgroundTitle: 'Christmas',
        //     id: 'christmas',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Cocktails',
        //     id: 'cocktails',
        //     description: 'Create a cocktail recipe.'
        // },
        // {
        //     backgroundTitle: 'Comics',
        //     id: 'comic',
        //     description: 'Create a comic strip.'
        // },
        // {
        //     backgroundTitle: 'Compare',
        //     id: 'door',
        //     description: 'compare'
        // },
        // {
        //     backgroundTitle: 'Directions',
        //     id: 'directions',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Directory',
        //     id: 'directory',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Engagement',
        //     id: 'door',
        //     description: 'Design a starburst layout using your wedding or engagement pictures.'
        // },
        // {
        //     backgroundTitle: 'Tree',
        //     id: 'tree',
        //     description: 'A family tree.'
        // },
        // {
        //     backgroundTitle: 'Frame',
        //     id: 'door',
        //     description: 'Use an existing frame. Try different pictures inside of it before you print.'
        // },
        // {
        //     backgroundTitle: 'Garage Sale',
        //     id: 'garage',
        //     description: '..'
        // },
        // {
        //     backgroundTitle: 'Guest',
        //     id: 'door',
        //     description: 'Create a print for a guest or speaker.'
        // },
        // {
        //     backgroundTitle: 'Halloween',
        //     id: 'halloween',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Instructions',
        //     id: 'door',
        //     description: 'Create an instructional poster.'
        // },
        // {
        //     backgroundTitle: 'Kids',
        //     id: 'door',
        //     description: 'Track your kids\' growth.'
        // },
        // {
        //     backgroundTitle: 'Lobby',
        //     id: 'lobby',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Love',
        //     id: 'love',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Matted',
        //     id: 'door',
        //     description: 'Create photographic products with pre-cut mats.'
        // },
        // {
        //     backgroundTitle: 'Meme',
        //     id: 'meme',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Political',
        //     id: 'political',
        //     description: '..'
        // },
        // {
        //     backgroundTitle: 'Pregnancy',
        //     id: 'door',
        //     description: 'Watch that belly grow!'
        // },
        // {
        //     backgroundTitle: 'Product',
        //     id: 'door',
        //     description: 'Create a print featuring a product.'
        // },
        // {
        //     backgroundTitle: 'Reference',
        //     id: 'door',
        //     description: 'Create a reference poster with labels.'
        // },
        // {
        //     backgroundTitle: 'Seasons',
        //     id: 'seasons',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Selfie',
        //     id: 'selfie',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Selfies',
        //     id: 'door',
        //     description: 'Make selfie strip!'
        // },
        // {
        //     backgroundTitle: 'Sports',
        //     id: 'sports',
        //     description: '...'
        // },
        // {
        //     backgroundTitle: 'Story',
        //     id: 'door',
        //     description: 'Tell a story visually.'
        // },
        // {
        //     backgroundTitle: 'Teach',
        //     id: 'door',
        //     description: 'Create a teaching aide.'
        // },
        // {
        //     backgroundTitle: 'Team',
        //     id: 'team',
        //     description: '..'
        // },
        // {
        //     backgroundTitle: 'Texting',
        //     id: 'texting',
        //     description: 'Export for texting.'
        // },
        // {
        //     backgroundTitle: 'Timeline',
        //     id: 'door',
        //     description: 'Share the progress of something.'
        // },
        // {
        //     backgroundTitle: 'Tourist',
        //     id: 'door',
        //     description: 'Create your own tourist posters.'
        // },
        // {
        //     backgroundTitle: 'Try',
        //     id: 'door',
        //     description: 'Not sure which frame to use for a picture that you want to print? Upload your picture and try it in different sizes to see which is best.'
        // },
        // {
        //     backgroundTitle: 'Valentine\'s Day',
        //     id: 'valentine',
        //     description: '...'
        // }
    ]
};
