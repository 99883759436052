import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {EnvService} from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    constructor(private httpClient: HttpClient,
                private envService: EnvService) {
    }

    record(resource: string) {
        if (window.location.hostname === 'instantlayout.com') {
            this.sendRecord(resource).subscribe();
        }
    }

    sendRecord(resource: string): Observable<any> {
        return this.httpClient.post(this.envService.url + 'analytics', { resource: resource });
    }

}
