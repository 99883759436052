import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {Subject} from 'rxjs/Subject';
import {EnvService} from "../env.service";

@Injectable()
export class UserService {

    loggedIn: boolean;
    user: string;
    verifiesEmail: string;
    verifiesCode: string;
    verifiesResetting: boolean;
    token: string;
    sessionTimer: any;
    sessionEnd: any;
    modalState: boolean;
    private subject1 = new Subject<number>();

    constructor (private router: Router,
                 private http: HttpClient,
                 private envService: EnvService) {
        this.token = Cookie.get('token');
    }

    passSessionModal (state: number) {
        this.subject1.next(state);
    }

    clearLogin () {
        this.loggedIn = false;
        this.user = '';
        this.token = '';
        this.modalState = false;
        Cookie.delete('token');
        if (this.router.url !== '/'
            && this.router.url !== '/home') {
            this.router.navigate(['/logout']);
        }
    }

    checkLogin () {
        const token = Cookie.get('token');
        if (token) {
            this.http.get(this.envService.url + 'check/' + token, {})
                .map(response => response)
                .subscribe(
                    response => {
                        if (response['passed']) {
                            Cookie.set('token', token);
                            this.setTimes();
                            this.loggedIn = true;
                            this.token = token;
                        }
                        else {
                            this.clearLogin();
                        }
                    }
                );

        }
        else {
            this.clearLogin();
        }
    }

    updateSession() {
        this.modalState = false;
        if (Cookie.get('token')) {
            this.http.post(this.envService.url + 'renew', {})
                .map(response => response)
                .subscribe(
                    response => {
                        if (response['passed']) {
                            this.setTimes();
                            this.passSessionModal(0);
                            Cookie.delete('token');
                        } else {
                            this.logout();
                        }
                    }
                );
        }
        else {
            this.clearLogin();
        }
    }

    login(token: string) {
        this.loggedIn = true;
        this.token = token;
        this.setTimes();
        Cookie.set('token', token);
        if (this.router.url === '/logout') {
            this.router.navigate(['/projects']);
        }
    }

    setTimes() {
        clearTimeout(this.sessionTimer);
        clearTimeout(this.sessionEnd);
        const self = this;
        this.sessionTimer = setTimeout(function () {
            self.passSessionModal(1);
            self.modalState = true;
        }, 60 * 1000 * 240); // 4 hours
        this.sessionEnd = setTimeout(function () {
            self.router.navigate(['/logout']);
        }, 60 * 1000 * 480); // 8 hours
    }

    logout() {
        this.loggedIn = false;
        clearTimeout(this.sessionTimer);
        clearTimeout(this.sessionEnd);
        if (Cookie.get('token')) {
            this.http.get(this.envService.url + 'logout', {})
                .subscribe(
                    response => {
                        Cookie.delete('token');
                        this.modalState = false;
                        this.token = '';
                    }
                );
        }
    }

}
