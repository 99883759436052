import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {EnvService} from "../env.service";

@Injectable()
export class PasswordService {
    constructor(private http: HttpClient,
                private envService: EnvService) {
    }


    checkPassword(email: string, code: string, resetting: boolean, password: string): Observable<any> {
        return this.http
            .post(this.envService.url + 'password', {
            email: email,
            code: code,
            resetting: resetting,
            password: password
        })
        .map(this.extractData)
        .catch(this.handleError);
    }


    private extractData(res: HttpResponse<any>) {
        let body = res;
        return body || {};
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }
}
