import {Component, OnInit} from '@angular/core';
import {EmailService} from "../../services/email.service";
import {AnalyticsService} from '../../analytics.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

    email: string;
    membership: string;
    message: string;
    spinner: boolean;

    constructor(private emailService: EmailService,
                private analyticsService: AnalyticsService) {
    }

    ngOnInit(): void {
        this.analyticsService.record('email');
    }


    updateMembership() {
        this.spinner = true;
        this.emailService.updateMembership(this.email, this.membership)
            .subscribe(
                result => this.processMembershipResult(result),
                error => this.processMembershipResult(error)
            );
    }



    processMembershipResult(result) {
        if (result && result.message) {
            this.message = result.message;
        }
        else if (result) {
            this.message = 'Done.';
        }
        this.spinner = false;
    }



}
