import {Injectable} from '@angular/core';

@Injectable()
export class TitleService {
    svg: any;
    centerX: number;
    centerY: number;
    exporting: boolean;
    lineRatio1: number;
    lineRatio2: number;
    textSpace: {
        x: number,
        y: number,
        centerX: number,
        centerY: number,
        width: number,
        height: number
    };
    line1Element: any;
    line2Element: any;

    constructor() {
    }



    draw(that: any, svg: any, centerX: number, centerY: number, exporting: boolean) {

        if (!that.titleText || !that.titleSize) {
            return;
        }

        this.svg = svg;
        this.centerX = centerX;
        this.centerY = centerY;
        this.exporting = exporting;

        this.drawTitleRect(that);
        this.setTextSpace(that);
        this.drawTitleText(that);
        this.scaleFontSizes(that);
        this.stackTextLines(that);
        this.positionText(that);
        this.offsetAlignment(that);
    }



    drawTitleRect(that: any) {

        that.title = this.svg.append('g')
            .attr('id', (this.exporting) ? 'title-rect-exporting' : 'title-rect')
            .attr('class', (that.titlePanel) ? 'selector-fader' : '')
            .style('opacity', () => (that.gridActive) ? 0.3 : 1);

        this.drawRect(that);

    }



    drawRect(that: any) {
        that.title.append('rect')
            .attr('x', that.titleRectX)
            .attr('y', that.titleRectY)
            .attr('width', that.titleRectW)
            .attr('height', that.titleRectH)
            .style('fill', that.titleColorPresetBackground)
            .style('cursor', 'pointer')
            .on('click', () => {
                if (!that.imageMoved) {
                    that.openTitleText();
                }
                that.imageMoved = false;
                that.d3.event.stopPropagation();
            });
    }



    setTextSpace(that: any) {

        let verticalMargin = 0;
        let horizontalMargin = that.titleWidth * (1 / 12);

        if (that.titleWidth > that.titleHeight) {
            verticalMargin = (that.titleHeight - (that.titleHeight / 1.5)) / 2;
        } else {
            verticalMargin = (that.titleWidth - (that.titleWidth / 1.5)) / 2;
        }

        this.textSpace = {
            x: that.titleX + horizontalMargin,
            y: that.titleY + verticalMargin,
            centerX: that.titleX + (that.titleWidth / 2),
            centerY: that.titleY + (that.titleHeight / 2),
            width: that.titleWidth - (horizontalMargin * 2),
            height: that.titleHeight - (verticalMargin * 2)
        };

        // if (that.titleText === 'left' || that.titleText === 'right') {
        //     if (that.titleWidth > that.titleHeight) {
        //         horizontalMargin = (that.titleHeight - (that.titleHeight / 1.5)) / 2;
        //     } else {
        //         horizontalMargin = (that.titleWidth - (that.titleWidth / 1.5)) / 2;
        //     }
        //     this.textSpace.x = that.titleX + horizontalMargin;
        //     this.textSpace.width = that.titleWidth - (horizontalMargin * 2);
        // }

    }



    drawTitleText(that: any) {

        let fillColor = that.titleColorPresetColor;
        let opacity = 1;

        if (fillColor.match(/rgba/)) {

            fillColor = fillColor.split(/\(/)[1];
            fillColor = fillColor.split(/\)/)[0];
            opacity = +fillColor.split(/,/)[3];
            const rgb = fillColor.split(/,/);
            rgb.pop();
            fillColor = 'rgb(' + rgb.join(',') + ')';
        }

        this.lineRatio1 = (1 - +that.titleFontSizes) * 10;
        this.lineRatio2 = +that.titleFontSizes * 10;

        this.line1Element = that.title.append('text')
            .text(that.titleTextLine1)
            .attr('id', (this.exporting) ? 'title-line-1-exporting' : 'title-line-1')
            .style('font-weight', () => that.titleTextLine1Bold ? 'bold' : 'normal')
            .style('font-style', () => that.titleTextLine1Italic ? 'italic' : 'normal')
            .style('text-decoration', () => that.titleTextLine1Underline ? 'underline' : '')
            .style('font-family', '\'' + that.fonts[that.defaultTitleFont1Index].id + '\', \'Deja Vu\'')
            .attr('text-anchor', () => {
                switch (that.titleTextPosition) {
                    case 'left':
                        return 'start';
                    case 'right':
                        return 'end';
                    default:
                        return 'middle';
                }
            })
            .attr('x', () => {
                // if (that.titleText === 'left') {
                //     return this.textSpace.centerX;
                // }
                // else if (that.titleText === 'right') {
                //     return this.textSpace.centerX;
                // }
                return this.textSpace.centerX;
            })
            .attr('y', () => {
                // if (that.titleText === 'top') {
                //     if (that.marginsBehavior === 'even-edge') {
                //         return this.textSpace.centerY;
                //     } else {
                //         return this.textSpace.centerY;
                //     }
                // }
                // else if (that.titleText === 'bottom') {
                //     if (that.marginsBehavior === 'even-edge') {
                //         return this.textSpace.centerY;
                //     } else {
                //         return this.textSpace.centerY;
                //     }
                // }
                return this.textSpace.centerY;
            })
            .style('cursor', 'pointer')
            .style('font-size', this.lineRatio1 + 'px')
            .style('fill', fillColor)
            .style('opacity', opacity)
            .attr('clip-path', 'url(#clip-title)')
            .on('click', () => {
                that.openTitleText();
            });

        this.line2Element = that.title.append('text')
            .text(that.titleTextLine2)
            .attr('id', (this.exporting) ? 'title-line-2-exporting' : 'title-line-2')
            .style('font-weight', () => that.titleTextLine2Bold ? 'bold' : 'normal')
            .style('font-style', () => that.titleTextLine2Italic ? 'italic' : 'normal')
            .style('text-decoration', () => that.titleTextLine2Underline ? 'underline' : '')
            .style('font-family', '\'' + that.fonts[that.defaultTitleFont2Index].id + '\', \'Deja Vu\'')
            // .attr('dominant-baseline', 'middle')
            .attr('text-anchor', () => {
                switch (that.titleTextPosition) {
                    case 'left':
                        return 'start';
                    case 'right':
                        return 'end';
                    default:
                        return 'middle';
                }
            })
            .attr('x', () => {
                // if (that.titleText === 'left') {
                //     return this.textSpace.centerX;
                // }
                // else if (that.titleText === 'right') {
                //     return this.textSpace.centerX;
                // }
                return this.textSpace.centerX;
            })
            .attr('y', () => {
                // if (that.titleText === 'top') {
                //     if (that.marginsBehavior === 'even-edge') {
                //         return this.textSpace.centerY;
                //     } else {
                //         return this.textSpace.centerY;
                //     }
                // }
                // else if (that.titleText === 'bottom') {
                //     if (that.marginsBehavior === 'even-edge') {
                //         return this.textSpace.centerY;
                //     } else {
                //         return this.textSpace.centerY;
                //     }
                // }
                return this.textSpace.centerY;
            })
            .style('cursor', 'pointer')
            .style('font-size', this.lineRatio2 + 'px')
            .style('fill', fillColor)
            .style('opacity', opacity)
            .attr('clip-path', 'url(#clip-title)')
            .on('click', () => {
                that.openTitleText();
            });

    }


    scaleFontSizes(that) {

        const
            textWidth1 = this.line1Element.node().getComputedTextLength(),
            textWidth2 = this.line2Element.node().getComputedTextLength(),
            largerLineWidth = (textWidth1 > textWidth2) ? textWidth1 : textWidth2,
            widthScale = this.textSpace.width / largerLineWidth;

        this.line1Element.style('font-size', (this.lineRatio1 * widthScale) + 'px');
        this.line2Element.style('font-size', (this.lineRatio2 * widthScale) + 'px');

        const
            textHeight1 = this.line1Element.node().getBBox().height,
            textHeight2 = this.line2Element.node().getBBox().height,
            totalTextHeight = textHeight1 + textHeight2,
            heightScale = this.textSpace.height / totalTextHeight;

        if (this.textSpace.height < totalTextHeight) {
            this.line1Element.style('font-size', (this.lineRatio1 * widthScale * heightScale * that.titleFontScale) + 'px');
            this.line2Element.style('font-size', (this.lineRatio2 * widthScale * heightScale * that.titleFontScale) + 'px');
        } else {
            this.line1Element.style('font-size', (this.lineRatio1 * widthScale * that.titleFontScale) + 'px');
            this.line2Element.style('font-size', (this.lineRatio2 * widthScale * that.titleFontScale) + 'px');
        }

    }


    stackTextLines(that) {

        const
            textHeight1 = this.line1Element.node().getBBox().height,
            textHeight2 = this.line2Element.node().getBBox().height,
            offset1 = (textHeight2 / 2),
            offset2 = (textHeight1 / 2),
            middleLine1 = textHeight1 / 4,
            middleLine2 = textHeight2 / 4;

        this.line1Element.attr('y', +this.line1Element.attr('y') - offset1 + middleLine1);
        this.line2Element.attr('y', +this.line2Element.attr('y') + offset2 + middleLine2);

    }


    positionText(that) {

        const
            textHeight1 = this.line1Element.node().getBBox().height,
            textHeight2 = this.line2Element.node().getBBox().height,
            half1 = textHeight1 / 2,
            half2 = textHeight2 / 2;

        switch (that.titleTextPosition) {
            case 'left':
                this.line1Element.attr('x', this.textSpace.x);
                this.line2Element.attr('x', this.textSpace.x);
                break;
            case 'right':
                this.line1Element.attr('x', () => this.textSpace.x + this.textSpace.width);
                this.line2Element.attr('x', () => this.textSpace.x + this.textSpace.width);
                break;
            case 'top':
                this.line1Element.attr('y', this.textSpace.y + half1);
                this.line2Element.attr('y', this.textSpace.y + textHeight1 + half2);
                break;
            case 'bottom':
                this.line1Element.attr('y', this.textSpace.y + this.textSpace.height - textHeight2 - half1);
                this.line2Element.attr('y', this.textSpace.y + this.textSpace.height - half2);
                break;
        }

    }


    offsetAlignment(that: any) {

        if (that.titleTextLine1 === '' || that.titleTextLine2 === '') {
            return;
        }

        const vector1 = this.line1Element.node().getBBox();
        const vector2 = this.line2Element.node().getBBox();

        if (vector1.width >= vector2.width) {
            switch (that.titleTextAlignment) {
                case 'left':
                    switch (that.titleTextPosition) {
                        case 'center':
                            this.line2Element
                                .attr('x', vector2.x - ((vector1.width - vector2.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'right':
                            this.line2Element
                                .attr('x', vector2.x - vector1.width + vector2.width)
                                .attr('text-anchor', 'start');
                            break;
                        case 'middle':
                            this.line2Element
                                .attr('x', vector2.x - ((vector1.width - vector2.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'top':
                            this.line2Element
                                .attr('x', vector2.x - ((vector1.width - vector2.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'bottom':
                            this.line2Element
                                .attr('x', vector2.x - ((vector1.width - vector2.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'center':
                    switch (that.titleTextPosition) {
                        case 'left':
                            this.line2Element
                                .attr('x', vector2.x + ((vector1.width - vector2.width) / 2));
                            break;
                        case 'right':
                            this.line2Element
                                .attr('x', vector2.x - ((vector1.width - vector2.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'right':
                    switch (that.titleTextPosition) {
                        case 'left':
                            this.line2Element
                                .attr('x', vector2.x + vector1.width)
                                .attr('text-anchor', 'end');
                            break;
                        case 'center':
                            this.line2Element
                                .attr('x', vector2.x + (vector1.width / 2) + (vector2.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                        case 'middle':
                            this.line2Element
                                .attr('x', vector2.x + (vector1.width / 2) + (vector2.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                        case 'top':
                            this.line2Element
                                .attr('x', vector2.x + (vector1.width / 2) + (vector2.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                        case 'bottom':
                            this.line2Element
                                .attr('x', vector2.x + (vector1.width / 2) + (vector2.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                    }
                    break;
            }
        } else if (vector2.width > vector1.width) {
            switch (that.titleTextAlignment) {
                case 'left':
                    switch (that.titleTextPosition) {
                        case 'center':
                            this.line1Element
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'right':
                            this.line1Element
                                .attr('x', vector1.x - vector2.width + vector1.width)
                                .attr('text-anchor', 'start');
                            break;
                        case 'middle':
                            this.line1Element
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'top':
                            this.line1Element
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                        case 'bottom':
                            this.line1Element
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'center':
                    switch (that.titleTextPosition) {
                        case 'left':
                            this.line1Element
                                .attr('x', vector1.x + ((vector2.width - vector1.width) / 2));
                            break;
                        case 'right':
                            this.line1Element
                                .attr('x', vector1.x - ((vector2.width - vector1.width) / 2))
                                .attr('text-anchor', 'start');
                            break;
                    }
                    break;
                case 'right':
                    switch (that.titleTextPosition) {
                        case 'left':
                            this.line1Element
                                .attr('x', vector1.x + vector2.width)
                                .attr('text-anchor', 'end');
                            break;
                        case 'center':
                            this.line1Element
                                .attr('x', vector1.x + (vector2.width / 2) + (vector1.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                        case 'middle':
                            this.line1Element
                                .attr('x', vector1.x + (vector2.width / 2) + (vector1.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                        case 'top':
                            this.line1Element
                                .attr('x', vector1.x + (vector2.width / 2) + (vector1.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                        case 'bottom':
                            this.line1Element
                                .attr('x', vector1.x + (vector2.width / 2) + (vector1.width / 2))
                                .attr('text-anchor', 'end');
                            break;
                    }
                    break;
            }
        }
    }


}
