import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {EnvService} from '../../../env.service';

@Injectable()
export class ProjectsService {
    constructor(private http: HttpClient,
                private userService: UserService,
                private envService: EnvService) {
    }


    getProjects() {
        return this.http
            .get(this.envService.url + 'projects/' + this.userService.token)
            .map((res: HttpResponse<any>) => res);
    }


    getAllData() {
        return Observable.forkJoin(
            this.http.get(this.envService.url + 'studio/'),
            this.http.get(this.envService.url + 'projects/' + this.userService.token),
            this.http.get(this.envService.url + 'exports/' + this.userService.token)
        );
    }


    saveNewProject(project) {
        return this.http
            .post(this.envService.url + 'project', {
                token: this.userService.token,
                project: project
            })
            .map((res: HttpResponse<any>) => res);
    }


    saveExistingProject(project) {
        return this.http
            .put(this.envService.url + 'project', {
                token: this.userService.token,
                project: project
            })
            .map((res: HttpResponse<any>) => res);
    }


    renameProject(oldName, project) {
        return this.http
            .post(this.envService.url + 'rename', {
                token: this.userService.token,
                oldName: oldName,
                project: project
            })
            .map((res: HttpResponse<any>) => res);
    }


    copyProject(oldName, project) {
        return this.http
            .post(this.envService.url + 'copy', {
                token: this.userService.token,
                oldName: oldName,
                project: project
            })
            .map((res: HttpResponse<any>) => res);
    }


    deleteProject(project) {
        const tokenProject = this.userService.token + '-' + project;
        return this.http
            .delete(this.envService.url + 'project/' + tokenProject)
            .map((res: HttpResponse<any>) => res);
    }


    getProjectForExport() {
        return this.http
            .get(this.envService.url + 'projects/' + this.userService.token)
            .map((res: HttpResponse<any>) => res);
    }


}
