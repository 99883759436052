import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {ValidateService} from './validate.service';
import {UserService} from '../../services/user.service';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'validate',
    templateUrl: './validate.component.html',
    styleUrls: ['./validate.component.css']
})
export class ValidateComponent implements OnInit {

    errorMessage: any;
    sentEmailAddress: string;
    form = new ValidateForm('', '', false);
    spinner: boolean;
    error: any = {};

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private validateService: ValidateService,
                private userService: UserService,
                private analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
            this.form.email = queryParams['email'];
            this.form.code = queryParams['code'];
        });
        this.sentEmailAddress = this.userService.verifiesEmail;
        this.form.email = (this.form.email) ? this.form.email : this.userService.verifiesEmail;
        this.form.resetting = this.userService.verifiesResetting;
        this.analyticsService.record('validate');
    }

    tryCode() {
        this.error = {};
        this.error['sending code'] = true;
        this.checkCode();
    }

    checkCode() {
        this.form.email = this.form.email.replace(/ /g, '');
        this.form.code = this.form.code.replace(/ /g, '');
        this.spinner = true;
        this.validateService.checkCode(this.form.email, this.form.code)
            .subscribe(
                codeResult => this.processCodeResult(codeResult),
                error => this.errorMessage = <any>error
            );
    }

    processCodeResult(codeResult: any) {
        this.error = {};
        if (codeResult.passed) {
            this.userService.verifiesEmail = (this.sentEmailAddress) ? this.sentEmailAddress : this.form.email;
            this.userService.verifiesCode = this.form.code;
            this.router.navigate(['/password']);
        } else {
            this.error['bad code'] = true;
        }
        this.spinner = false;
    }

}

export class ValidateForm {
    constructor(public email: string, public code: string, public resetting: boolean) {
    }
}
