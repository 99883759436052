import {Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RegisterService} from '../register/register.service';
import {UserService} from '../../services/user.service';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

    errorMessage: any;
    form = new RegisterForm('');
    result: any;
    spinner: boolean;
    error: any = {};

    constructor(private userService: UserService,
                private router: Router,
                private registerService: RegisterService,
                private analyticsService: AnalyticsService) {
    }

    ngOnInit(): void {
        this.analyticsService.record('forgot');
    }

    tryRegister() {
        this.error = {};
        if (this.form.email.match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i)) {
            this.error['sending email'] = true;
            this.spinner = true;
            this.checkEmail();
        } else {
            this.error['invalid email'] = true;
        }
    }

    checkEmail() {
        this.registerService.checkEmail(this.form.email, true)
            .subscribe(
                result => this.processEmailResult(),
                error => this.errorMessage = <any>error
            );
    }

    processEmailResult() {
        this.error = {};
        this.userService.verifiesEmail = this.form.email.toLowerCase(); // to share with /validate
        this.userService.verifiesResetting = true; // to share with /password
        this.router.navigate(['/validate']);
        this.spinner = false;
    }

}

export class RegisterForm {
    constructor(public email: string) {
    }
}
