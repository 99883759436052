import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {AnalyticsService} from '../../analytics.service';

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

    constructor(private userService: UserService,
                private analyticsService: AnalyticsService) {
        }

    ngOnInit() {
        this.userService.logout();
        this.analyticsService.record('logout');
    }

}
