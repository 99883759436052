import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {
    @Input() message;

    constructor() {
    }

    ngOnInit() {
    }

}
